<section class="wrapper-section"
    *ngIf="analytics != null && analytics.listUser != null && analytics.listUser.length > 0">
    <div class="row">
        <div class="col-sm-12">
            <h2 class="title-section">{{ 'titles.who-makes-diference' | translate:campaign}}</h2>
            <span style="background-color: var(--color);" class="line-title"></span>
        </div>
    </div>
    <div id="collections-section" class="padding-top-20-md padding-bottom-10 bg-section"
        [ngStyle]="{ display: slickerLoaded ? 'block' : 'none' }">
        <div class="collections-slider w-slider container">
            <div class="row">

            </div>
            <div class="row" style="justify-content: center;">
                <div class="slick-container">

                    <div class="collection-slide" *ngFor="let user of analytics.listUser; let i = index"
                        [attr.data-index]="i">
                        <a class="collection-link w-inline-block">
                            <h4 class="collection-title">{{ user.name }}</h4>
                            <div class="collection-img-wrapper">
                                <div class="collection-img-ngo">
                                    <img src="{{ user.ngoLogo }}" class="collection-img" />
                                </div>
                                <div class="collection-img-block">
                                    <img src="{{ user.image }}" class="collection-img" />
                                </div>

                            </div>

                            <p class="collection-price">{{ 'currency-symbol' | translate:campaign}}
                                {{ user.userDonation | number:'.2-2' }}</p>
                            <p class="collection-time">{{ user.timeAgo }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>