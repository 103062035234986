import {Component, HostListener, Input, OnInit} from '@angular/core';
import {animate, animation, state, style, transition, trigger} from '@angular/animations';
import {CampaignAnalytics, CampaignModel} from "../../models/campaign.model";
import {HelperMethods} from "../../customization/helperMethods";
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-test-direct-donation',
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0)',
      })),
      state('close', style({
        transform: 'translateX(-150%)',
      })),
      transition('open => close', [
        animate('0.5s 100ms ease-out')
      ]),
      transition('close => open', [
        animate('0.5s 100ms ease-in')
      ])
    ]),
    trigger('moveLine1', [
      state('open', style({
        opacity: 1,
        transform: 'translateX(0)',
      })),
      state('close', style({
        opacity: 0,
        transform: 'translateX(-150%)',
      })),
      transition('open => close', [
        animate('0.6s 100ms ease-out')
      ]),
      transition('close => open', [
        animate('0.6s 100ms ease-in')
      ])
    ]),
    trigger('moveLine2', [
      state('open', style({
        opacity: 1,
        transform: 'translateX(0)',
      })),
      state('close', style({
        opacity: 0,
        transform: 'translateX(-150%)',
      })),
      transition('open => close', [
        animate('0.7s 100ms ease-out')
      ]),
      transition('close => open', [
        animate('0.7s 100ms ease-in')
      ])
    ])
  ],
  templateUrl: './test-direct-donation.component.html',
  styleUrls: ['./test-direct-donation.component.css']
})
export class TestDirectDonationComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:resize', ['$event'])

  @Input() campaign: CampaignModel;
  @Input() analytics: CampaignAnalytics;
  @Input() content: any;
  @Input() loading: boolean = false;
  @Input() selectedCause: any;

  isOpen = false;
  showItemMenu = false;
  showValues = false;
  isMobile = false;
  currentUrl = null;
  ngo: any;
  shareText = "Vamos unir nossas forças em prol da transformação social? Acabei de realizar uma doação por meio da campanha Startups pelo Bem, iniciativa que está mobilizando empresas a gerarem impacto social, e convido você a doar também!  Juntos e de pouco em pouco podemos fazer a diferença e transformar a vida de quem precisa";
  animation: boolean = false;

  constructor(public helper: HelperMethods, public router: Router) { }

  ngOnInit(): void {
    console.log("Esta certo")
    setTimeout(() => {
      debugger;

      if(this.campaign && this.campaign.isJunoOk){
        const href = this.router.url;
        const currentUrl = href.split("?")[0].replace("/", "");
        console.log(currentUrl);
        let container = document.getElementById("polen-widget");
        let ifrm = document.createElement("iframe");
        if(this.campaign.supportedNgos.length === 1){
          ifrm.setAttribute("src", `https://widget.polen.com.br/directDonation/${href.split("?")[0].replace("/", "")}?selectedNgo=${this.campaign.supportedNgos[0].url}`);
        }else {
          ifrm.setAttribute("src", `https://widget.polen.com.br/directDonation/${href.replace("/", "")}`);
        }
        ifrm.id = "ifrm-polen"
        // ifrm.setAttribute("src", `http://127.0.0.1:3000/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
        ifrm.style.width = "100%";
        ifrm.style.height = "100%";
        ifrm.style.maxWidth = "460px";
        ifrm.style.border = "none";
        ifrm.style.background = "#FFFFFF";
        ifrm.setAttribute("allow", "clipboard-read; clipboard-write")
        // ifrm.contentWindow.postMessage({}, `https://polen-widget.vercel.app/directDonation/${this.href.replace("/", "")}`);
        if(container){
          container.append(ifrm);
        }
      }
      this.currentUrl = window.location.href;
      this.campaign.customFields.forEach((custom)=>{
        if(custom.key == "showTotalDonated"){
          this.showValues = custom.value === "true";
        }
      })
    }, 1000);
    this.verifyMobile();
  }

  getAnswer(step: string, form: any) {
    if (form.loading != null) {
      this.loading = form.loading;
    }
    else {
      if (step == 'ongselect') {
        if (form.ngo) {
          this.selectedCause = form.ngo;
        }
      }

      if (step == 'donation') {
        this.campaign.goalCampaignId = form.goalCampaignId;
        this.campaign.goalCampaignSuggestion = form.goalCampaignSuggestion;
        this.campaign.campaignType = form.campaignType;
        //this.save();
      }
    }
  }

  animateLinks(): void {
    const navList = document.querySelector('.nav-list');
    const mobileMenu = document.querySelector('.mobile-menu');

    if (navList && mobileMenu){
      navList.classList.toggle('active');
      mobileMenu.classList.toggle('active');
    }

    this.isOpen = !this.isOpen;

    setTimeout(() => {
      this.showItemMenu = !this.showItemMenu;
    }, 100);
  }

  onWindowScroll() {
    const element = document.querySelector('.container-direct-donation') as HTMLElement;
    const myClass = document.querySelector('.header-direct-donation') as HTMLElement;

    if (window.pageYOffset  < element.scrollTop) {
      myClass.classList.add('navbar-background');
    } else {
      myClass.classList.remove('navbar-background');
    }
  }

  scrollToElement(id: string): void {
    // const element = document.querySelector(id) as HTMLElement;
    // element.scrollIntoView();
    // setTimeout(() => {
    //   console.log($(id));
    //   const offset = $(id).offset();
    //   console.log(offset.top);
    //   $('html, body').animate({
    //     scrollTop: offset.top - 200,
    //     scrollLeft: offset.left
    //   });
    // }, 100)
    const element = document.querySelector(id) as HTMLElement;

    element.scrollIntoView({block: "nearest", behavior: "smooth"});
  }

  balanceAnimation(){
    this.animation = true;
    setTimeout(()=>{
      this.animation = false;
    }, 2000);
  }

  verifyMobile(){
    this.isMobile = window.innerWidth <= 450;
  }
}
