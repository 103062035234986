import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { CustomizationComponent } from "./customization/customization.component";
import { RouterModule, ExtraOptions } from "@angular/router";
import { LpCustomRoutes } from "./app.routing";
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, registerLocaleData } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgxMaskModule } from "ngx-mask";
import { NgxSmartModalModule } from "ngx-smart-modal";
// import {CardModule, NgxCard} from 'ngx-card/ngx-card';
import { TabsModule, ModalModule, AccordionModule } from 'ngx-foundation';
import { NgxFaviconModule } from 'ngx-favicon';
import { customFavicons, AvailableCustomFavicons } from "./config/favicon.config";
import { DirectDonationComponent } from "./widget/direct-donation/direct-donation.component";
import { OngSelectorComponent } from "./widget/ong-selector/ong-selector.component";
import { HowItWorksComponent } from "./widget/how-it-works/how-it-works.component";
import { UseTermsComponent } from "./widget/use-terms/use-terms.component";
import { HomeComponent } from "./home/home.component";
import { BackerListComponent } from "./widget/backer-list/backer-list.component";
import localePt from '@angular/common/locales/pt';
import { GoogleAnalyticsService } from "./services/googleanalytics.service";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { JunoDirectDonationComponent } from "./widget/juno-direct-donation/juno-direct-donation.component";
import { JunoService } from "./services/juno.service";
import { JunoBus } from "./services/juno.bus";
import { DirectDonationNgoComponent } from './widget/direct-donation-ngo/direct-donation-ngo.component';
import {TestDirectDonationComponent} from "./widget/test-direct-donation/test-direct-donation.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


// console.log('hostname: ' + window.location.hostname)
// let wichSubdomain = window.location.hostname.indexOf('localhost') >= 0 ? 'james.99causas.com' : window.location.hostname;
// console.log('wichSubdomain: ' + wichSubdomain)
// let subdomain = wichSubdomain.split('.')[0];
// console.log('subdomain: ' + subdomain)
// const currentRoute = (subdomain == '99causas') ? lp99Routes : ((subdomain == 'james') ? lpJamesRoutes : lpCustomRoutes);
// console.log('currentRoute: ' + JSON.stringify(currentRoute))

registerLocaleData(localePt);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  //return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CustomizationComponent,
    DirectDonationComponent,
    JunoDirectDonationComponent,
    OngSelectorComponent,
    HowItWorksComponent,
    UseTermsComponent,
    BackerListComponent,
    DirectDonationNgoComponent,
    TestDirectDonationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    //RouterModule.forRoot(AppRoutes, routerOptions),
    LpCustomRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    // CardModule,
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    TranslateService,
    {
      provide: LocationStrategy,
      //useClass: HashLocationStrategy
      useClass: PathLocationStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    GoogleAnalyticsService,
    JunoService,
    JunoBus
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

