
export class CampaignModel {
    active: boolean;
    apiToken: string
    availablePaymentMethods: string[]
    campaignName: string;
    campaignType: number;
    campaignUrl: string;
    couponGiftList: CampaignCouponModel[]
    cover: string;
    coverMobile: string;
    currency: string
    currencySymbol: string
    customFields: any;
    dateActive: Date;
    dateCreate: Date;
    dateDisable: Date;
    goalCampaignId: string
    goalCampaignSuggestion: string
    hasRecurringPayment: any;
    id: string;
    isJunoOk: boolean;
    language: string
    logo: string;
    mainPercentage: any
    metaTags: number;
    minDonationValue: number
    name: string;
    ngoId: string
    ngoUrl: string
    owner: number
    paymentList: number[]
    presetDonationList: PresetDonation[]
    recurringPayment: boolean;
    rules: string
    selectedCauses: string[]
    seoUrl: string;
    status: number
    storeBillingAddress: string;
    storeBillingName: string;
    storeDocument: string;
    storeId: string;
    storeName: string;
    subDomainUrl: string;
    supportedNgos: any
    themeId: string;
    themeName: string;
}

export class PresetDonation {
    donation: number
    description: string
    title: string

    constructor(d: number) {
        this.donation = d;
    }
}

export class CampaignCouponModel {
    id: string;
    title: string;
    shortDescription: string;
    coupon: string;
    maxDonation: number;
    infoRule: string;
    active: boolean

    //caso nao exiba o cupom, usamos esse campo pra falar pra verificar o email
    checkEmail: boolean
}

export class CampaignAnalytics {
    totalRaised: number
    totalPendingDonation: number
    totalCanceledDonation: number
    listNgoImpact: any
    listUser: UserCampaign[]
    lastUpdate: Date
}

export class UserCampaign {
    name: string
    image: string
    ngoUrl: string
    ngoLogo: string
    userDonation: number
    anonymous: boolean
    status: number
    timeAgo: string
    date: Date
}