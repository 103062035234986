import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CampaignModel } from 'src/app/models/campaign.model';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {

  @Input() ngoList: any
  @Input() color: any
  @Input() campaign: CampaignModel
  @Input() selectedCause: any
  @Output() answer = new EventEmitter();
  public isAdmin: boolean
  colorLight: string

  constructor() { }

  ngOnInit() {
    console.log(this.color)
    this.colorLight = this.color + '80'
  }


  scrollToElement(id): void {
    setTimeout(() => {
      var offset = $(id).offset();
      $('html, body').animate({
        scrollTop: offset.top,
        scrollLeft: offset.left
      });
    }, 100)
  }

  changeTab($element, id, idInactive, container) {
    setTimeout(() => {
      debugger
      jQuery(container + ' .tabs-title').removeClass('is-active');
      jQuery(container + ' .tabs-title a').css('background-color', '')
      jQuery($element).parent().addClass("is-active");
      jQuery($element).css('background-color', this.color)
      $(id).show();
      $(idInactive).hide();
    }, 100)
  }

  changeAccordion($element) {
    setTimeout(() => {
      jQuery('#how-it-works .accordion-content').css('display', '');
      jQuery('#how-it-works .accordion-item').removeClass('is-active')
      jQuery($element).parent().addClass("is-active")
      jQuery($element).next().css('display', 'block')

    }, 100)
  }

}
