export class PaymentInfo {
    storeName: string;
    createPaymentInvoice: boolean;
    id: string;
    method: string;
    extra_info: CreditCardInfo;
    test: boolean;
    isInstallment: boolean;
}

export class CreditCardInfo {
    default: boolean;
    brand: string;
    holder_name: string;
    display_number: string;
    bin: string;
    month: number;
    year: number;
    month_year: string;
    token: string;
    installment: number

    first_name: string;
    last_name: string;

    polenPaymentMethodId: string;
    tokenize: boolean
}

export class NewDonationDto {
    storeId: string;
    identifier: string;
    email: string;
    name: string;
    phone: string;
    causesList: Array<any>;
    verified: boolean;
    optIn: boolean;
    donation: number = 0;
    userPayForBankFee: boolean;
    userBankFee: number;
    monthly: boolean = false;
}
