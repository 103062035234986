<!-- Nosso impacto -->
<section id="choose-ong-section" class="choose-ong wrapper-section bg-gray"
    style="background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)), url();"
    [ngStyle]="{ 'background-color': colorLight}" *ngIf="ngoList && colorLight">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="title-section h3" style="color: #fff;">{{ 'titles.choose-cause-to-help' | translate }}</h2>
                <span style="background-color: #fff;" class="line-title mb-0"></span>
            </div>
        </div>

        <div class="container">
            <div class="row container-wrap-our-impact d-flex" style="justify-content: center;">

                <div class="col-md-4 ong" *ngFor="let ngo of ngoList; let i = index" [attr.data-index]="i">
                    <div class="wrap-our-impact mt-4 mt-md-5">
                        <figure (click)="selectNgo(ngo, '#signup-form')"
                            class="m-0 d-none d-md-block d-lg-block d-xl-block"
                            style="border-radius: 25px;cursor: pointer;">
                            <img src="../../assets/custom/images/donation-app/ong-white-background.png"
                                alt="{{ ngo.name }}">
                        </figure>
                        <div class="company container pb-3 d-none d-md-block d-lg-block d-xl-block">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <div class="logo-circle box-80 mt-1 mr-2 p-2"
                                        style="    width: 120px;height: 120px;">
                                        <img src="{{ ngo.logo }}" alt="{{ ngo.name }}" style="border-radius: initial;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ong__title">
                            <a id="donation-{{ngo.url}}" data-id="{{ngo.url}}" class="love-btn"
                                href="javascript:void(0)" title="Apoie a {{ngo.name}}"
                                (click)="selectNgo(ngo, $event.currentTarget, '#signup-form')">
                                <div class="heart-icon" [ngStyle]="{ 'background-color': color}">
                                    <svg width="21px" height="18px" viewBox="0 0 21 18" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Mobile" transform="translate(-36.000000, -895.000000)" fill="#FFFFFF"
                                                fill-rule="nonzero">
                                                <g id="Card---selection" transform="translate(21.000000, 854.000000)">
                                                    <g id="Bitmap-+-Rectangle-Mask">
                                                        <path
                                                            d="M29.331,41 C27.724,41 26.18,41.677 25.085,42.837 C23.989,41.677 22.445,41 20.839,41 C17.619,41 15,43.619 15,46.839 C15,52.634 20.974,56.168 24.184,58.067 C24.408,58.2 24.618,58.324 24.811,58.44 C24.895,58.491 24.99,58.516 25.085,58.516 C25.18,58.516 25.275,58.491 25.359,58.44 C25.552,58.324 25.762,58.199 25.986,58.067 C29.196,56.168 35.17,52.634 35.17,46.839 C35.17,43.619 32.551,41 29.331,41 Z M25.446,57.153 C25.321,57.227 25.201,57.298 25.085,57.367 C24.969,57.298 24.849,57.227 24.724,57.153 C21.697,55.362 16.061,52.028 16.061,46.839 C16.061,44.205 18.204,42.062 20.838,42.062 C22.332,42.062 23.761,42.778 24.66,43.978 C24.861,44.245 25.309,44.245 25.51,43.978 C26.409,42.778 27.837,42.062 29.332,42.062 C31.966,42.062 34.109,44.205 34.109,46.839 C34.109,52.028 28.473,55.362 25.446,57.153 Z"
                                                            id="Shape"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <svg class="filled-heart" width="21px" height="18px" viewBox="0 0 21 18"
                                        version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Mobile" transform="translate(-36.000000, -1005.000000)"
                                                fill="#FFFFFF" fill-rule="nonzero">
                                                <g id="Card---selection-Copy"
                                                    transform="translate(21.000000, 964.000000)">
                                                    <g id="Bitmap-+-Rectangle-Mask">
                                                        <path
                                                            d="M29.331,41 C27.724,41 26.18,41.677 25.085,42.837 C23.989,41.677 22.445,41 20.839,41 C17.619,41 15,43.619 15,46.839 C15,52.634 20.974,56.168 24.184,58.067 C24.408,58.2 24.618,58.324 24.811,58.44 C24.895,58.491 24.99,58.516 25.085,58.516 C25.18,58.516 25.275,58.491 25.359,58.44 C25.552,58.324 25.762,58.199 25.986,58.067 C29.196,56.168 35.17,52.634 35.17,46.839 C35.17,43.619 32.551,41 29.331,41 Z"
                                                            id="Shape"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="ong--label">
                                    <img class="d-none d-sm-block d-md-none d-lg-none d-xl-none" src="{{ ngo.logo }}"
                                        alt="{{ ngo.name}}" style="width: 50px;" />
                                    <p>{{ ngo.name }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>