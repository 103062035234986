import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { CampaignModel } from 'src/app/models/campaign.model';
declare var jQuery: any;
declare var $: any;
declare var google: any;

@Component({
  selector: 'use-terms',
  templateUrl: './use-terms.component.html',
  styleUrls: ['./use-terms.component.css']
})
export class UseTermsComponent implements OnInit {


  @Input() campaign: CampaignModel
  newcampaign: CampaignModel
  campaignUrl: string
  storeName: string
  content: any;
  storeDocument: string
  @Output() answer = new EventEmitter();
  public isAdmin: boolean
  colorLight: string
  @Input() campaignName: string

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.campaign.currentValue != null) {
      this.newcampaign = changes.campaign.currentValue;
      this.campaignUrl = "https://doe.polen.com.br/" + this.newcampaign.subDomainUrl
      this.storeName = this.newcampaign.storeName;
      this.storeDocument = this.newcampaign.storeDocument;

      // setTimeout(() => {
      //   debugger
      //   new google.translate.TranslateElement(
      //     { pageLanguage: 'en' },
      //     'termosdeuso-container'
      //   );

      // }, 500);

      this.content = this.campaign.customFields.reduce(function (map, obj) {
        map[obj.key] = obj;
        return map;
      }, {});
    }
  }


}
