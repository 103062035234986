import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { Configuration } from 'src/app/app.constants';
import { forkJoin, Observable } from 'rxjs';
import { HelperMethods } from 'src/app/customization/helperMethods';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CampaignService } from '../services/campaign.service';
import { CampaignModel, CampaignAnalytics } from '../models/campaign.model';
import { GoogleAnalyticsService } from '../services/googleanalytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

declare var jQuery: any;
declare var $: any;
declare let gtag: Function;
declare let fbq: Function;


@Component({
  selector: 'app-customization',
  templateUrl: './customization.component.html',
  styleUrls: ['./customization.component.css']
})
export class CustomizationComponent implements OnInit {
  version: string = "5.1 - alteracao para aparcer um widget apenas"
  url: string;
  test: boolean = false;
  backtopageType: string
  content: any;
  isAdmin: boolean = true
  campaign: CampaignModel
  subdomain: string;
  campaignUrl: string
  analytics: CampaignAnalytics
  loading: boolean = false;
  loaded: boolean = false;
  position: number = 0;
  manualInstall: boolean = true
  platformSelected: string;
  messages: string[] = [
    "E-commerces polinizadores tem uma conversão de vendas até 30% maior",
    "50% dos consumidores que escolhem uma causa no e-commerce, finalizam a compra",
    "56% dos brasileiros compram motivados por causas",
    "89% dos consumidores trocariam uma marca por outra socialmente responsável"
  ];

  coverImage: string = null;
  coverMobileImage: string = null;
  loadingMessage = this.messages[this.position];
  selectedCause: any;

  constructor(public router: Router, private meta: Meta, public helper: HelperMethods, private titleService: Title, private campaignService: CampaignService, public translate: TranslateService, public gaService: GoogleAnalyticsService, public ngxSmartModalService: NgxSmartModalService, private route: ActivatedRoute, private helperMethods: HelperMethods) {
    window['angularContext'] = this;
  }

  ngOnInit() {
    console.log("version: " + this.version)
    this.route.paramMap.subscribe((parms) => {
      debugger;
      var subdomain = parms['params'].subdomain
      var secondParms = parms['params'].campaignUrl
      var thirdParms = parms['params'].return
      if (secondParms != null) {
        if (secondParms != 'thankyou' && secondParms != 'tryagain') {
          this.campaignUrl = secondParms;
          this.backtopageType = thirdParms;
        }
        else {
          this.campaignUrl = null;
          this.backtopageType = secondParms;
        }
      }
      else {
        this.campaignUrl = null;
        this.backtopageType = null;
      }

      this.subdomain = subdomain;
      // if (subdomain != "multiplique-o-bem")
      //   window.location.href = "/multiplique-o-bem";

      console.log(parms['params'].subdomain)
      console.log(parms['params'].campaignUrl)
      
      if (subdomain == null && parms['params'].campaignId) {
        var campaignId = parms['params'].campaignId;
      }
      if (subdomain != null) {
        this.campaignService.getBySubdomain(this.subdomain, this.campaignUrl).subscribe(res => {
          this.loadCampaign(res);
        })
      }
      else if (campaignId != null) {
        this.campaignService.get(campaignId).subscribe(res => {
          res.data.status = 2
          this.loadCampaign(res);
        })
      }
    });

    // this.route.queryParams.subscribe(params => {
    //     // console.log(params);
    //     this.test = params.test == 'true';
    //     console.log("meu teste:", this.test);
    //   }
    // );

    //this.updateLoadingMessage();
  }

  loadCampaign(res) {
    if (res.success) {
      this.campaign = res.data;
      console.log(this.campaign);
      this.titleService.setTitle(this.campaign.name);
      this.meta.updateTag({ name: 'description', content: 'Participe da campanha ' + this.campaign.name + ' e apoie uma causa social junto com a ' + this.campaign.storeName + '.' })
      this.setLanguage()
      this.setCurrency()
      this.setCoverImage()
      this.shuffleNgoList()
      this.loadContent()
      this.loadAnalytics()
      this.loadDonation()
      this.loadBackToPage()
      this.changeUrlOfNgos();
    }
    else {
      //this.notificationBuilder.showNotification('danger', 3000, res.error)
      console.log(res.errorDev);

    }
    this.loaded = true;
  }

  changeUrlOfNgos() {
    this.campaign.supportedNgos.forEach(item => {
      item.externalUrl = 'https://polen.com.br/' + item.url
      if (item.url == 'servethecity') {
        item.externalUrl = 'https://www.servethecity.net'
      }

      if (item.url == 'bancoalimentar-pt') {
        item.externalUrl = 'http://www.bancoalimentar.pt'
      }

      if (item.url == 'lanostrafamiglia') {
        item.externalUrl = 'https://lanostrafamiglia.it'
      }

      if (item.url == 'medicossinfronteras-espana') {
        item.externalUrl = 'https://www.msf.es/nuestra-accion/coronavirus'
      }

      if (item.url == 'helpo') {
        item.externalUrl = 'https://www.helpo.pt'
      }

      if (item.url == 'arrels') {
        item.externalUrl = 'https://www.arrelsfundacio.org'
      }

      if (item.url == 'visaofuturo') {
        item.externalUrl = 'https://www.visaofuturo.org.br/covid/'
      }

      if (item.url == 'fraternidadesemfronteiras') {
        item.externalUrl = 'http://www.fraternidadesemfronteiras.org.br'
      }
    });
  }

  loadBackToPage() {
    if (this.backtopageType != null) {
      if (this.backtopageType == 'thankyou')
        this.ngxSmartModalService.getModal('posDonationModal').open()

      if (this.backtopageType == 'tryagain')
        this.ngxSmartModalService.getModal('posDonationCancelModal').open()
    }
  }

  loadContent() {
    //transforma o array em objeto
    this.content = this.campaign.customFields.reduce(function (map, obj) {
      map[obj.key] = obj;
      return map;
    }, {});
    this.changeTheme()
    this.setTrackers()
  }

  loadAnalytics() {
    setTimeout(() => {
      this.campaignService.getAnalytics(this.campaign.id).subscribe(resAna => {
        //console.log(resAna)
        if (resAna.success)
          this.analytics = resAna.analytics
      })
    }, 1000)
    debugger
  }

  setLanguage() {
    if (this.campaign.language == null || this.campaign.language.length == 0) {
      this.translate.use('br');
    }
    else {
      if (this.campaign.subDomainUrl == 'h4h-br')
        this.translate.use('br-h4h');
      else
        this.translate.use(this.campaign.language);
    }

  }

  setCurrency() {
    if (this.campaign.currency == null || this.campaign.currency.length == 0) {
      this.campaign.currencySymbol = "R$"
      this.campaign.currency = "BRL"
    }
    else {
      switch (this.campaign.currency) {
        case "BRL":
          this.campaign.currencySymbol = "R$"
          break;
        case "USD":
          this.campaign.currencySymbol = "US$"
          break;
        case "EUR":
          this.campaign.currencySymbol = "€"
          break;
        default:
          break;
      }
    }

  }

  setTrackers() {
    //adiciona trackeador do google analytics
    if (this.content.googleAnalyticsId != null && this.content.googleAnalyticsId.length > 0) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', this.content.googleAnalyticsId, { 'page_path': event.urlAfterRedirects });
        }
      })
    }

    //adiciona trackeador do google facebook
    if (this.content.facebookId != null && this.content.facebookId.length > 0) {
      fbq('init', this.content.facebookId);
      fbq('track', 'PageView');
    }
  }

  setCoverImage() {
    this.coverImage = this.campaign.cover;
    if (this.coverImage == null || this.coverImage.indexOf("null") >= 0) {
      var idx = Math.floor(Math.random() * this.campaign.supportedNgos.length)
      console.log('indice-' + idx)
      var ngo = this.campaign.supportedNgos[idx];
      this.coverImage = ngo.image
    }

    this.coverMobileImage = this.coverImage;
    if (this.campaign.coverMobile != null && this.campaign.coverMobile.length > 0 && this.campaign.coverMobile.indexOf("null") < 0) {
      this.coverMobileImage = this.campaign.coverMobile;
    }

    this.meta.updateTag({ name: 'og:url', content: this.coverImage })
  }

  changeTheme() {

    if (this.content.primaryColor != null) {
      var primary = this.content.primaryColor.value
      var secondary = this.content.primaryColor.value + '90'
      document.documentElement.style.setProperty('--color', primary);
      document.documentElement.style.setProperty('--colorlight', secondary);
      document.documentElement.style.setProperty('--color20', primary + '20');
      document.documentElement.style.setProperty('--color60', primary + '60');
    }
  }

  shuffleNgoList() {
    if (this.campaign.owner == 1) {
      this.selectedCause = this.campaign.supportedNgos[0];
    }
    else {
      setTimeout(() => {
        var indexSelectFirst = Math.floor(Math.random() * 2);
        this.selectedCause = this.campaign.supportedNgos[indexSelectFirst];
      }, 100)
    }
  }

  loadDonation() {
    try {
      jQuery(".select-donation").click(function (e) {
        e.preventDefault();
        jQuery('html, body').animate({
          scrollTop: jQuery(".choose-ong").offset().top
        }, 500);
      });

    } catch (e) {
    }
  }

  getAnswer(step: string, form: any) {
    if (form.loading != null) {
      this.loading = form.loading;
    }
    else {
      if (step == 'ongselect') {
        if (form.ngo) {
          this.selectedCause = form.ngo;
        }
      }

      if (step == 'donation') {
        this.campaign.goalCampaignId = form.goalCampaignId;
        this.campaign.goalCampaignSuggestion = form.goalCampaignSuggestion;
        this.campaign.campaignType = form.campaignType;
        //this.save();
      }
    }
  }

  updateLoadingMessage() {
    setTimeout(() => {
      if (this.position + 1 >= this.messages.length)
        this.position = 0;
      else
        this.position += 1;

      this.loadingMessage = this.messages[this.position]
      this.updateLoadingMessage();
    }, 5000);
  }

  scrollToElement(id): void {
    if ($(id).length == 0)
      id = "#signup-form"

    if (id == "#choose-ong-section") {
      this.gaService.trackEvent("intentDonation");
    }

    setTimeout(() => {

      var offset = $(id).offset();
      $('html, body').animate({
        scrollTop: offset.top - 100,
        scrollLeft: offset.left
      });
    }, 100)
  }
}
