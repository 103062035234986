<!-- [ngStyle]="{ 'background-image': 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(https://storage.googleapis.com/sealpolen-files.polinizador.com.br/blob/ongs/ong-larmaemariana-thankyou.png)'}" -->
<div *ngIf="selectedCause != null && campaign.status < 3 && campaign.presetDonationList != null">
    <section #signupform id="signup-form" class="wrapper-section bg-gray"
        style="display:block;background-repeat: no-repeat;background-size: cover;"
        [ngStyle]="{ 'background-image': 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(' + detailNgoImageBackground + ')'}"
        *ngIf="donationUser != null && creditCard != null && selectedCause != null">
        <div class="container">
            <div class="row">
                <div class="col-6 d-sm-none d-xs-none d-md-block d-lg-block">
                </div>
                <div *ngIf="campaign.isJunoOk===false" class="col-sm-6 col-xs-6 col-md-12 col-lg-12 signup">
                    <div class="grid-container">
                        <!-- <header class="step-mark">
                        <h4 id="how-to-help">Último passo! Faça seu cadastro</h4>
                        <p class="text-center">Atenção: utilize os mesmo dados do App da 99</p>
                    </header> -->
                        <!-- <p class="show-for-medium desktop-text">Só falta 1 passo para você começar a apoiar as ONGs <strong>que cuidam das crianças nas creches!</strong></p> -->
                        <div class="test" *ngIf="iuguTest" style="background: #ffeeba;text-align: center;padding: 5px;">
                            campanha teste</div>
                        <header class="step-mark">
                            <div class="row">
                                <div class="col-12 col-md-2 text-center">
                                    <div (click)="scrollToElement('#choose-ong-section')"
                                        class="logo-circle box-80 mt-1 mr-2 p-2">
                                        <img src="{{ selectedCause.logo }}" alt="{{ selectedCause.name }}">
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 text-center">
                                    <h4 #howtohelp id="how-to-help" style="justify-content: center;">
                                        {{ 'payment.title' | translate }}
                                    </h4>
                                    <p *ngIf="registerFormStep1" class="text-center step1">
                                        {{ 'payment.step1-subtitle' | translate }} 😍
                                    </p>
                                    <p *ngIf="registerFormStep1" class="text-center step1">(<a
                                            (click)="scrollToElement('#choose-ong-section')"
                                            style="color:white;cursor: pointer;">{{ 'payment.change-cause' | translate }}</a>)
                                    </p>
                                    <p *ngIf="registerFormStep2" class="text-center step2"
                                        style="color: #369ff4;font-weight: bold;">
                                        {{ 'payment.donation-of' | translate }}
                                        {{ 'currency-symbol' | translate:campaign }}{{ donationUser.donation | number:'.2-2' }}
                                        <span *ngIf="donationUser.monthly">{{ 'payment.monthly' | translate }}</span>
                                        (<a (click)="backToStep1()">{{ 'payment.back-to-step1' | translate }}</a>)
                                    </p>
                                </div>
                            </div>
                        </header>
                    </div>
                    <!-- open .grid-container -->
                    <div class="grid-container" id="formImageHeaderContainerMobile">
                        <!-- <img src="{{ detailNgoImageMobile }}" alt="" class="hands-image hide-for-medium"> -->
                        <!-- <img src="../../../assets/99causas/lp-abril2019/img/maos-coracao.png" alt="" class="hands-image hide-for-medium"> -->
                        <form id="registerForm" *ngIf="registerFormStep1" class="grid-x grid-margin-x" #f="ngForm"
                            novalidate data-abide (ngSubmit)="saveForm(f.value, f)">
                            <div class="alert callout" style="display: none;">
                                <p><i class="fi-alert"></i>{{ 'payment.step1-error' | translate }}</p>
                            </div>
                            <fieldset>
                                <ol class="no-bullet">
                                    <li class="step1">
                                        <div class="input-container-left">
                                            <input id="nameIpt" type="text"
                                                placeholder="{{ 'payment.step1-placeholder-name' | translate }}"
                                                class="name" name="name" [(ngModel)]="donationUser.name" #name="ngModel"
                                                required
                                                [ngClass]="{'danger-input': name.invalid && (name.dirty || name.touched || f.submitted)  }" />
                                            <div *ngIf="name.invalid && (name.dirty || name.touched || f.submitted)"
                                                class="form-error is-visible">
                                                {{ 'payment.step1-error-name' | translate }}
                                            </div>
                                        </div>
                                        <div class="input-container-right">
                                            <input id="phoneIpt" type="text"
                                                placeholder="{{ 'payment.step1-placeholder-phone' | translate }}"
                                                class="phone" name="phone" [(ngModel)]="donationUser.phone"
                                                #phone="ngModel" [required]="campaign.language == 'br'"
                                                [ngClass]="{'danger-input': (phone.invalid && (phone.dirty || phone.touched || f.submitted)) || errorPhone }" />
                                            <div *ngIf="(phone.invalid && (phone.dirty || phone.touched || f.submitted)) || errorPhone"
                                                class="form-error is-visible">
                                                {{ 'payment.step1-error-phone' | translate }}
                                            </div>
                                        </div>
                                    </li>
                                    <li class="step1">
                                        <input id="email" type="email"
                                            placeholder="{{ 'payment.step1-placeholder-email' | translate }}"
                                            class="email" name="email" [(ngModel)]="donationUser.email" #email="ngModel"
                                            required
                                            [ngClass]="{'danger-input': (email.invalid && (email.dirty || email.touched || f.submitted)) || errorEmail }" />
                                        <div *ngIf="(email.invalid && (email.dirty || email.touched || f.submitted)) || errorEmail"
                                            class="form-error is-visible">{{ 'payment.step1-error-email' | translate }}
                                        </div>
                                    </li>
                                    <li *ngIf="campaign.hasRecurringPayment" class="step1 payment-recurrence">
                                        <ul class="tabs polen-tab" data-tabs>
                                            <li class="tabs-title single-payment is-active">
                                                <a (click)="selectPaymentRecurrence('single-pay',$event.currentTarget,null,'.payment-recurrence')"
                                                    href="javascript:void(0)"
                                                    title="Como funciona">{{ 'payment.single-pay' | translate }}</a>
                                            </li>
                                            <li class="tabs-title monthly-payment"><a href="javascript:void(0)"
                                                    (click)="selectPaymentRecurrence('monthly-pay',$event.currentTarget,null,'.payment-recurrence')"
                                                    title="Como funciona">{{ 'payment.monthly-pay' | translate }}</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="step1">
                                        <div class="amount-wrapper">
                                            <div class="amount-buttons-wrapper">
                                                <a (click)="selectAmount(preset.donation)"
                                                    *ngFor="let preset of campaign.presetDonationList;let i = index"
                                                    [ngClass]="{'selected': donateAmount == preset.donation }"
                                                    [ngStyle]="campaign.presetDonationList.length == 2 ? { 'width': 'calc(45%)'} : {'width': 'calc(29%)'}"
                                                    class="amount-button-grey">{{ 'currency-symbol' | translate:campaign }}
                                                    {{ preset.donation }}</a>
                                                <a (click)="selectAmount(0)" [ngClass]="{'selected': editDonateAmount }"
                                                    [ngStyle]="(campaign.presetDonationList.length == 4 || campaign.presetDonationList.length == 1) ? { 'width': 'calc(61%)'} : {'width': 'calc(92%)'}"
                                                    class="amount-button-grey other">
                                                    <div *ngIf="!editDonateAmount">
                                                        {{ 'payment.step1-othervalue' | translate }}
                                                    </div>
                                                    <div class="editDonateAmountContainer"
                                                        *ngIf="editDonateAmount == true">
                                                        <div class="labelCurrency">
                                                            {{ 'currency-symbol' | translate:campaign }}
                                                        </div>
                                                        <input id="editAmount" type="text"
                                                            placeholder="{{ 'payment.step1-zerodecimal' | translate }}"
                                                            class="other-value" name="donation"
                                                            [(ngModel)]="donationUser.donation" #donation="ngModel" />
                                                    </div>
                                                </a>
                                                <div *ngIf="editDonateAmount && donationUser.donation < campaign.minDonationValue && f.submitted"
                                                    class="form-error is-visible" style="margin-top: 5px;
                                                text-align: center;">{{ 'payment.step1-error-value-min' | translate }}
                                                    {{ 'currency-symbol' | translate:campaign }}{{campaign.minDonationValue | number:'.2-2'}}
                                                </div>
                                                <div class="couponBanner alert alert-primary"
                                                    *ngIf="campaign.campaignType == 3 && possibleGiftCoupon != null">
                                                    <div class="couponBanner-Content">
                                                        <div class="couponBanner-Icon">
                                                            <img style="width:40px"
                                                                src="https://files.polen.app.br/images/icones/cupom-heart-red.png" />
                                                        </div>
                                                        <div class="couponBanner-Description">
                                                            <div class="couponBanner-Header">
                                                                Ao realizar uma doação você ganhará:
                                                            </div>
                                                            {{possibleGiftCoupon.title}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="errorDonation" class="errorDonation form-error is-visible">
                                            {{ 'payment.step1-error-value-empty' | translate }}
                                        </div>
                                    </li>
                                </ol>
                            </fieldset>
                            <button #btnForm class="button step1" style="background: var(--color);color:white"
                                (click)="scrollToElement('#formImageHeaderContainerMobile')" type="submit"
                                value="{{ 'buttons.want-to-help' | translate }}">{{ 'buttons.want-to-help' | translate }}</button>
                        </form>
                        <div class="payment-step grid-x grid-margin-x " *ngIf="registerFormStep2">
                            <ul *ngIf="campaign.availablePaymentMethods.length > 1"
                                class="tabs polen-tab payment-method" data-tabs>
                                <li *ngIf="IsAvailablePayment('credit_card')"
                                    class="tabs-title tab-credit_card-panel is-active"><a href="javascript:void(0)"
                                        (click)="selectPaymentMethod('cartao',$event.currentTarget,'#credit_card-panel','.payment-step')"
                                        title="Como funciona">{{ 'payment.credit-card' | translate }}</a></li>
                                <li *ngIf="IsAvailablePayment('boleto')" class="tabs-title tab-boleto-panel"><a
                                        data-tabs-target="panel2" href="javascript:void(0)"
                                        (click)="selectPaymentMethod('boleto',$event.currentTarget,'#boleto-panel','.payment-step')"
                                        title="Boleto">{{ 'payment.bankslip' | translate }}</a></li>
                                <li *ngIf="IsAvailablePayment('paypal')" class="tabs-title tab-paypal-panel"><a
                                        data-tabs-target="panel2" href="javascript:void(0)"
                                        (click)="selectPaymentMethod('paypal',$event.currentTarget,'#paypal-panel','.payment-step')"
                                        title="Paypal">Paypal</a></li>
                            </ul>
                            <fieldset style="width: 100%;">
                                <div class="tabs-content polen-tab-content payment-method-content step2"
                                    data-tabs-content="methodpayment-tabs">
                                    <div class="tabs-panel is-active" id="credit_card-panel" #creditcard>
                                        <form id="creditCardForm" #f2="ngForm" novalidate data-abide
                                            (ngSubmit)="f2.form.valid && saveForm(f2.value, f2.valid)">
                                            <ol class="no-bullet">
                                                <li>
                                                    <div class="slds-card__body slds-card__body_inner">
                                                        <div class="demo-container slds-border_top slds-border_bottom">
                                                            <div class="card-wrapper"></div>
                                                            <div class="form-container">
                                                                <div class="slds-form slds-form_compound" card
                                                                    container=".card-wrapper">
                                                                    <fieldset class="slds-form-element">
                                                                        <div class="slds-form-element__group">
                                                                            <div class="slds-form-element__row">
                                                                                <div class="slds-form-element">
                                                                                    <input type="text" name="number"
                                                                                        placeholder="{{ 'payment.step2-card-placeholder-number' | translate }}"
                                                                                        class="slds-input" card-number
                                                                                        [(ngModel)]="creditCard.display_number"
                                                                                        #display_number="ngModel"
                                                                                        required
                                                                                        [ngClass]="{'danger-input': (display_number.invalid && (display_number.dirty || display_number.touched || f2.submitted)) || errorDisplayNumber || error2DisplayNumber.length > 0 }" />
                                                                                </div>
                                                                                <div *ngIf="(display_number.invalid && (display_number.dirty || display_number.touched || f2.submitted)) || errorDisplayNumber"
                                                                                    class="form-error is-visible">
                                                                                    {{ 'payment.step2-card-error-number' | translate }}
                                                                                </div>
                                                                                <div *ngIf="error2DisplayNumber.length > 0 "
                                                                                    class="form-error is-visible">
                                                                                    {{ error2DisplayNumber}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="slds-form-element__row">
                                                                                <div class=" slds-size_1-of-2">
                                                                                    <div class="slds-form-element">
                                                                                        <input type="text"
                                                                                            name="holder_name"
                                                                                            placeholder="{{ 'payment.step2-card-placeholder-holdername' | translate }}"
                                                                                            class="slds-input" card-name
                                                                                            [(ngModel)]="creditCard.holder_name"
                                                                                            #holder_name="ngModel"
                                                                                            required
                                                                                            [ngClass]="{'danger-input': (holder_name.invalid && (holder_name.dirty || holder_name.touched || f2.submitted)) || error2HolderName.length > 0 }" />
                                                                                    </div>
                                                                                    <div *ngIf="holder_name.invalid && (holder_name.dirty || holder_name.touched || f2.submitted)"
                                                                                        class="form-error is-visible">
                                                                                        {{ 'payment.step2-card-error-holdername' | translate }}
                                                                                    </div>
                                                                                    <div *ngIf="error2HolderName.length > 0 "
                                                                                        class="form-error is-visible">
                                                                                        {{ error2HolderName}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="input-container-left">
                                                                                    <input type="text" name="expiry"
                                                                                        placeholder="MM/YYYY"
                                                                                        class="slds-input" card-expiry
                                                                                        [(ngModel)]="creditCard.month_year"
                                                                                        #month_year="ngModel" required
                                                                                        [ngClass]="{'danger-input': (month_year.invalid && (month_year.dirty || month_year.touched || f2.submitted)) || errorMonthYear || error2MonthYear.length > 0  }" />
                                                                                    <div *ngIf="(month_year.invalid && (month_year.dirty || month_year.touched || f2.submitted)) || errorMonthYear"
                                                                                        class="form-error is-visible">
                                                                                        {{ 'payment.step2-card-error-expiredate' | translate }}
                                                                                    </div>
                                                                                    <div *ngIf="error2MonthYear.length > 0 "
                                                                                        class="form-error is-visible">
                                                                                        {{ error2MonthYear}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-container-right">
                                                                                    <input type="text" name="cvc"
                                                                                        placeholder="CVC"
                                                                                        class="slds-input" card-cvc
                                                                                        [(ngModel)]="creditCard.bin"
                                                                                        #bin="ngModel" required
                                                                                        [ngClass]="{'danger-input': (bin.invalid && (bin.dirty || bin.touched || f2.submitted)) || errorCVV || error2CVV.length > 0 }" />
                                                                                    <div *ngIf="(bin.invalid && (bin.dirty || bin.touched || f2.submitted)) || errorCVV"
                                                                                        class="form-error is-visible">
                                                                                        {{ 'payment.step2-card-error-cvc' | translate }}
                                                                                    </div>
                                                                                    <div *ngIf="error2CVV.length > 0 "
                                                                                        class="form-error is-visible">
                                                                                        {{ error2CVV}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="slds-form-element__row">
                                                                                <div class=" slds-size_1-of-2">
                                                                                    <div class="slds-form-element">
                                                                                        <input type="text" id="document"
                                                                                            name="document"
                                                                                            [(ngModel)]="donationUser.identifier"
                                                                                            #identifier="ngModel"
                                                                                            placeholder="{{ 'payment.step2-card-placeholder-document' | translate }}"
                                                                                            class="document slds-input"
                                                                                            required
                                                                                            [ngClass]="{'danger-input': (identifier.invalid && (identifier.dirty || identifier.touched || f2.submitted) || errorIdentifier)  }" />
                                                                                    </div>
                                                                                    <div *ngIf="(identifier.invalid && (identifier.dirty || identifier.touched || f2.submitted)) || errorIdentifier"
                                                                                        class="form-error is-visible">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="slds-form-element__row">
                                                                                <div class=" slds-size_1-of-2">
                                                                                    <label class="container-checkbox">
                                                                                        {{ 'payment.step2-cover-bankfee-costs' | translate }}
                                                                                        {{ 'currency-symbol' | translate:campaign }}{{ suggestedBankFee | number:'.2-2' }}
                                                                                        <input id="paybanktaxes"
                                                                                            type="checkbox" />
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="slds-form-element__row">
                                                                                <div class=" slds-size_1-of-2">
                                                                                    <label
                                                                                        class="container-checkbox">{{ 'payment.step2-agreewith' | translate }}
                                                                                        <a (click)="showUseTerms()">
                                                                                            {{ 'payment.step2-useterms' | translate }}
                                                                                        </a>
                                                                                        <input id="useterms"
                                                                                            type="checkbox" />
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <div *ngIf="errorUseTerms"
                                                                                        class="form-error is-visible"
                                                                                        style="margin-top: 5px;">
                                                                                        {{ 'payment.step2-useterms-error' | translate }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ol>
                                            <div *ngIf="errorTokenUnknown" class="alert callout">
                                                <p><i class="fi-alert"></i>
                                                    {{ 'payment.step2-card-msg-payment-error1' | translate }}
                                                    <br>{{ 'payment.step2-card-msg-payment-error2' | translate }}
                                                </p>
                                            </div>
                                            <div id="RecaptchaCreditCardField"></div>
                                            <button class="button step2" type="submit" id="confirmRegistration"
                                                value="{{ 'payment.step2-paybutton-creditcard' | translate }}"
                                                style="background: var(--color);color:white;">
                                                {{ 'payment.step2-paybutton-creditcard' | translate }}</button>
                                            <!-- <button class="button step2 show-for-small-only" type="submit"
                                            style="background: var(--color);color:white" id="confirmRegistration"
                                            value="Confirmar!">
                                            Realizar Doação</button> -->
                                        </form>
                                        <input type="hidden" id="google-captcha" />
                                    </div>
                                    <div class="tabs-panel" id="boleto-panel" #bankslip style="display:none">
                                        <img class="bankslip-logo"
                                            src="../../../assets/custom/images/donation-app/boleto_bancario.jpg" />
                                        <p class="bankslip-donationlabel">
                                            {{ 'payment.donation-of' | translate }}
                                            {{ 'currency-symbol' | translate:campaign }}{{ donationUser.donation | number:'.2-2' }}
                                        </p>
                                        <p style="text-align: center;margin-top: 5px;font-size: 12px;">
                                            {{ 'payment.step2-bankslip-info' | translate }}
                                        </p>
                                        <div class="slds-form-element__row">
                                            <div class=" slds-size_1-of-2">
                                                <div class="slds-form-element">
                                                    <input type="text" id="document" name="document"
                                                        [(ngModel)]="donationUser.identifier" #identifier="ngModel"
                                                        placeholder="{{ 'payment.step2-card-placeholder-document' | translate }}"
                                                        class="document slds-input" required
                                                        [ngClass]="{'danger-input': (identifier.invalid && (identifier.dirty || identifier.touched || f2.submitted) || errorIdentifier)  }" />
                                                </div>
                                                <div *ngIf="(identifier.invalid && (identifier.dirty || identifier.touched || f2.submitted)) || errorIdentifier"
                                                    class="form-error is-visible">
                                                    {{ 'payment.step2-card-error-document' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="slds-form-element__row">
                                            <div class=" slds-size_1-of-2">
                                                <label
                                                    class="container-checkbox">{{ 'payment.step2-cover-bankfee-costs' | translate }}
                                                    {{ 'currency-symbol' | translate:campaign }}{{ suggestedBankFee | number:'.2-2' }}
                                                    <input id="paybanktaxes" type="checkbox" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="slds-form-element__row">
                                            <div class=" slds-size_1-of-2">
                                                <label class="container-checkbox">
                                                    {{ 'payment.step2-agreewith' | translate }}
                                                    <a (click)="showUseTerms()">
                                                        {{ 'payment.step2-useterms' | translate }}
                                                    </a>
                                                    <input id="useterms" type="checkbox" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div *ngIf="errorUseTerms" class="form-error is-visible"
                                                    style="margin-top: 5px;">
                                                    {{ 'payment.step2-useterms-error' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <button class="button step2" type="button" (click)="saveForm()"
                                            style="background: var(--color);color:white"
                                            style="background: var(--color);color:white;"
                                            id="confirmRegistrationBankSlip"
                                            value="{{ 'payment.step2-paybutton-bankslip' | translate }}">{{ 'payment.step2-paybutton-bankslip' | translate }}</button>
                                    </div>
                                    <div class="tabs-panel" id="paypal-panel" #paypal style="display:none">
                                        <img class="bankslip-logo"
                                            src="../../../assets/custom/images/donation-app/paypal-logo.png" />
                                        <p class="bankslip-donationlabel">
                                            {{ 'payment.donation-of' | translate}}
                                            {{ 'currency-symbol' | translate:campaign}}{{ donationUser.donation | number:'.2-2' }}
                                        </p>
                                        <p style="text-align: center;margin-top: 5px;font-size: 12px;"
                                            [innerHtml]="'payment.step2-paypal-info' | translate">
                                        </p>
                                        <!-- <div class="slds-form-element__row">
                                            <div class=" slds-size_1-of-2">
                                                <div class="slds-form-element">
                                                    <input type="text" id="document" name="document"
                                                        [(ngModel)]="donationUser.identifier" #identifier="ngModel"
                                                        placeholder="{{ 'payment.step2-card-placeholder-document' | translate }}"
                                                        class="document slds-input" required
                                                        [ngClass]="{'danger-input': (identifier.invalid && (identifier.dirty || identifier.touched || f2.submitted) || errorIdentifier)  }" />
                                                </div>
                                                <div *ngIf="(identifier.invalid && (identifier.dirty || identifier.touched || f2.submitted)) || errorIdentifier"
                                                    class="form-error is-visible">
                                                    {{ 'payment.step2-card-error-document' | translate }}
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="slds-form-element__row">
                                            <div class=" slds-size_1-of-2">
                                                <label
                                                    class="container-checkbox">{{ 'payment.step2-cover-bankfee-costs' | translate }}
                                                    {{ 'currency-symbol' | translate:campaign }}{{ suggestedBankFee | number:'.2-2' }}
                                                    <input id="paybanktaxes" type="checkbox" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="slds-form-element__row">
                                            <div class=" slds-size_1-of-2">
                                                <label class="container-checkbox">
                                                    {{ 'payment.step2-agreewith' | translate }}
                                                    <a (click)="showUseTerms()">
                                                        {{ 'payment.step2-useterms' | translate }}
                                                    </a>
                                                    <input id="useterms" type="checkbox" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div *ngIf="errorUseTerms" class="form-error is-visible"
                                                    style="margin-top: 5px;">
                                                    {{ 'payment.step2-useterms-error' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <button class="button step2" type="button" (click)="saveForm()"
                                            style="background: var(--color);color:white;margin-top:15px"
                                            id="confirmRegistrationBankSlip" value="{{ 'donate' | translate}}">
                                            {{ 'donate' | translate}}
                                            {{ 'currency-symbol' | translate:campaign}}{{ donationUser.donation | number:'.2-2' }}
                                            {{ 'with' | translate}} Paypal
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <p class="security-payment-text">
                        <img src="https://storage.googleapis.com/sealpolen-files.polinizador.com.br/images/campanha/mulheresquecausam2020/lock-green.svg"
                            style="width: 13px;margin-top: -5px;">
                        {{ 'payment.certified-text' | translate }}
                        <!-- <a (click)="openThankyou()">thankyou</a> -->
                    </p>
                    <!-- close .grid-container -->
                </div>
              <div id="polen-widget"></div>
            </div>
        </div>
    </section>
    <ngx-smart-modal #usetermsModal identifier="usetermsModal" customClass="small-modal">
        <section id="signup-form" style="margin: 0 !important;padding: 0 !important;">
            <div id="useterms-container" class="grid-x align-bottom">
                <!-- <div class="cell medium-6 show-for-medium">
            <img src="../../../assets/99/img/maos-coracao.png" alt="" class="hands-image">
        </div> -->
                <div class="cell medium-12 mb-4">
                    <div class="grid-container">
                        <header class="step-mark">
                            <h4 id="how-to-help" style="margin-bottom: 0;">{{ 'payment.step2-useterms' | translate }}
                            </h4>
                        </header>
                    </div>
                    <!-- open .grid-container -->
                    <div class="grid-container">
                        <img src="{{ campaign.logo }}" style="max-height: 120px;" alt=""
                            class="hands-image hide-for-medium">
                        <form class="grid-x grid-margin-x " style="padding: 20px 10px;">
                            <fieldset class="useterm-box" *ngIf="campaign != null">
                                <use-terms [campaignName]="campaign.name" [campaign]="campaign"></use-terms>
                            </fieldset>
                            <button class="button" (click)="ngxSmartModalService.getModal('usetermsModal').close()"
                                style="background: var(--color);color:white" data-close aria-label="Close modal"
                                type="button" value="{{ 'payment.step2-useterms-gotit' | translate }}">
                                {{ 'payment.step2-useterms-gotit' | translate }}
                            </button>
                        </form>
                    </div>
                    <!-- close .grid-container -->
                </div>
            </div>
        </section>
    </ngx-smart-modal>
    <ngx-smart-modal #notpaidModal identifier="notpaidModal" customClass="small-modal">
        <div class="notpaid-popup reveal" id="notpaid-popup">
            <div class="grid-container" *ngIf="selectedCause != null"
                style="display: flex;flex-direction: column;justify-content: center;">
                <img id="logo-hero" src="../../../assets/custom/images/error-credit-card.png"
                    style="max-width: 30%;margin: 0 auto;" />
                <h3 class="text-center">{{ 'notpaidpopup.title' | translate }}</h3>
                <br>
                <p class="text-center sub-text">
                    {{ 'notpaidpopup.fail-reason' | translate }}
                </p>
                <div class="text-center sub-text">
                    {{ 'notpaidpopup.suggestion-title' | translate:selectedCause }}
                    <ul style="font-weight: bold;">
                        <li>{{ 'notpaidpopup.suggestion-1' | translate }}</li>
                        <li>{{ 'notpaidpopup.suggestion-2' | translate }}</li>
                    </ul>
                </div>
                <div>
                    <img class="center-aligned" src="{{ selectedCause.logo }}" alt="{{ selectedCause.name}}"
                        style="max-width: 200px;max-height: 200px;" />
                </div>
                <a class="participe-button button-notpaid"
                    (click)="ngxSmartModalService.getModal('notpaidModal').close()" title="Tente outra vez">
                    {{ 'notpaidpopup.try-again' | translate }}</a>
            </div>
        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #confirmationModal identifier="confirmationModal" customClass="small-modal">
        <div class="confirmation-popup reveal" id="confirmation-popup"
            [ngClass]="{'confirmation-bankslip': bankSlipBarCode != null  }">
            <div class="grid-container" *ngIf="selectedCause != null">
                <p *ngIf="paymentMethod == 'credit_card'" class="text-center thankyou_header success_header">
                    {{ 'thankyou.title-creditcard' | translate }}
                </p>
                <p *ngIf="paymentMethod == 'boleto'" class="text-center thankyou_header waiting_header">
                    {{ 'thankyou.title-bankslip' | translate }}
                </p>
                <p *ngIf="paymentMethod == 'paypal'" class="text-center thankyou_header paypal_header">
                    {{ 'thankyou.title-paypal' | translate }}
                </p>
                <img style="max-width: 150px;border-radius: 50%;background: white;" class="center-aligned"
                    src="{{ selectedCause.logo }}" alt="{{ selectedCause.name}}" />
                <p class="text-center sub-text" style="max-width: 90%;">
                    {{ selectedCause.thankyouMessage }}
                </p>
                <div class="couponThankyou">
                    <div class="couponBanner alert alert-success"
                        *ngIf="campaign.campaignType == 3 && possibleGiftCoupon != null && paymentMethod == 'credit_card'">
                        <div class="couponBanner-Content">
                            <div class="couponBanner-Icon">
                                <img style="width:80px"
                                    src="https://files.polen.app.br/images/icones/confetti-icon.png" />
                            </div>
                            <div class="couponBanner-Description">
                                <div class="couponBanner-Header">
                                    Para retribuir seu gesto de carinho queremos te presentear com:
                                </div>
                                <div>{{possibleGiftCoupon.title}}</div>
                                <div *ngIf="possibleGiftCoupon.coupon">
                                    <div class="couponBanner-Header">Basta usar em nosso site o cupom:</div>
                                    <div class="couponCodeShow">
                                        {{possibleGiftCoupon.coupon}}
                                    </div>
                                </div>
                                <div *ngIf="possibleGiftCoupon.checkEmail">
                                    <b>Você receberá o cupom via e-mail!</b>
                                </div>
                                <div class="couponBanner-Header"><a class="couponShowRulesCall"
                                        (click)="showCouponRules()">Atenção para as regras de uso</a>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <a class="participe-button button-notpaid" href="{{ campaign.companyMainDomain }}"
                            target="_blank" style="margin-top: 0px;display: inline-table;"
                            title="Utilizar o cupom no site">
                            Ir para o site</a>
                    </div>
                    <div class="couponBanner alert alert-warning"
                        *ngIf="campaign.campaignType == 3 && possibleGiftCoupon != null && paymentMethod != 'credit_card'">
                        <div class="couponBanner-Content">
                            <div class="couponBanner-Icon">
                                <img style="width:40px"
                                    src="https://files.polen.app.br/images/icones/timer-colored-icon.png" />
                            </div>
                            <div class="couponBanner-Description">
                                <div class="couponBanner-Header">
                                    Estamos aguardando seu pagamento! <br>Ao realizar a doação você receberá:
                                </div>
                                {{possibleGiftCoupon.title}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="paymentMethod == 'paypal' && invoiceUrl">
                    <a href="{{ invoiceUrl }}" target="_blank" class="button paypal-button" title="Acessar paypal">
                        {{ 'donate' | translate }} {{ 'with' | translate }} Paypal</a>
                </div>
                <div *ngIf="bankSlipBarCode" class="barcode-html">
                    <p>{{bankSlipBarCode}}</p>
                    <svg style="width: 100%;" id="barcode"></svg>
                    <a href="{{ bankSlipUrl }}" target="_blank" class="button participe-button"
                        title="{{ 'thankyou.open-bankslip' | translate }}">{{ 'thankyou.open-bankslip' | translate }}</a>
                </div>
                <div style="text-align: center;font-size: 13px;margin-bottom: 5px;">
                    {{ 'thankyou.email-message' | translate }}
                </div>
                <div>
                    <div id="donation-{{selectedCause.url}}" data-id="{{selectedCause.url}}"
                        class="love-btn polen-ong-card polen-ong-card-{{selectedCause.url}}" href="javascript:void(0)"
                        title="Apoie a {{selectedCause.name}}">
                        <div>
                            <img src="{{ selectedCause.image }}" alt="{{ selectedCause.name}}" style="width: 100%;" />
                        </div>
                    </div>
                </div>
                <h3 class="text-center">{{ 'thankyou.share-idea' | translate }}:</h3>
                <p class="text-center sub-text">{{ 'thankyou.invite-friends' | translate }}</p>
            </div>
            <div class="share99">
                <div>
                    <link rel="stylesheet"
                        href="https://netdna.bootstrapcdn.com/font-awesome/3.1.1/css/font-awesome.css" />
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" />
                    <style>
                        .share99 {
                            text-align: center;
                            margin-bottom: -35px;
                        }

                        /* Wrapper */
                        .icon-button {
                            background-color: white;
                            border-radius: 3.6rem;
                            cursor: pointer;
                            display: inline-block;
                            font-size: 1.2rem;
                            height: 3.6rem;
                            line-height: 3.6rem;
                            margin: 0 5px;
                            position: relative;
                            text-align: center;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            width: 3.6rem;
                        }

                        /* Circle */
                        .icon-button span {
                            display: block;
                            left: 50%;
                            position: absolute;
                            top: 50%;
                            -webkit-transition: all 0.3s;
                            -moz-transition: all 0.3s;
                            -o-transition: all 0.3s;
                            transition: all 0.3s;
                            width: 2.2rem;
                            height: 2.2rem;
                            border-radius: 2rem;
                            margin: -1.1rem;
                        }

                        .twitter span {
                            background-color: #4099ff;
                        }

                        .facebook span {
                            background-color: #3B5998;
                        }

                        .instagram span {
                            background-color: #DF3796;
                        }

                        .whatsapp span {
                            background-color: #1DBEA5;
                        }

                        /* Icons */
                        .icon-button i {
                            background: none;
                            color: white;
                            height: 3.6rem;
                            left: 0;
                            line-height: 3.6rem;
                            position: absolute;
                            top: 0;
                            -webkit-transition: all 0.3s;
                            -moz-transition: all 0.3s;
                            -o-transition: all 0.3s;
                            transition: all 0.3s;
                            width: 3.6rem;
                            z-index: 10;
                        }

                        .icon-button .icon-whatsapp,
                        .icon-button .icon-twitter,
                        .icon-button .icon-facebook,
                        .icon-button .icon-instagram {
                            color: white;
                        }
                    </style>
                    <div>
                        <a target="_blank"
                            href="whatsapp://send?text={{sharedText}}"
                            target="_blank" class="icon-button whatsapp polen-desktop"><i
                                class="fab fa-whatsapp whats-desktop"></i><span></span></a>
                        <a target="_blank"
                            href="whatsapp://send?text={{sharedText}}"
                            target="_blank" class="icon-button whatsapp polen-mobile"><i
                                class="fab fa-whatsapp whats-mobile"></i><span></span></a>
                        <a target="_blank"
                            href="http://www.facebook.com/share.php?u=https://doe.polen.com.br/{{ campaign.subDomainUrl }}?utm_source=fb"
                            class="icon-button facebook"><i class="icon-facebook"></i><span></span></a>
                        <a target="_blank"
                            href="https://twitter.com/intent/tweet?text={{twitterShareText}}&original_referer=https://doe.polen.com.br/{{ campaign.subDomainUrl }}"
                            class="icon-button twitter"><i class="icon-twitter"></i><span></span></a>
                    </div>
                </div>
            </div>
            <div class="cta__download" style="margin-top: 1rem;">
                <p class="text-center">{{ 'thankyou.get-an-email-instructions' | translate }}
                    <br>
                    <br>
                </p>
                <div class="powered-polen text-center" style="justify-content: center;">
                    <span style="display: block;" class="">{{ 'thankyou.campaign-madeby-polen' | translate }}</span>
                    <a href="https://polen.com.br/empresas">
                        <img style="max-width: 80px;"
                            src="https://polen.com.br/content/v6/images/logo-polen-horizontal.svg" alt="Polen">
                    </a>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #couponRulesModal identifier="couponRulesModal" customClass="small-modal" style="z-index: 1047;">
        <div class="notpaid-popup reveal" id="notpaid-popup" style="z-index: 1047;">
            <div class="grid-container" *ngIf="possibleGiftCoupon != null"
                style="display: flex;flex-direction: column;justify-content: center;">
                <img id="logo-hero" src="https://files.polen.app.br/images/icones/coupon-waiting-colored.png"
                    style="max-width: 20%;margin: 0 auto;" />
                <h3 class="text-center">Regras de uso do Cupom</h3>
                <br>
                <p class="text-center sub-text">
                    Veja abaixo as regras de uso para utilizar este cupom:
                </p>
                <div class="text-center sub-text">
                    <p *ngIf="campaign.rules != null">{{ campaign.rules}}<br></p>
                    {{ possibleGiftCoupon.infoRule }}
                </div>
                <a class="participe-button button-notpaid" href="{{ campaign.companyMainDomain }}" target="_blank"
                    title="Utilizar o cupom no site">
                    Ir para o site</a>
            </div>
        </div>
    </ngx-smart-modal>
</div>
<div style="display: none;">
    <button (click)="setLoading(true)">load true</button>
    <button (click)="setLoading(false)">load false</button>
</div>
<div *ngIf="selectedCause != null && campaign.status == 3" style="display: none">
    <section #signupform id="signup-form" class="wrapper-section bg-gray"
        style="display:block;background-repeat: no-repeat;background-size: cover;"
        [ngStyle]="{ 'background-image': 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url(' + detailNgoImageBackground + ')'}"
        *ngIf="donationUser != null && creditCard != null && selectedCause != null">
        <div class="container">
            <div class="row">
                <div class="col-6 d-sm-none d-xs-none d-md-block d-lg-block">
                </div>
                <div class="col-sm-6 col-xs-6 col-md-12 col-lg-12 signup">
                    <div class="grid-container">
                        <header class="step-mark">
                            <div class="row">
                                <div class="col-12 col-md-2 text-center">
                                    <div (click)="scrollToElement('#choose-ong-section')"
                                        class="logo-circle box-80 mt-1 mr-2 p-2">
                                        <img src="{{ selectedCause.logo }}" alt="{{ selectedCause.name }}">
                                    </div>
                                </div>
                                <div class="col-12 col-md-10 text-center">
                                    <h4 #howtohelp id="how-to-help" style="justify-content: center;">
                                        {{ 'payment.title' | translate }}
                                    </h4>
                                    <p *ngIf="registerFormStep1" class="text-center step1">
                                        {{ 'payment.step1-subtitle' | translate }} 😍
                                    </p>
                                    <p *ngIf="registerFormStep1" class="text-center step1">(<a
                                            (click)="scrollToElement('#choose-ong-section')"
                                            style="color:white;cursor: pointer;">{{ 'payment.change-cause' | translate }}</a>)
                                    </p>
                                    <p *ngIf="registerFormStep2" class="text-center step2"
                                        style="color: #369ff4;font-weight: bold;">
                                        {{ 'payment.donation-of' | translate }}
                                        {{ 'currency-symbol' | translate:campaign }}{{ donationUser.donation | number:'.2-2' }}
                                        <span *ngIf="donationUser.monthly">{{ 'payment.monthly' | translate }}</span>
                                        (<a style="cursor: pointer;"
                                            (click)="backToStep1()">{{ 'payment.back-to-step1' | translate }}</a>)
                                    </p>
                                </div>
                            </div>
                        </header>
                    </div>
                    <!-- open .grid-container -->
                    <div class="grid-container" id="formImageHeaderContainerMobile">
                        <!-- <img src="{{ detailNgoImageMobile }}" alt="" class="hands-image hide-for-medium"> -->
                        <!-- <img src="../../../assets/99causas/lp-abril2019/img/maos-coracao.png" alt="" class="hands-image hide-for-medium"> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
