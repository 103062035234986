<div class="direct-donation-ngo">
  <div [style.background-color]="primaryColor" class="polen-direct-donation-content">
    <header class="polen-direct-donation-header">
      <h2 class="title-campaign" [style.color]="textColor">Doe para a organização</h2>
      <h2 class="title-campaign" [style.color]="textColor">{{campaign.ownerName}}</h2>
      <p *ngIf="showAbout" class="about-campaign" [innerHTML]="about" [style.color]="textColor"></p>
    </header>
    <main [class.withoutText] = "!showAbout" class="polen-widget-direct-donation">
      <div id="polen-widget"></div>
    </main>
    <div *ngIf="campaign.cover" class="cover-image" [ngStyle]="{'background-image': 'url('+campaign.cover+')'}"></div>
  </div>
  
  <footer class="polen-footer-direct-donation">
    <div class="content-about-polen">
      <img src="https://polen.com.br/content/v6/images/logo-polen-vertical.svg" alt="logo do polen">
      <div class="text-about-polen">
        <p>Transparência, repasse e uso das doações vericadas pelo polen.</p>
        <br/>
        <p>2017 - 2021 @ Polen Tecnologia LTDA.</p>
        <p>CNPJ 28.133.440/001-36</p>
        <p>eupolinizo@opolen.com.br</p>
      </div>
    </div>
    <div class="list-link">
      <dl class="list-link-polen">
        <dt>POLEN</dt>
        <dd><a href="https://polen.com.br/sobre" target="_blank">Sobre nós</a></dd>
        <dd><a href="https://polen.com.br/#" target="_blank">Impacto do Polen</a></dd>
        <dd><a href="https://polen.com.br/empresas" target="_blank">Para empresas</a></dd>
        <dd><a href="https://polen.com.br/termos-de-uso-e-politica-privacidade" target="_blank">Termos de uso</a></dd>
        <dd><a href="https://polen.com.br/contato" target="_blank">Fale conosco</a></dd>
      </dl>
      <dl class="list-link-ngo">
        <dt>{{campaign.name}}</dt>
        <dd *ngIf="campaign.companyMainDomain"><a href="{{campaign.companyMainDomain}}" target="_blank">Conheça a ONG</a></dd>
        <dd><a href="https://polen.com.br/{{campaign.ngoUrl}}" target="_blank">Transparência das doações</a></dd>
      </dl>
    </div>
    <div class="polen-to-company">
      <p>Tem uma empresa e gostaria de nos apoiar com frequência?</p>
      <button class="support-btn" (click)="activeModal()">
        <img src="https://polen.com.br/content/v6/images/transparencia/apoie-como-empresa-ativo.svg" alt="imagem empresa">
        <span>APOIE COMO EMPRESA</span>
      </button>
    </div>
  </footer>

  <div class="modal-container" *ngIf="showModal">
    <div id="modal-content">
      <button class="btn-close" (click)="showModal=false">X</button>
    </div>
  </div>

</div>
