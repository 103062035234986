import { Configuration } from '../app.constants'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private http: HttpClient) {
  }

  list(): any {
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/GetListByStoreUrl')
  }


  loadCreate(): any {
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/Create')
  }

  create(setting): any {
    //console.log(setting);
    return this.http.post(Configuration.apiUrl + 'Api/Campaign/Create', JSON.stringify(setting), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  getBySubdomain(subdomain, campaignUrl): any {
    //console.log(Configuration.apiUrl + 'Api/Campaign/GetSubdomain?subdomain=' + subdomain + '&campaignUrl=' + campaignUrl)
    if (campaignUrl == null)
      return this.http.get(Configuration.apiUrl + 'Api/Campaign/GetSubdomain?subdomain=' + subdomain)
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/GetSubdomain?subdomain=' + subdomain + '&campaignUrl=' + campaignUrl)
  }

  getAnalytics(campaignId): any {
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/GetAnalytics?campaignId=' + campaignId)
  }

  get(campaignId): any {
    console.log(Configuration.apiUrl + 'Api/Campaign/GetCampaign?campaignId=' + campaignId);
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/GetCampaign?campaignId=' + campaignId)
  }

  getCouponCodeById(campaignId, couponId): any {
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/GetCouponCode?campaignId=' + campaignId + '&couponId=' + couponId)
  }

  edit(setting): any {
    console.log(setting);
    return this.http.post(Configuration.apiUrl + 'Api/Campaign/Edit', JSON.stringify(setting), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  editCustomFields(campaignId, setting): any {
    console.log(setting);
    return this.http.post(Configuration.apiUrl + 'Api/Campaign/EditCustomFields?campaignId=' + campaignId, JSON.stringify(setting), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  removeImage(id, campaignId): any {
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/RemoveImage?campaignId=' + campaignId + '&id=' + id)
  }

  validateSubDomain(subdomain): any {
    return this.http.get(Configuration.apiUrl + 'Api/Campaign/ValidateSubDomain?subdomain=' + subdomain)
  }

  sendCallRequest(phoneModel): any {
    debugger
    console.log(phoneModel);
    return this.http.post(Configuration.apiUrl + 'Api/Onboarding/SendCallRequest', JSON.stringify(phoneModel), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  clearStoreCache(storeName): any {
    return this.http.get(Configuration.apiUrl + 'Api/Profile/ClearStoreCache?storeName=' + storeName)
  }
}
