import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/services/googleanalytics.service';
declare var jQuery: any;

@Component({
  selector: 'ong-selector',
  templateUrl: './ong-selector.component.html',
  styleUrls: ['./ong-selector.component.css']
})
export class OngSelectorComponent implements OnInit {

  @Input() ngoList: any
  @Input() color: any
  @Input() selectedCause: any
  @Output() answer = new EventEmitter();
  public isAdmin: boolean
  colorLight: string

  constructor(public gaService: GoogleAnalyticsService) { }

  ngOnInit() {
    console.log(this.color)
    this.colorLight = this.color
  }

  selectNgo(ngo: any, $element) {
    debugger
    this.gaService.trackEvent("selectCause", ngo.url);

    jQuery(".ong").addClass("not-selected")
    jQuery($element).parents(".ong").removeClass("not-selected")
    this.answer.emit({
      "ngo": ngo
    });
  }

}
