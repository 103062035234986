import { Component, OnInit, Input } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-direct-donation-ngo',
  templateUrl: './direct-donation-ngo.component.html',
  styleUrls: ['./direct-donation-ngo.component.css']
})
export class DirectDonationNgoComponent implements OnInit {
  @Input() campaign: any;
  primaryColor: string="#9b599d";
  textColor: string = "#000000";
  about: string = "";
  href: string = "";
  showModal: boolean = false;
  showAbout: boolean = true;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.campaign.customFields.forEach(item => {
      if (item.key === "primaryColor"){
        this.primaryColor = item.value;
      } else if(item.key === "about"){
        this.about = item.value;
      } else if(item.key ==="showAbout"){
        this.showAbout = item.value.toLowerCase() == "true";
      }
    });

    if (this.primaryColor.length === 4 || this.primaryColor.length === 7) {
      let r, g, b: any;
      let hsp: any;
      let newColor: any;

      newColor = +("0x" + this.primaryColor.slice(1).replace(this.primaryColor.length < 5 && /./g, '$&$&'));

      r = newColor >> 16;
      g = newColor >> 8 & 255;
      b = newColor & 255;

      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      );

      if (hsp > 127.5) {
        this.textColor = "#000000";
      }
      else {
        this.textColor = "#FFFFFF";
      }
    }
    setTimeout(() => {

      if(this.campaign){
        this.href = this.router.url;
        let container = document.getElementById("polen-widget");
        let ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", `https://widget.polen.com.br/directDonation/${this.campaign.ngoUrl}`);
        ifrm.id = "ifrm-polen"
        // ifrm.setAttribute("src", `http://127.0.0.1:3000/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
        ifrm.style.width = "100%";
        ifrm.style.height = "100%";
        ifrm.style.borderRadius = "16px";
        ifrm.style.border = "1px solid #dcdcdc";
        ifrm.style.maxWidth = "460px";
        ifrm.style.minHeight = "650px";
        ifrm.style.maxHeight = "660px";
        ifrm.style.border = "none";
        ifrm.style.background = "#FFFFFF";
        ifrm.setAttribute("allow", "clipboard-read; clipboard-write")
        // ifrm.contentWindow.postMessage({}, `https://polen-widget.vercel.app/directDonation/${this.href.replace("/", "")}`);

        container.append(ifrm);
      }
    }, 1500);
  }

  activeModal():void {
    this.showModal = true;
    setTimeout(() => {
      let containerScript = document.getElementById("modal-content");
      let script = document.createElement('script');
      if(containerScript){
        script.type = "text/javascript"
        script.text = `hbspt.forms.create({
          portalId: "6216901",
          formId: "37c7f694-e8c4-4ef5-9f60-b2883d18df5f"
        });`
        containerScript.append(script);
      }

    }, 1000);
  }

}
