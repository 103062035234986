<section #howitworks class="wrapper-section" id="how-it-works" *ngIf="campaign != null">
    <div class="container">
        <div class="col-12">
            <!-- .tabs -->
            <ul class="tabs d-block d-sm-block d-md-none d-lg-none d-xl-none d-flex" data-tabs id="howitworks-tabs">
                <li class="tabs-title is-active">
                    <a href="javascript:void(0)"
                        (click)="changeTab($event.currentTarget,'#comofunciona','#faq','#how-it-works')"
                        [ngStyle]="{ 'background-color': color}" title="Como funciona">Como
                        funciona</a>
                </li>
                <li class="tabs-title"><a href="javascript:void(0)"
                        (click)="changeTab($event.currentTarget,'#faq','#comofunciona','#how-it-works')"
                        title="FAQ">FAQ</a></li>
            </ul>
            <!-- .tabs-content -->
            <div class="tabs-content" data-tabs-content="howitworks-tabs">
                <div class="tabs-panel is-active" id="comofunciona" #comofunciona>
                    <h4>{{ 'howitworks.title-howitworks' | translate }}</h4>
                    <p [innerHtml]="'howitworks.about.first-part' | translate:campaign">
                    </p>
                    <div>
                        <ul *ngFor="let ngo of campaign.supportedNgos; let i = index">
                            <li>
                                <p>
                                    <b>{{ ngo.name }}</b> : {{ ngo.description}}
                                </p>
                            </li>
                        </ul>
                    </div>
                    <p [innerHtml]="'howitworks.about.last-part' | translate:campaign">
                    </p>
                    <div class="customRules" *ngIf="campaign.campaignType == 3">
                        <div *ngIf="campaign.rules != null">Veja as regras para a utilização de cupons desta
                            campanha:<br>
                            <p>{{ campaign.rules }}</p>
                        </div>
                        <p><br>
                            Após a confirmação do pagamento você receberá um e-mail com o comprovante e o seu cupom!
                        </p>
                    </div>
                    <a class="btn btn-donate" style="background-color: var(--color);" href="javascript:void(0)"
                        (click)="scrollToElement('#choose-ong-section')"
                        title="{{ 'buttons.want-to-help' | translate }}">{{ 'buttons.want-to-help' | translate }}</a>
                </div>
                <div class="tabs-panel" id="faq" #faq>
                    <h4 class="d-none d-md-block d-lg-block d-xl-block">{{ 'howitworks.title-faq' | translate }}</h4>
                    <div class="accordion" id="accordionHowItWork">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        {{ 'howitworks.faq.title-howparticipate' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordionHowItWork">
                                <div class="card-body">
                                    {{ 'howitworks.faq.description-howparticipate' | translate:campaign }}
                                    <div *ngIf="campaign.campaignType == 3">
                                        <br>
                                        Para desbloquear seu cupom de desconto, basta escolher uma das organizações
                                        sociais disponíveis, o valor da doação que deseja fazer e o meio de
                                        pagamento. Com o pagamento realizado e confirmado, você receberá um e-mail
                                        com o cupom de desconto.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        {{ 'howitworks.faq.title-behindcampaign' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-behindcampaign' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        {{ 'howitworks.faq.title-guaranteemoney' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-guaranteemoney' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading4">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                        {{ 'howitworks.faq.title-knowpaymentconfirmed' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse4" class="collapse" aria-labelledby="heading4"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-knowpaymentconfirmed' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading5">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        {{ 'howitworks.faq.title-nocreditcard' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse5" class="collapse" aria-labelledby="heading5"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-nocreditcard' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="campaign.language == 'br'">
                            <div class="card-header" id="heading6">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        {{ 'howitworks.faq.title-bankslipask' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse6" class="collapse" aria-labelledby="heading6"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-bankslipask' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading7">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                        {{ 'howitworks.faq.title-paymentmethods' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse7" class="collapse" aria-labelledby="heading7"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-paymentmethods' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="campaign.owner == 0">
                            <div class="card-header" id="heading8">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                        {{ 'howitworks.faq.title-untilwhen' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse8" class="collapse" aria-labelledby="heading8"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-untilwhen' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading9">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                        {{ 'howitworks.faq.title-whodonateit' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse9" class="collapse" aria-labelledby="heading9"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-whodonateit' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading10">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                        {{ 'howitworks.faq.title-whendonationismake' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse10" class="collapse" aria-labelledby="heading10"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-whendonationismake' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading11">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                        {{ 'howitworks.faq.title-howkeepupdated' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse11" class="collapse" aria-labelledby="heading11"
                                data-parent="#accordionHowItWork">
                                <div class="card-body" *ngIf="campaign.owner == 0"
                                    [innerHtml]="'howitworks.faq.description-howkeepupdated' | translate:campaign">
                                </div>
                                <div class="card-body" *ngIf="campaign.owner == 1"
                                    [innerHtml]="'howitworks.faq.description-howkeepupdated-ngocampaign' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="campaign.seoUrl != 'phonetrack'">
                            <div class="card-header" id="heading12">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                        {{ 'howitworks.faq.title-isdonationrecurrent' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse12" class="collapse" aria-labelledby="heading12"
                                data-parent="#accordionHowItWork">
                                <div class="card-body" *ngIf="campaign.hasRecurringPayment"
                                    [innerHtml]="'howitworks.faq.description-isdonationrecurrent-available' | translate:campaign">
                                </div>
                                <div class="card-body" *ngIf="!campaign.hasRecurringPayment"
                                    [innerHtml]="'howitworks.faq.description-isdonationrecurrent' | translate:campaign">
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="heading13">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                        {{ 'howitworks.faq.title-whyitsaysPolenBeneficiary' | translate }}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapse13" class="collapse" aria-labelledby="heading13"
                                data-parent="#accordionHowItWork">
                                <div class="card-body"
                                    [innerHtml]="'howitworks.faq.description-whyitsaysPolenBeneficiary' | translate:campaign">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>