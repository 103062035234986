import { Configuration } from '../app.constants'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CreditCardInfo } from '../models/payment.model';
import { HelperService } from './helper.service';
import { JunoBus } from './juno.bus';

declare var DirectCheckout: any
@Injectable({
  providedIn: 'root'
})

export class JunoService {
  constructor(private http: HttpClient,
    private junoBus: JunoBus,
    private helper: HelperService) {
  }
  private junoCheckout: any
  public storeId: string
  public storeSeoUrl: string
  //Se tokenize=true
  //5.1 - Tokenizamos e fim (caso onde só estamos salvando o metodo de pagamento na assinatura)
  //5.2 - Tokenizamos e em seguida cobramos (tela de invoice, ao cobrar um statement)
  //5.3 - Tentamos tokenizar mas falha (gastamos a hash). Se falhar, pegamos a hash novamente e cobramos direto
  async tokenize(statementId, cardData, captchaToken) {
    return new Promise(async (resolve, reject) => {
      //gera a hash
      var model = await this.getCardHash(cardData);
      if (!model.success) {
        debugger
        reject(model)
        //this.showError(model.error);
        return;
      }

      //pega token e paymentMethodId
      var tokenResult = await this.tokenizeCreditCard(model.cardHash, cardData.holderName, model.brand);
      debugger
      if (tokenResult.success) {
        //se for na tela de cobranca, chamamos o /charge
        var res = await this.charge(statementId, cardData, tokenResult.paymentMethodId, captchaToken)
        resolve(res)
      }
      else {
        var res = await this.charge(statementId, cardData, null, captchaToken)
        resolve(res)
      }
    })
  }

  //Realiza a cobrança do Statement
  //6. Apenas cobramos (tela de invoice, ao cobrar um statement, usuario ñ quis salvar para próximas cobranças)
  //Se for tokenizado, faz a cobrança usando o paymentMethodId
  //Se ñ for tokenizado (paymentMethodId=null), pegamos a hash e cobramos
  async charge(statementId, cardData, paymentMethodId: string, captchaToken) {
    return new Promise(async (resolve, reject) => {
      //cartao de credito
      debugger
      if (cardData != null) {
        if (paymentMethodId == null) {
          try {
            var model = await this.getCardHash(cardData);
            debugger
            if (!model.success) {
              reject(model)
              return
            }
          } catch (e) {
            debugger
            reject({ success: false, error: e })
            return
          }
        }

        var paymentType = 1
        this.junoBus.charge(this.storeSeoUrl, statementId, paymentType, model.cardHash, null, model.last4number, cardData.holderName, model.brand, 1, captchaToken).subscribe(data => {
          debugger
          if (data.success) {
            resolve(data);
          } else {
            reject(data)
          }
        })
      }
      //boleto
      else {
        var paymentType = 2
        console.log("metodo de pagamento boleto")
        this.junoBus.charge(this.storeSeoUrl, statementId, paymentType, null, null, null, null, null, 1, captchaToken).subscribe(data => {
          debugger
          if (data.success) {
            resolve(data);
          } else {
            reject(data)
          }
        })
      }
    });
  }

  //Chamaos a API para tokenizar a partir da hash
  //return: se success=true, retorna o paymentMethodId que passamos para /charge
  async tokenizeCreditCard(hash, holderName, brand): Promise<any> {
    return new Promise((resolve, reject) => {
      this.junoBus.tokenizeCreditCard(this.storeId, hash, holderName, brand).subscribe(data => {
        debugger
        resolve(data);
      })
    });
  }

  async getCardHash(cardData): Promise<any> {
    var obj = this
    return new Promise((resolve, reject) => {
      debugger
      this.loadJunoCheckout();
      var flag = this.junoCheckout.getCardType(cardData.cardNumber);
      var last4number = cardData.cardNumber.substr(cardData.cardNumber.length - 4)
      this.junoCheckout.getCardHash(cardData, function (cardHash) {
        debugger
        resolve({ success: true, cardHash, brand: flag, last4number })
      }, function (error) {
        debugger
        reject({ success: false, error })
      });
    });
  }

  loadJunoCheckout() {
    if (this.junoCheckout == null) {
      if (Configuration.junoProduction)
        this.junoCheckout = new DirectCheckout(Configuration.junoProductionPublicKey);
      else
        this.junoCheckout = new DirectCheckout(Configuration.junoSandboxPublicKey, false);
    }
  }

  getCardData(model: CreditCardInfo) {
    debugger
    var bin = this.helper.replaceAll(model.bin, '_', '')
    var number = this.helper.replaceAll(model.display_number, ' ', '')
    number = this.helper.replaceAll(number, '_', '')
    var cardData = {
      cardNumber: number,
      holderName: model.holder_name,
      securityCode: bin,
      expirationMonth: model.month_year.split('/')[0],
      expirationYear: model.month_year.split('/')[1]
    };
    return cardData;
  }

  /* isValidCardData: Validação dos dados do cartão de crédito(retorna true se for válido) */
  async junoValidateCreditCard(cardData): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve({ valid: true });
      //TODOJUNO - tem bug nesse metodo
      // var junoValid = this.junoCheckout.isValidCardData(cardData, function (error) {
      //   debugger
      //   console.log(error);
      //   this.answer.emit({ "loading": false });
      //   this.notificationBuilder.showNotification('danger', 2000, 'Verifique se os dados estão corretos para atualizar os dados.')
      //   reject({ valid : false, error});
      //  });
      //  resolve({ valid : true});
    });
  }


}
