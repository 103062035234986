declare let gtag: Function;
declare let fbq: any;


export class GoogleAnalyticsService {

  public eventEmitter(
    eventAction: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null) {

    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': eventValue * 1.0
    })
  }

  public trackEvent(nameEvent, ngoUrl?, number?) {
    try {
      //this.eventEmitter(nameEvent, null, null, ngoUrl, number);
      this.eventEmitter(nameEvent, "click", ngoUrl, number);

      if (number != null) {
        fbq('track', nameEvent, { value: number, currency: 'BRL' });
      }
      else {
        fbq('track', nameEvent);
      }

    } catch (error) {

    }
  }

  public trackDonation(ngoUrl, value) {

  }
}