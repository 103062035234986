import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import { CustomizationComponent } from './customization/customization.component';
import { HomeComponent } from './home/home.component';
import {NgModule} from '@angular/core';
const routerOptions: ExtraOptions = {
  useHash: true,
};

const routes: Routes = [
    // {
    //     path: 'maismulher',
    //     component: MaisMulherComponent,
    //     data: { title: 'maismulher' }
    // },
    // {
    //     path: '**',
    //     component: MaisMulherComponent,
    //     data: { title: 'maismulher' }
    // }
    {
        path: '',
        component: HomeComponent,
        data: { title: 'Crie sua Campanha em minutos sem custo' }
    },
    {
        path: 'customization/:subdomain',
        component: CustomizationComponent,
        data: { title: 'Customização' }
    },
    {
        path: 'preview/:campaignId',
        component: CustomizationComponent,
        data: { title: 'Customização' }
    },
    {
        path: ':subdomain/:campaignUrl',
        component: CustomizationComponent,
        data: { title: 'Campanhas' }
    },
    {
        path: ':subdomain/:campaignUrl/:return',
        component: CustomizationComponent,
        data: { title: 'Retorno de Campanha' }
    },

    {
        path: ':subdomain',
        component: CustomizationComponent,
        data: { title: 'Customização' }
    }
];

@NgModule({
  imports:[
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
    RouterModule
  ]
})

export class LpCustomRoutes {  }
