import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { debug } from 'util';
import { NewDonationDto, CreditCardInfo, PaymentInfo } from 'src/app/models/payment.model';
import { FinanceService } from 'src/app/services/finance.service';
import { CampaignCouponModel, CampaignModel, PresetDonation } from 'src/app/models/campaign.model';
import { NgForm } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import Swal from 'sweetalert2'
import { GoogleAnalyticsService } from 'src/app/services/googleanalytics.service';
import { CampaignService } from 'src/app/services/campaign.service';
import {Router} from "@angular/router";

//declare var swal: any;
declare var JsBarcode: any;
declare var jQuery: any;
declare var $: any;
declare var ga: any;
declare var polenStorage: any;
declare var polenHelper: any;
declare var Iugu: any;
declare var grecaptcha: any;
declare var google: any;


@Component({
  selector: 'direct-donation',
  templateUrl: './direct-donation.component.html',
  styleUrls: ['./direct-donation.component.css']
})
export class DirectDonationComponent implements OnInit {

  errorCreditCard: string
  masks: any
  signupform: any
  loading: boolean
  donationUser: NewDonationDto
  creditCard: CreditCardInfo
  paymentMethod: string = 'credito';
  donateAmount: number = 0
  editDonateAmount: boolean = false
  editDonateAmountText: string
  registerFormStep1: boolean = true
  registerFormStep2: boolean = false
  errorDisplayNumber: boolean = false;
  errorMonthYear: boolean = false;
  errorCVV: boolean = false;
  errorPhone: boolean = false;
  errorEmail: boolean = false;
  errorDonation: boolean = false;
  errorIdentifier: boolean = false;
  error2MonthYear: string = '';
  error2HolderName: string = '';
  error2CVV: string = '';
  error2DisplayNumber: string = '';
  errorUseTerms: boolean;
  ngoList: any;
  ngoSelected: any;
  iuguTest: boolean = false
  detailNgoImageMobile: string
  detailNgoImage: string
  detailNgoImageBackground: string;
  suggestedBankFee: number;
  bankSlipBarCode: string = null
  invoiceUrl: string = null
  bankSlipUrl: string = null
  errorTokenUnknown: boolean = false;
  twitterShareText: string
  obj: any = this;
  possibleGiftCoupon: CampaignCouponModel = null
  public href: string = "";
  sharedText: string = ""; 

  @Input() selectedCause: any;
  @Input() campaign: CampaignModel;
  @Input() seoUrl: string;
  @Input() selectedCauses: string[] = new Array<string>();
  @Output() answer = new EventEmitter();
  // @Input() onboard: OnBoardingModel
  public isAdmin: boolean
  hasChanges: boolean = false
  successChanged: boolean = false

  constructor(private router: Router, private financeService: FinanceService, private campaignService: CampaignService, public gaService: GoogleAnalyticsService, public ngxSmartModalService: NgxSmartModalService) { }

  setLoading(status) {
    this.answer.emit({ "loading": status });
  }

  ngOnInit() {

    this.sharedText = `Pessoal, acabei de fazer apoiar a campanha ${this.campaign.name} e estou te convidando a fazer sua parte também por uma causa tão importante! Doe você também: https://doe.polen.com.br/${this.campaign.campaignUrl}`
    this.campaign.customFields.forEach(item => {
      if (item.key === "sharedText"){
        this.sharedText = item.value;
      }
    });
    this.donationUser = new NewDonationDto();
    this.creditCard = new CreditCardInfo();
    this.loadPresetDonations()
    console.log('direct-donation -> ', this.selectedCause)
    // let value = 5.5
    // this.gaService.trackEvent("concludedDonation", "cufa", value);

    this.campaign.hasRecurringPayment = this.campaign.recurringPayment || this.campaign.seoUrl == 'polen-sandbox' || this.campaign.seoUrl == 'phonetrack';
    this.iuguTest = this.campaign.seoUrl == 'polen-sandbox'

    setTimeout(() => {
      this.loadDonation()

      if(this.campaign.isJunoOk===true){
        this.href = this.router.url;
        let container = document.getElementById("polen-widget");
        let ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", `https://widget.polen.com.br/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
        ifrm.id = "ifrm-polen"
        // ifrm.setAttribute("src", `http://127.0.0.1:3000/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
        ifrm.style.width = "100%";
        ifrm.style.height = "100%";
        ifrm.style.maxWidth = "460px";
        ifrm.style.maxHeight = "660px";
        ifrm.style.border = "none";
        ifrm.style.background = "#FFFFFF";
        ifrm.setAttribute("allow", "clipboard-read; clipboard-write")
        // ifrm.contentWindow.postMessage({}, `https://polen-widget.vercel.app/directDonation/${this.href.replace("/", "")}`);

        container.append(ifrm);
      }

    }, 1500);

    console.log(this.campaign);
  }

  ngOnChanges(changes: SimpleChanges) {
    debugger
    if (this.selectedCause) {
      this.selectNgo(this.selectedCause, null);
    }

    if (changes.selectedCause.currentValue != null && !changes.selectedCause.firstChange) {
      const currentItem: SimpleChange = changes.selectedCause.currentValue;
      if (changes.selectedCause.previousValue != undefined)
        this.selectNgo(currentItem, "#signup-form");
      else
        this.selectNgo(currentItem, null);
    }
  }

  openThankyou() {
    // this.paymentMethod = 'boleto';
    // this.thankyouDonation(this, true, { 'metodo': 'boleto', 'bankSlipBarCode': '03399065964100000018604637101017482180000000680', 'bankSlipUrl': 'link' });
    this.paymentMethod = 'credit_card';
    this.thankyouDonation(this, true, { 'metodo': 'credit_card', "success": "true", "invoiceUrl": "https://sandbox.paypal.com/cgi-bin/webscr?cmd=_donations&amount=10.30&business=sb-m4egn1717350@business.example.com&charset=UTF-8&first_name=Fer&last_name=&item_name=Hmanity%20For%20Hope&currency_code=USD&custom=X8M590_2b799203-b8c4-46e0-9a22-ae704c439f72&notify_url=https://storeapi.opolen.com.br/api/finance/paypal/ipn&return=https://donate.polen.com.br/thankyou&cancel_return=https://donate.polen.com.br/cancel" });
  }

  loadDonation() {
    try {
      var obj = this
      //a mascara de telefone só eh aplicada para campanhas no brasil
      if (this.campaign.language == "br")
        this.maskPhone(obj)
      this.maskEmail(obj)
      this.showPaymentOptions()
      //set payment type
    } catch (e) {
    }
  }

  loadPresetDonations() {
    if (this.campaign.presetDonationList == null) {
      this.campaign.presetDonationList = []
      this.campaign.presetDonationList.push(new PresetDonation(5))
      this.campaign.presetDonationList.push(new PresetDonation(10))
      this.campaign.presetDonationList.push(new PresetDonation(20))
      this.campaign.presetDonationList.push(new PresetDonation(25))
    }
  }

  showPaymentOptions() {
    if (this.campaign.paymentList == null || this.campaign.paymentList.length == 0) {
      this.campaign.availablePaymentMethods = ["credit_card", "boleto"];
    }
    else {
      this.campaign.availablePaymentMethods = [];
      this.campaign.paymentList.forEach(p => {
        switch (p) {
          case 1:
            this.campaign.availablePaymentMethods.push("credit_card")
            break;
          case 2:
            this.campaign.availablePaymentMethods.push("boleto")
            break;
          case 5:
            this.campaign.availablePaymentMethods.push("paypal")
            break;
          default:
            break;
        }
      });
    }

    //mostra se essa campanha tem a opção de pagamento recorrente ou nao.
    if (this.campaign.hasRecurringPayment) {
      this.donationUser.monthly = false;
    }
  }

  IsAvailablePayment(payment) {
    return this.campaign.availablePaymentMethods.indexOf(payment) >= 0
  }

  selectPaymentRecurrence(recurrence, $element, id, container) {
    debugger
    this.donationUser.monthly = recurrence == 'monthly-pay';
    this.changeTab($element, id, ".payment-recurrence .tabs-panel", container);
  }

  selectNgo(ngo: any, id: string) {
    debugger
    if (this.donationUser == null)
      this.donationUser = new NewDonationDto();

    this.donationUser.causesList = [];
    this.donationUser.causesList.push(ngo.url);
    this.ngoSelected = ngo;
    this.detailNgoImageBackground = ngo.image;
    if (id != null)
      this.scrollToElement(id);

    let container = document.getElementById("ifrm-polen");
    if(container){
      // container.setAttribute("src", `http://127.0.0.1:3000/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
      container.setAttribute("src", `https://polen-widget.vercel.app/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
    }
  }

  selectPaymentMethod(method, $element, id, container) {
    this.paymentMethod = method;
    this.changeTab($element, id, ".payment-method-content .tabs-panel", container);
    this.getBankFee();
    setTimeout(() => {
      this.maskDocument(this)
    }, 500)
  }

  getBankFee() {
    if (this.paymentMethod == 'boleto') {
      this.suggestedBankFee = 1.70;
    }
    else {
      this.suggestedBankFee = this.donationUser.donation * 0.03;
    }
  }


  selectAmount(amount) {
    var obj = this;
    if (amount > 0) {
      this.donateAmount = amount
      this.editDonateAmountText = ""
      this.editDonateAmount = false
      this.donationUser.donation = amount
      this.getBankFee();

      if (this.campaign.campaignType == 3) {
        this.loadCouponBanner(amount);
      }
    }
    else {
      this.donateAmount = 0
      this.editDonateAmount = true
      setTimeout(() => {
        obj.maskDonation(obj);
      }, 500)
    }
    obj.scrollToElement('#how-to-help');
  }

  loadCouponBanner(amount) {
    this.setPossibleCouponBanner(amount);
    setTimeout(() => {
      jQuery(".couponBanner").show();
    }, 500)
  }

  //atravez do valor selecionado, definimos qual cupom dentre a lista de cupom existente a pessoa pode ganhar se doar.
  setPossibleCouponBanner(amount) {
    this.possibleGiftCoupon = null
    this.campaign.couponGiftList.forEach(c => {
      if (c.active) {

        //eh a primeira vez que vai popular o objeto
        if (this.possibleGiftCoupon == null && amount <= c.maxDonation) {
          this.possibleGiftCoupon = c;
        }

        if (this.possibleGiftCoupon != null) {
          //temos q garantir que além de menor q maxDonation o Amount nao eh
          if (amount <= c.maxDonation && amount > this.possibleGiftCoupon.maxDonation) {
            this.possibleGiftCoupon = c;
          }
        }
      }
    })

    //se nao encontrou nenhum mas existir algo ativo na lista de cupom, exibimos o primeiro
    if (this.possibleGiftCoupon == null && this.campaign.couponGiftList.filter(x => x.active).length > 0) {
      this.possibleGiftCoupon = this.campaign.couponGiftList[0];
    }

    return this.possibleGiftCoupon;
  }

  showCouponRules() {
    if (this.possibleGiftCoupon != null) {
      this.ngxSmartModalService.getModal('couponRulesModal').open()
    }

  }

  getRecaptchaToken(token) {
    $("#google-captcha").val(token);
  }

  //pega o token via API
  setCreditCardApiToken(obj) {
    var month = obj.creditCard.month_year;
    var year = obj.creditCard.month_year;
    try {
      month = obj.creditCard.month_year.split(' ')[0];
      year = obj.creditCard.month_year.split(' ')[2];

      if (year == undefined) {
        month = obj.creditCard.month_year.split('/')[0];
        year = obj.creditCard.month_year.split('/')[1];
        if (year != undefined && year.length < 4) {
          year = '20' + year;
        }
      }
    }
    catch (e) {
      console.log(e);
    }

    var firstName = obj.creditCard.holder_name;
    var lastName = '';
    try {
      firstName = obj.creditCard.holder_name.split(/ (.+)/)[0];
      lastName = obj.creditCard.holder_name.split(/ (.+)/)[1];
      if (lastName == undefined)
        lastName = '';
    }
    catch (e) {
      console.log(e);
    }

    var cc = Iugu.CreditCard(obj.creditCard.display_number,
      month, year, firstName, lastName, obj.creditCard.bin);

    var payment = new PaymentInfo()
    payment.extra_info = new CreditCardInfo();
    payment.extra_info.bin = obj.creditCard.bin
    payment.extra_info.first_name = firstName;
    payment.extra_info.last_name = lastName;
    payment.extra_info.display_number = obj.creditCard.display_number
    payment.extra_info.month = month
    payment.extra_info.year = year

    this.financeService.createPaymentTokenIugu(payment.extra_info, obj.campaign.seoUrl, obj.campaign.apiToken, obj.iuguTest).subscribe(response => {
      debugger
      obj.errorTokenUnknown = false;
      if (response.success) {
        obj.error2DisplayNumber = '';
        obj.error2CVV = '';
        obj.error2HolderName = '';
        obj.error2MonthYear = '';

        obj.createPayment(response.data, obj)
      }
      else {
        if (response.data != undefined) {
          var data = response.data.errors;
          if (data.errors) {
            //console.log(data);
            var hasError = false;
            if (data.errors.number != null) {
              hasError = true;
              obj.error2DisplayNumber = "número do cartão de crédito inválido"
            }
            if (data.errors.verification_value != null) {
              hasError = true;
              obj.error2CVV = "código verificador inválido"
            }
            if (data.errors.last_name != null) {
              hasError = true;
              obj.error2HolderName = "o nome adicionado é inválido"
            }
            if (data.errors.first_name != null) {
              hasError = true;
              obj.error2HolderName = "o nome adicionado é inválido"
            }
            if (data.errors.expiration != null) {
              hasError = true;
              obj.error2MonthYear = "data de validade inválida"
            }

            if (!hasError) {
              obj.errorTokenUnknown = true;
            }
          }
          obj.setLoading(false);
        }
        //algum erro desconhecido
        else if (response.error != undefined) {
          console.log(response);
        }
      }
    });
  }

  //pega o token via Javascript
  setCreditCard(value, valid) {
    var a = this.donationUser;
    var b = this.creditCard;
    var obj = this
    obj.setLoading(true);

    var tokenResponseHandler = function (data) {
      debugger
      if (data.errors) {
        //console.log(data);
        if (data.errors.number != null) {
          obj.error2DisplayNumber = "número do cartão de crédito inválido"
        }
        if (data.errors.verification_value != null) {
          obj.error2CVV = "código verificador inválido"
        }
        if (data.errors.last_name != null) {
          obj.error2HolderName = "o nome adicionado é inválido"
        }
        if (data.errors.first_name != null) {
          obj.error2HolderName = "o nome adicionado é inválido"
        }
        if (data.errors.expiration != null) {
          obj.error2MonthYear = "data de validade inválida"
        }

        obj.setLoading(false);
      } else {
        obj.error2DisplayNumber = '';
        obj.error2CVV = '';
        obj.error2HolderName = '';
        obj.error2MonthYear = '';

        obj.createPayment(data, obj)
      }
    }

    try {
      var cc = obj.getCreditCardObject(obj);
      Iugu.setAccountID("3FEED9651C78463A9A5558075870D87C");
      //Iugu.setTestMode(true);
      Iugu.setup();

      //var cc = Iugu.CreditCard("5136 5145 5735 3196", "10", "2021", "Fernando", "Teste", "123")
      //var cc = Iugu.CreditCard("5136 5145 5735 3191", "10", "2018", "Fernando", "Teste", "123123")
      Iugu.createPaymentToken(cc, tokenResponseHandler);
    }
    catch (e) {
      console.log(e);
    }

    return false;
  }

  getCreditCardObject(obj) {
    var month = obj.creditCard.month_year;
    var year = obj.creditCard.month_year;
    try {
      month = obj.creditCard.month_year.split(' ')[0];
      year = obj.creditCard.month_year.split(' ')[2];
    }
    catch (e) {
      console.log(e);
    }

    var firstName = obj.creditCard.holder_name;
    var lastName = '';
    try {
      firstName = obj.creditCard.holder_name.split(/ (.+)/)[0];
      lastName = obj.creditCard.holder_name.split(/ (.+)/)[1];
      if (lastName == undefined)
        lastName = '';
    }
    catch (e) {
      console.log(e);
    }

    var cc = Iugu.CreditCard(obj.creditCard.display_number,
      month, year, firstName, lastName, obj.creditCard.bin);

    return cc;
  }

  createPayment(data, obj) {
    try {
      debugger
      obj.setLoading(true);
      //obj.trackGa('Confirmar', '');
      //if (polenStorage.getPolenContainer().selectedNgo != null) {

      if (obj.donationUser.causesList == null) {
        this.donationUser.causesList = [];
        this.donationUser.causesList.push(obj.selectedCause.url)
      }

      var newDonationDto = {
        storeId: obj.seoUrl,
        donation: obj.donationUser.donation,
        userId: obj.donationUser.phone.replace(/\D/g, ''),
        userEmail: obj.donationUser.email,
        userName: obj.donationUser.name,
        userPhone: obj.donationUser.phone,
        userDocument: obj.donationUser.identifier,
        ngoId: obj.donationUser.causesList[0],
        verified: false,
        optIn: true
      };

      var payment = new PaymentInfo();
      payment.storeName = newDonationDto.storeId;

      if (obj.paymentMethod == 'boleto' || obj.paymentMethod == 'paypal') {
        payment.method = obj.paymentMethod;
      }
      else {
        payment.test = data.test;
        payment.method = data.method;
        payment.extra_info = new CreditCardInfo();
        if (data.extra_info != undefined) {
          payment.extra_info.bin = data.extra_info.bin
          payment.extra_info.brand = data.extra_info.brand
          payment.extra_info.holder_name = data.extra_info.holder_name
          payment.extra_info.display_number = data.extra_info.display_number
          payment.extra_info.month = data.extra_info.month
          payment.extra_info.year = data.extra_info.year
        }
        payment.extra_info.token = data.id
        payment.id = data.id
      }

      let captchaToken = $("#google-captcha").val();
      var userDonation = {
        userDonation: newDonationDto,
        payment,
        userPayForBankFee: this.donationUser.userPayForBankFee,
        userBankFee: this.donationUser.userBankFee,
        RecurringPayment: this.donationUser.monthly,
        campaignId: obj.campaign.id,
        recaptchaToken: captchaToken,
        couponId: null
      };

      if (this.possibleGiftCoupon != null) {
        userDonation.couponId = this.possibleGiftCoupon.id
      }

      //token de acesso à campanha
      var token = obj.campaign.apiToken;
      this.financeService.createDirectInvoice(userDonation, token, obj.iuguTest).subscribe(data => {
        console.log('obrigado');
        obj.setLoading(false);
        if (data.success) {
          obj.thankyouDonation(obj, true, data.data);
        } else {
          if (data.isDuplicated) {
            obj.alertDuplicated();
          }
          else {
            obj.thankyouDonation(obj, false, null);
          }
        }
      })

    } catch (e) {
      console.log('createPayment error' + JSON.stringify(e));
    }
  }

  alertDuplicated() {
    Swal.fire({
      title: "Doação duplicada",
      text: "Vimos que você já fez uma doação com o mesmo valor para a " + this.ngoSelected.name + ". Que tal apoiar outra causa? Compartilhe o amor! 😍",
      icon: 'warning'
    }).then((result) => {

    })
  }

  saveForm(valid, form: NgForm) {
    var obj = this
    if (form != undefined)
      console.log(form.form.valid)

    if (this.registerFormStep1) {
      //a validacao de telefone só eh aplicada para campanhas no brasil
      if (!this.is_Phone(this.donationUser.phone)) {
        this.errorPhone = true;
        if (this.donationUser.email.indexOf('@') <= 0) {
          this.errorEmail = true;
        }
        else {
          this.errorEmail = false;
        }
      }
      else {
        this.errorPhone = false;
        if (this.donationUser.email.indexOf('@') <= 0) {
          this.errorEmail = true;
          return;
        }
        this.errorEmail = false;

        if (this.donationUser.donation <= 0) {
          this.errorDonation = true;
          return;
        }

        if (this.donationUser.donation < this.campaign.minDonationValue) {
          return;
        }
        this.errorDonation = false;

        this.getBankFee();

        //seleciona a primeira aba disponivel para pagamento
        if (this.campaign.availablePaymentMethods != null) {
          var lonelyPay = this.campaign.availablePaymentMethods[0];
          this.selectPaymentMethod(lonelyPay, null, '#' + lonelyPay + '-panel', null)
          $(".payment-method .tabs-title").removeClass('is-active')
          $(".tab-" + lonelyPay + "-panel").addClass('is-active');
        }

        this.registerFormStep1 = false;
        this.registerFormStep2 = true;
        this.gaService.trackEvent("startDonation");

        var obj = this;
        setTimeout(() => {
          if ($('#RecaptchaCreditCardField').length) {
            grecaptcha.ready(function () {
              console.log('recaptcha ready')
              grecaptcha.render("RecaptchaCreditCardField", {
                "sitekey": "6LcUQf0UAAAAAA6BH2NUuZSnkhCVD8p79XSQ8pVZ",
                "size": "invisible",
                "callback": obj.getRecaptchaToken
              });
            });
          }


          jQuery("#creditCardForm").submit(function (e) {
            debugger
            e.preventDefault();

            obj.gaService.trackEvent("payDonation");
            if (obj.paymentMethod == 'boleto')
              return;

            if (obj.campaign.language == 'br') {
              if (obj.donationUser.identifier == null || !obj.is_CpfCnpj(obj.donationUser.identifier, obj)) {
                obj.errorIdentifier = true;
                return;
              }
              obj.errorIdentifier = false;
            }

            if (jQuery("#useterms:checked").val() != "on") {
              obj.errorUseTerms = true
              return;
            }
            obj.errorUseTerms = false

            if (jQuery("#paybanktaxes:checked").val() == "on") {
              obj.donationUser.userPayForBankFee = true
              obj.donationUser.userBankFee = obj.suggestedBankFee;
            }
            else {
              obj.donationUser.userPayForBankFee = false
            }

            obj.setLoading(true);
            var okDisplayNumber = Iugu.utils.validateCreditCardNumber(obj.creditCard.display_number);
            var brand = Iugu.utils.getBrandByCreditCardNumber(obj.creditCard.display_number);
            var okMonthYear = Iugu.utils.validateExpirationString(obj.creditCard.month_year);
            var okCVV = Iugu.utils.validateCVV(obj.creditCard.bin, brand);

            if (okDisplayNumber && okMonthYear && okCVV) {
              grecaptcha.execute();
              console.log('cartao de credito valido');
              obj.errorDisplayNumber = false;
              obj.errorMonthYear = false;
              obj.errorCVV = false;
              //obj.setCreditCard(form, valid);
              obj.setCreditCardApiToken(obj);
            }
            else {
              obj.setLoading(false);
              obj.errorDisplayNumber = false;
              obj.errorMonthYear = false;
              obj.errorCVV = false;

              if (!okDisplayNumber)
                obj.errorDisplayNumber = true;

              if (!okMonthYear)
                obj.errorMonthYear = true;

              if (!okCVV)
                obj.errorCVV = true;

              console.log('cartao de credito invalido');
            }
          });

          obj.maskDocument(obj)

        }, 500)
      }
    }
    else {
      //aqui significa que é pagamento com boleto ou paypal

      if (obj.paymentMethod == 'boleto' || obj.paymentMethod == 'paypal') {
        obj.gaService.trackEvent("payDonation");

        //apenas no brasil a validacao de documento esta habilitada
        if (obj.campaign.language == "br") {
          if (obj.donationUser.identifier == null || !obj.is_CpfCnpj(obj.donationUser.identifier, obj)) {
            obj.errorIdentifier = true;
            return;
          }
          obj.errorIdentifier = false;
        }

        if (jQuery("#useterms:checked").val() != "on") {
          obj.errorUseTerms = true
          return;
        }
        obj.errorUseTerms = false

        if (jQuery("#paybanktaxes:checked").val() == "on") {
          obj.donationUser.userPayForBankFee = true
          obj.donationUser.userBankFee = obj.suggestedBankFee;
        }
        else {
          obj.donationUser.userPayForBankFee = false
        }

        obj.createPayment(null, obj)
      }
    }
  }

  backToStep1() {
    this.registerFormStep1 = true;
    this.registerFormStep2 = false;
  }

  maskPhone(obj) {
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
      spOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
      };
    jQuery("input.phone").mask(SPMaskBehavior, spOptions);

    jQuery("input.phone").focusout(function () {
      if (jQuery("input.phone").val().length < 15) {
        obj.errorPhone = true;
      }
      else {
        obj.errorPhone = false;
      }
    })
  }

  maskEmail(obj) {
    jQuery("#email").mask("A", {
      translation: {
        "A": { pattern: /[\w@\-.+]/, recursive: true }
      }
    });
    jQuery("#email").focusout(function () {
      if (!obj.is_email(obj.donationUser.email)) {
        obj.errorEmail = true;
      }
      else {
        obj.errorEmail = false;
      }
    })
  }



  maskDocument(obj) {
    //soh cria a mascara de cnpj/cpf se foi uma campanha brasileira
    if (obj.campaign.language == 'br') {
      var CpfCnpjMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
      },
        cpfCnpjpOptions = {
          onKeyPress: function (val, e, field, options) {
            field.mask(CpfCnpjMaskBehavior.apply({}, arguments), options);
          }
        };

      jQuery(".document").mask(CpfCnpjMaskBehavior, cpfCnpjpOptions);
    }
  }

  maskDonation(obj) {
    obj.donationUser.donation = null;
    jQuery("#editAmount")
      //.mask("R$ 999.999,99")"#.##0,00", {reverse: true}
      .mask("#.##0,00", { reverse: true })
      .focusout(function (event) {
        var target, phone, element;
        target = (event.currentTarget) ? event.currentTarget : event.srcElement;
        var cleanPoint = target.value.replace(/\./g, '')
        var changeComa = obj.replaceAll(cleanPoint, ',', '.')
        obj.donationUser.donation = changeComa * 1
        obj.loadCouponBanner(obj.donationUser.donation)
      });
    jQuery("#editAmount").focus();
  }

  changeTab($element, id, elementsToHide, container) {
    setTimeout(() => {
      if (container != null)
        jQuery(container + ' .tabs-title').removeClass('is-active')

      if ($element != null)
        jQuery($element).parent().addClass("is-active")

      if (elementsToHide != null)
        $(elementsToHide).hide();

      if (id != null)
        $(id).show();

    }, 100)
  }

  scrollToElement(id): void {
    setTimeout(() => {
      var offset = $(id).offset();
      $('html, body').animate({
        scrollTop: offset.top - 100,
        scrollLeft: offset.left
      });
      // var element = document.getElementById(id);
      // element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 100)
  }


  is_email(email) {
    var er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}/;
    if (!er.exec(email)) {
      return false;
    }
    return true
  }

  is_CpfCnpj(doc, obj) {
    if (obj.is_CPF(doc))
      return true
    if (obj.is_CNPJ(doc))
      return true
    return false
  }

  is_Phone(doc) {
    if (this.campaign.language == 'br') {
      doc = doc.replace(/\D/g, '');
      var er = /^\(?\d{2}\)?[\s-]?[\s9]?\d{4}-?\d{4}$/;
      if (!er.exec(doc)) {
        return false;
      }
      return true
    }
    else {
      //quando ñ eh campanha brasileira, o telefone nao eh obrigatorio
      return true
    }
  }

  is_CPF(strCPF) {
    strCPF = strCPF.replace(/\D/g, '');
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  is_CNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;

  }

  replaceAll(str, find, replace) {
    if (str.indexOf(find) >= 0)
      return str.replace(new RegExp(find, 'g'), replace);
    return str;
  }

  toggleClass(idclass, classe) {
    setTimeout(() => {
      jQuery(idclass).toggleClass(classe);
    }, 100)
  }


  thankyouDonation(obj, paid, data) {
    debugger
    obj.gaService.trackEvent("concludedDonation", obj.selectedCause.url, obj.donationUser.donation);
    //var nameCause = obj.ngoSelected.name;
    // jQuery(".whats-desktop").attr("href", "https://web.whatsapp.com/send?text=Além do melhor preço, agora  a 99 vai doar 5% do valor das corridas para a causa que você escolher! Já me cadastrei e escolhi *" + nameCause + "*. Qual é a sua causa? Ajude agora você também! https%3A//james.com?utm_source=whats");
    // jQuery(".whats-mobile").attr("href", "whatsapp://send?text=Além do melhor preço, agora  a 99 vai doar 5% do valor das corridas para a causa que você escolher! Já me cadastrei e escolhi *" + nameCause + "*. Qual é a sua causa? Ajude agora você também! https%3A//james.com?utm_source=whats");
    this.twitterShareText = encodeURI(`${this.sharedText}`);
    //jQuery(".twitter").attr("href", "https://twitter.com/intent/tweet?text=" + twitterText + "&url=https%3A//james.com?utm_source=twitter&original_referer=https://james.com");

    jQuery("#confirmation-popup .filled-heart").css('opacity', '1')

    obj.bankSlipUrl = null;
    obj.bankSlipBarCode = null;
    obj.invoiceUrl = null;


    obj.setLoading(false);
    if (paid) {
      if (obj.paymentMethod == 'boleto' && data.metodo == 'boleto') {
        if (data.error != undefined) {
          console.log(data.error);
          console.log('boleto não foi criado!');
          obj.creditCard = new CreditCardInfo();
          this.ngxSmartModalService.getModal('notpaidModal').open()
          return;
        }

        obj.bankSlipUrl = data.bankSlipUrl;
        obj.bankSlipBarCode = data.bankSlipBarCode;

        setTimeout(() => {
          JsBarcode("#barcode", obj.bankSlipBarCode, {
            height: 75,
            displayValue: false,
            marginTop: 0
          });
        }, 1000)
      }
      else if (data.metodo == 'paypal') {
        obj.invoiceUrl = data.invoiceUrl;
        window.open(obj.invoiceUrl, '_blank');
      }
      else if (obj.paymentMethod == 'credit_card') {
        if (this.possibleGiftCoupon != null) {
          this.campaignService.getCouponCodeById(this.campaign.id, this.possibleGiftCoupon.id).subscribe(res => {
            if (res.success)
              obj.possibleGiftCoupon.coupon = res.data;
            else {
              obj.possibleGiftCoupon.coupon = '---';
              obj.possibleGiftCoupon.checkEmail = true
            }

          })
        }
      }

      console.log('cadastro realizado com sucesso!');
      //this.trackGa('Pagamento Confirmado', '');
      this.ngxSmartModalService.getModal('confirmationModal').open()
      setTimeout(() => {
        jQuery(".couponBanner").show()
      }, 1000)

    }
    else {
      this.gaService.trackEvent("failDonation");
      console.log('pagamento falhou!');
      //this.trackGa('Pagamento ñ realizado', '');
      obj.creditCard = new CreditCardInfo();
      this.ngxSmartModalService.getModal('notpaidModal').open()
    }
  }


  showUseTerms() {
    setTimeout(() => {
      new google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'useterms-container'
      );

    }, 500);
    this.ngxSmartModalService.getModal('usetermsModal').open()
  }
}
