import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { NewDonationDto, CreditCardInfo, PaymentInfo } from 'src/app/models/payment.model';
import { FinanceService } from 'src/app/services/finance.service';
import { CampaignCouponModel, CampaignModel, PresetDonation } from 'src/app/models/campaign.model';
import { NgForm } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import Swal from 'sweetalert2'
import { GoogleAnalyticsService } from 'src/app/services/googleanalytics.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { JunoService } from 'src/app/services/juno.service';
import { HelperService } from 'src/app/services/helper.service';
import {Router} from "@angular/router";

//declare var swal: any;
declare var JsBarcode: any;
declare var jQuery: any;
declare var $: any;
declare var ga: any;
declare var polenStorage: any;
declare var polenHelper: any;
declare var Iugu: any;
declare var grecaptcha: any;
declare var google: any;


@Component({
  selector: 'juno-direct-donation',
  templateUrl: './juno-direct-donation.component.html',
  styleUrls: ['./juno-direct-donation.component.css']
})
export class JunoDirectDonationComponent implements OnInit {

  errorCreditCard: string
  masks: any
  signupform: any
  loading: boolean
  donationUser: NewDonationDto
  creditCard: CreditCardInfo
  paymentMethod: string = 'credito';
  donateAmount: number = 0
  editDonateAmount: boolean = false
  editDonateAmountText: string
  registerFormStep1: boolean = true
  registerFormStep2: boolean = false
  errorDisplayNumber: boolean = false;
  errorMonthYear: boolean = false;
  errorCVV: boolean = false;
  errorPhone: boolean = false;
  errorEmail: boolean = false;
  errorDonation: boolean = false;
  errorIdentifier: boolean = false;
  error2MonthYear: string = '';
  error2HolderName: string = '';
  error2CVV: string = '';
  error2DisplayNumber: string = '';
  errorUseTerms: boolean;
  ngoList: any;
  ngoSelected: any;
  isTest: boolean = false
  detailNgoImageMobile: string
  detailNgoImage: string
  detailNgoImageBackground: string;
  suggestedBankFee: number;
  bankSlipBarCode: string = null
  invoiceUrl: string = null
  bankSlipUrl: string = null
  errorPayment: string = null;
  twitterShareText: string
  obj: any = this;
  possibleGiftCoupon: CampaignCouponModel = null

  @Input() selectedCause: any;
  @Input() campaign: CampaignModel;
  @Input() seoUrl: string;
  @Input() selectedCauses: string[] = new Array<string>();
  @Output() answer = new EventEmitter();
  // @Input() onboard: OnBoardingModel
  public isAdmin: boolean
  hasChanges: boolean = false
  successChanged: boolean = false
  error: string;
  public href: string = "";
  statementId: any; //caso já existe um statementId, significa que já esta atrelado à um userStatement

  constructor(private router: Router, private financeService: FinanceService, private campaignService: CampaignService, private junoService: JunoService, private helper: HelperService, public gaService: GoogleAnalyticsService, public ngxSmartModalService: NgxSmartModalService) { }

  setLoading(status) {
    this.answer.emit({ "loading": status });
  }

  ngOnInit() {
    this.donationUser = new NewDonationDto();
    this.creditCard = new CreditCardInfo();
    this.loadPresetDonations()
    // let value = 5.5
    // this.gaService.trackEvent("concludedDonation", "cufa", value);
    console.log(this.campaign);
    this.campaign.hasRecurringPayment = this.campaign.recurringPayment || this.campaign.seoUrl == 'polen-sandbox' || this.campaign.seoUrl == 'phonetrack';
    //TODOJUNO TESTE
    this.isTest = this.campaign.seoUrl == 'polen-sandbox'
    //this.statementId = "5e12ec2b-5e72-4e70-a5c5-08a620e01dce"
    if(this.campaign.supportedNgos.length === 1){
      this.ngoSelected = this.campaign.supportedNgos[0];
    }
    setTimeout(() => {
      this.loadDonation()

      if(this.campaign.isJunoOk===true){
        this.href = this.router.url;
        let container = document.getElementById("polen-widget");
        let ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", `https://widget.polen.com.br/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
        ifrm.id = "ifrm-polen"
        // ifrm.setAttribute("src", `http://127.0.0.1:3000/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
        ifrm.style.width = "100%";
        ifrm.style.height = "100%";
        ifrm.style.maxWidth = "460px";
        ifrm.style.maxHeight = "660px";
        ifrm.style.border = "none";
        ifrm.style.background = "#FFFFFF";
        ifrm.setAttribute("allow", "clipboard-read; clipboard-write")
        // ifrm.contentWindow.postMessage({}, `https://polen-widget.vercel.app/directDonation/${this.href.replace("/", "")}`);

        container.append(ifrm);
      }

    }, 1500);
  }

  ngOnChanges(changes: SimpleChanges) {
    debugger
    if (this.selectedCause) {
      this.selectNgo(this.selectedCause, null);
    }

    if (changes.selectedCause.currentValue != null && !changes.selectedCause.firstChange) {
      const currentItem: SimpleChange = changes.selectedCause.currentValue;
      if (changes.selectedCause.previousValue != undefined)
        this.selectNgo(currentItem, "#signup-form");
      else
        this.selectNgo(currentItem, null);
    }
  }

  openThankyou() {
    // this.paymentMethod = 'boleto';
    // this.thankyouDonation(this, true, { 'metodo': 'boleto', 'bankSlipBarCode': '03399065964100000018604637101017482180000000680', 'bankSlipUrl': 'link' });
    this.paymentMethod = 'credit_card';
    this.thankyouDonation(this, true, { 'metodo': 'credit_card', "success": "true", "invoiceUrl": "https://sandbox.paypal.com/cgi-bin/webscr?cmd=_donations&amount=10.30&business=sb-m4egn1717350@business.example.com&charset=UTF-8&first_name=Fer&last_name=&item_name=Hmanity%20For%20Hope&currency_code=USD&custom=X8M590_2b799203-b8c4-46e0-9a22-ae704c439f72&notify_url=https://storeapi.opolen.com.br/api/finance/paypal/ipn&return=https://donate.polen.com.br/thankyou&cancel_return=https://donate.polen.com.br/cancel" });
  }

  loadDonation() {
    try {
      var obj = this
      //a mascara de telefone só eh aplicada para campanhas no brasil
      if (this.campaign.language == "br")
        this.maskPhone(obj)
      this.maskEmail(obj)
      this.showPaymentOptions()
      //set payment type
    } catch (e) {
    }
  }

  loadPresetDonations() {
    if (this.campaign.presetDonationList == null) {
      this.campaign.presetDonationList = []
      this.campaign.presetDonationList.push(new PresetDonation(5))
      this.campaign.presetDonationList.push(new PresetDonation(10))
      this.campaign.presetDonationList.push(new PresetDonation(20))
      this.campaign.presetDonationList.push(new PresetDonation(25))
    }
  }

  showPaymentOptions() {
    if (this.campaign.paymentList == null || this.campaign.paymentList.length == 0) {
      this.campaign.availablePaymentMethods = ["credit_card", "boleto"];
    }
    else {
      this.campaign.availablePaymentMethods = [];
      this.campaign.paymentList.forEach(p => {
        switch (p) {
          case 1:
            this.campaign.availablePaymentMethods.push("credit_card")
            break;
          case 2:
            this.campaign.availablePaymentMethods.push("boleto")
            break;
          case 5:
            this.campaign.availablePaymentMethods.push("paypal")
            break;
          default:
            break;
        }
      });
    }

    //mostra se essa campanha tem a opção de pagamento recorrente ou nao.
    if (this.campaign.hasRecurringPayment) {
      this.donationUser.monthly = false;
    }
  }

  IsAvailablePayment(payment) {
    return this.campaign.availablePaymentMethods.indexOf(payment) >= 0
  }

  selectPaymentRecurrence(recurrence, $element, id, container) {
    debugger
    this.donationUser.monthly = recurrence == 'monthly-pay';
    this.changeTab($element, id, ".payment-recurrence .tabs-panel", container);
  }

  selectNgo(ngo: any, id: string) {
    debugger
    if (this.donationUser == null)
      this.donationUser = new NewDonationDto();

    this.donationUser.causesList = [];
    this.donationUser.causesList.push(ngo.url);
    this.ngoSelected = ngo;
    this.detailNgoImageBackground = ngo.image;
    if (id != null)
      this.scrollToElement(id);

    let container = document.getElementById("ifrm-polen");
    if(container){
      // container.setAttribute("src", `http://127.0.0.1:3000/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
      container.setAttribute("src", `https://polen-widget.vercel.app/directDonation/${this.href.replace("/", "")}?selectedNgo=${this.ngoSelected.url}`);
    }


  }

  selectPaymentMethod(method, $element, id, container) {
    this.paymentMethod = method;
    this.changeTab($element, id, ".payment-method-content .tabs-panel", container);
    this.getBankFee();
    setTimeout(() => {
      this.maskDocument(this)
    }, 500)
  }

  getBankFee() {
    if (this.paymentMethod == 'boleto') {
      this.suggestedBankFee = 1.70;
    }
    else {
      this.suggestedBankFee = this.donationUser.donation * 0.03;
    }
  }


  selectAmount(amount) {
    var obj = this;
    if (amount > 0) {
      this.donateAmount = amount
      this.editDonateAmountText = ""
      this.editDonateAmount = false
      this.donationUser.donation = amount
      this.getBankFee();

      if (this.campaign.campaignType == 3) {
        this.loadCouponBanner(amount);
      }
    }
    else {
      this.donateAmount = 0
      this.editDonateAmount = true
      setTimeout(() => {
        obj.maskDonation(obj);
      }, 500)
    }
    obj.scrollToElement('#how-to-help');
  }

  loadCouponBanner(amount) {
    this.setPossibleCouponBanner(amount);
    setTimeout(() => {
      jQuery(".couponBanner").show();
    }, 500)
  }

  //atravez do valor selecionado, definimos qual cupom dentre a lista de cupom existente a pessoa pode ganhar se doar.
  setPossibleCouponBanner(amount) {
    this.possibleGiftCoupon = null
    this.campaign.couponGiftList.forEach(c => {
      if (c.active) {

        //eh a primeira vez que vai popular o objeto
        if (this.possibleGiftCoupon == null && amount <= c.maxDonation) {
          this.possibleGiftCoupon = c;
        }

        if (this.possibleGiftCoupon != null) {
          //temos q garantir que além de menor q maxDonation o Amount nao eh
          if (amount <= c.maxDonation && amount > this.possibleGiftCoupon.maxDonation) {
            this.possibleGiftCoupon = c;
          }
        }
      }
    })

    //se nao encontrou nenhum mas existir algo ativo na lista de cupom, exibimos o primeiro
    if (this.possibleGiftCoupon == null && this.campaign.couponGiftList.filter(x => x.active).length > 0) {
      this.possibleGiftCoupon = this.campaign.couponGiftList[0];
    }

    return this.possibleGiftCoupon;
  }

  showCouponRules() {
    if (this.possibleGiftCoupon != null) {
      this.ngxSmartModalService.getModal('couponRulesModal').open()
    }

  }

  getRecaptchaToken(token) {
    $("#google-captcha").val(token);
  }

  alertDuplicated() {
    this.setLoading(false)
    Swal.fire({
      title: "Doação duplicada",
      text: "Vimos que você já fez uma doação com o mesmo valor para a " + this.ngoSelected.name + ". Que tal apoiar outra causa? Compartilhe o amor! 😍",
      icon: 'warning'
    }).then((result) => {

    })
  }

  saveInfoStep(valid, form: NgForm) {
    debugger
    var obj = this
    if (this.registerFormStep1) {
      //1º validação do formulário
      if (form != undefined && !form.form.valid) {
        console.log(form)
        return;
      }

      //2º a validacao de telefone só eh aplicada para campanhas no brasil
      if (!this.is_Phone(this.donationUser.phone)) {
        this.errorPhone = true;
        if (this.donationUser.email.indexOf('@') <= 0) {
          this.errorEmail = true;
        }
        else {
          this.errorEmail = false;
        }
      }
      else {
        this.errorPhone = false;
        this.errorEmail = false;
        this.errorDonation = false;
        if (this.donationUser.email.indexOf('@') <= 0) {
          this.errorEmail = true;
          return;
        }

        if (this.donationUser.donation <= 0) {
          this.errorDonation = true;
          return;
        }

        if (this.donationUser.donation < this.campaign.minDonationValue) {
          return;
        }

        //Validação concluída, pode avançar para próximo step
        this.InitializePaymentStep()
      }
    }
  }

  InitializePaymentStep() {
    debugger
    var obj = this;

    this.getBankFee();

    //seleciona a primeira aba disponivel para pagamento
    if (this.campaign.availablePaymentMethods != null) {
      var lonelyPay = this.campaign.availablePaymentMethods[0];
      this.selectPaymentMethod(lonelyPay, null, '#' + lonelyPay + '-panel', null)
      $(".payment-method .tabs-title").removeClass('is-active')
      $(".tab-" + lonelyPay + "-panel").addClass('is-active');
    }

    this.registerFormStep1 = false;
    this.registerFormStep2 = true;
    this.gaService.trackEvent("startDonation");

    setTimeout(() => {
      //instancia o Google Captcha
      debugger
      if ($('#RecaptchaCreditCardField').length) {
        grecaptcha.ready(function () {
          console.log('recaptcha ready')
          grecaptcha.render("RecaptchaCreditCardField", {
            "sitekey": "6LcUQf0UAAAAAA6BH2NUuZSnkhCVD8p79XSQ8pVZ",
            "size": "invisible",
            "callback": obj.getRecaptchaToken
          });
        });
      }

    }, 500)
  }

  async proccessPayment(f, model: CreditCardInfo, isValid) {
    debugger
    var obj = this
    if (this.registerFormStep2) {
      //if (obj.paymentMethod == 'boleto' || obj.paymentMethod == 'paypal') {
      obj.gaService.trackEvent("payDonation");

      obj.errorUseTerms = false
      obj.errorIdentifier = false;

      //apenas no brasil a validacao de documento esta habilitada
      if (obj.campaign.language == "br") {
        if (obj.donationUser.identifier == null || !obj.is_CpfCnpj(obj.donationUser.identifier, obj)) {
          obj.errorIdentifier = true;
          return;
        }
      }

      if (jQuery("#useterms:checked").val() != "on") {
        obj.errorUseTerms = true
        return;
      }

      if (jQuery("#paybanktaxes:checked").val() == "on") {
        obj.donationUser.userPayForBankFee = true
        obj.donationUser.userBankFee = obj.suggestedBankFee;
      }
      else {
        obj.donationUser.userPayForBankFee = false
      }

      //caso seja cartao de credito, pré-validamos o cartao antes
      if (obj.paymentMethod == 'creditcard') {
        var valid = await this.validateCreditPayment(f, model, isValid)
        if (!valid)
          return;
      }

      await obj.createUserStatement(null, obj)
      //}
    }
  }

  async createUserStatement(data, obj) {
    try {
      debugger
      obj.setLoading(true);
      //obj.trackGa('Confirmar', '');
      //if (polenStorage.getPolenContainer().selectedNgo != null) {

      if (obj.donationUser.causesList == null) {
        this.donationUser.causesList = [];
        this.donationUser.causesList.push(obj.selectedCause.url)
      }

      var newDonationDto = {
        storeId: obj.seoUrl,
        donation: obj.donationUser.donation,
        userId: obj.donationUser.phone.replace(/\D/g, ''),
        userEmail: obj.donationUser.email,
        userName: obj.donationUser.name,
        userPhone: obj.donationUser.phone,
        userDocument: obj.donationUser.identifier,
        ngoId: obj.donationUser.causesList[0],
        verified: false,
        optIn: true
      };

      var payment = new PaymentInfo();
      payment.storeName = newDonationDto.storeId;
      payment.method = obj.paymentMethod;
      payment.test = this.isTest;

      //let captchaToken = $("#google-captcha").val();
      var userDonation = {
        userDonation: newDonationDto,
        payment,
        userPayForBankFee: this.donationUser.userPayForBankFee,
        userBankFee: this.donationUser.userBankFee,
        RecurringPayment: this.donationUser.monthly,
        campaignId: obj.campaign.id,
        //recaptchaToken: captchaToken,
        couponId: null,
        paymentType: this.helper.getPaymentType(obj.paymentMethod)
      };

      if (this.possibleGiftCoupon != null) {
        userDonation.couponId = this.possibleGiftCoupon.id
      }

      //token de acesso à campanha
      var token = obj.campaign.apiToken;
      var result = null;

      if (this.statementId != null)
        result = { success: true, statementId: this.statementId };
      else
        result = await this.financeService.createUserStatement(userDonation, token, obj.isTest);

      if (result.success) {
        debugger
        this.junoService.storeSeoUrl = newDonationDto.storeId
        this.junoService.storeId = this.campaign.storeId
        this.statementId = result.statementId;
        var chargeRes = await this.proccessCharge(result.statementId, payment, this.creditCard)

        this.maskDocument(this)
        this.setLoading(false);
        if (chargeRes.success) {
          obj.thankyouDonation(obj, true, chargeRes.data);
        } else {
          obj.thankyouDonation(obj, false, null);
        }
      }
      else {
        if (result.isDuplicated) {
          obj.alertDuplicated();
        }
        else {
          this.showErrorPayment(null)
        }
      }

    } catch (e) {
      debugger
      this.showErrorPayment(e, true)
      console.log('createUserStatement error: ' + JSON.stringify(e));
    }
  }

  async validateCreditPayment(f, model: CreditCardInfo, isValid) {
    debugger
    //1. primeira validacao de formulario
    var valid = this.firstValidateCreditCard(f, model, isValid);
    if (!valid)
      return false;

    //2. montamos o objeto cartão para a Juno
    var cardData = this.junoService.getCardData(model);

    //3. fazemos a validacao da Juno
    this.junoService.loadJunoCheckout();
    var junoValid = await this.junoService.junoValidateCreditCard(cardData);
    if (!junoValid.valid)
      return false;
  }

  async proccessCharge(statementId, payment: PaymentInfo, model: CreditCardInfo): Promise<any> {
    debugger
    var cardData = null
    let captchaToken = "token"

    if (payment.method == 'credit_card') {
      cardData = this.junoService.getCardData(model);
    }

    //gera codigo captcha
    // setTimeout(async () => {
    //   debugger
    //   grecaptcha.execute();
    //
    //   //pega o captcha
    //   let captchaToken = $("#google-captcha").val();
    //
    //   //4. Realiza a (cobrança), (tokenizamos apenas) ou (tokenizamos e cobramos)
    //   //apos ter o hash do cartao, temos 4 possibilidades
    //
    // }, 100)

    if (this.creditCard.tokenize) {
      return await this.junoService.tokenize(statementId, cardData, captchaToken)
    }
    else {
      const result = await this.junoService.charge(statementId, cardData, null, captchaToken)
      console.log(result);
      return result;
    }
  }

  firstValidateCreditCard(f, model: CreditCardInfo, isValid) {
    if (!isValid) {
      var invalid = this.helper.findInvalidControls(f);
      console.log(invalid);
      this.setLoading(false);
      //this.notificationBuilder.showNotification('danger', 2000, 'Verifique se os dados estão corretos para atualizar os dados.')
      return false;
    }
    return true;
  }

  backToStep1() {
    this.registerFormStep1 = true;
    this.registerFormStep2 = false;
  }

  maskPhone(obj) {
    var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
      spOptions = {
        onKeyPress: function (val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
      };
    jQuery("input.phone").mask(SPMaskBehavior, spOptions);

    jQuery("input.phone").focusout(function () {
      if (jQuery("input.phone").val().length < 15) {
        obj.errorPhone = true;
      }
      else {
        obj.errorPhone = false;
      }
    })
  }

  maskEmail(obj) {
    jQuery("#email").mask("A", {
      translation: {
        "A": { pattern: /[\w@\-.+]/, recursive: true }
      }
    });
    jQuery("#email").focusout(function () {
      if (!obj.is_email(obj.donationUser.email)) {
        obj.errorEmail = true;
      }
      else {
        obj.errorEmail = false;
      }
    })
  }



  maskDocument(obj) {
    //soh cria a mascara de cnpj/cpf se foi uma campanha brasileira
    if (obj.campaign.language == 'br') {
      var CpfCnpjMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
      },
        cpfCnpjpOptions = {
          onKeyPress: function (val, e, field, options) {
            field.mask(CpfCnpjMaskBehavior.apply({}, arguments), options);
          }
        };

      jQuery(".document").mask(CpfCnpjMaskBehavior, cpfCnpjpOptions);
    }
  }

  showErrorPayment(e: any, showDefault: boolean = true) {
    debugger
    console.log(e);
    var defaultError = 'Ops! Não foi possível realizar a doação no momento. Por favor, tente mais tarde 😣'
    if (showDefault)
      this.errorPayment = defaultError
    else if (e != null) {
      if (e.message == "Invalid card number")
        this.errorPayment = 'O número do cartão é inválido'
      else if (e.message == 'Invalid security code')
        this.errorPayment = 'O Código de Segurança adicionado é inválido'
      else if (e.errorCode == 98) //Captcha not valid
        this.errorPayment = 'Captcha inválido'
      else if (e.error == "not-created") //erro enviado pela Juno
        this.errorPayment = defaultError
      else if (!e.success) //erro enviado pela Juno
        this.errorPayment = e.error
      else
        this.errorPayment = e.message
    }
    else {
      this.errorPayment = defaultError
    }
    this.setLoading(false)
  }

  maskDonation(obj) {
    obj.donationUser.donation = null;
    jQuery("#editAmount")
      //.mask("R$ 999.999,99")"#.##0,00", {reverse: true}
      .mask("#.##0,00", { reverse: true })
      .focusout(function (event) {
        var target, phone, element;
        target = (event.currentTarget) ? event.currentTarget : event.srcElement;
        var cleanPoint = target.value.replace(/\./g, '')
        var changeComa = obj.replaceAll(cleanPoint, ',', '.')
        obj.donationUser.donation = changeComa * 1
        obj.loadCouponBanner(obj.donationUser.donation)
      });
    jQuery("#editAmount").focus();
  }

  changeTab($element, id, elementsToHide, container) {
    setTimeout(() => {
      if (container != null)
        jQuery(container + ' .tabs-title').removeClass('is-active')

      if ($element != null)
        jQuery($element).parent().addClass("is-active")

      if (elementsToHide != null)
        $(elementsToHide).hide();

      if (id != null)
        $(id).show();

    }, 100)
  }

  scrollToElement(id): void {
    setTimeout(() => {
      var offset = $(id).offset();
      $('html, body').animate({
        scrollTop: offset.top - 100,
        scrollLeft: offset.left
      });
      // var element = document.getElementById(id);
      // element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 100)
  }


  is_email(email) {
    var er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}/;
    if (!er.exec(email)) {
      return false;
    }
    return true
  }

  is_CpfCnpj(doc, obj) {
    if (obj.is_CPF(doc))
      return true
    if (obj.is_CNPJ(doc))
      return true
    return false
  }

  is_Phone(doc) {
    if (this.campaign.language == 'br') {
      doc = doc.replace(/\D/g, '');
      var er = /^\(?\d{2}\)?[\s-]?[\s9]?\d{4}-?\d{4}$/;
      if (!er.exec(doc)) {
        return false;
      }
      return true
    }
    else {
      //quando ñ eh campanha brasileira, o telefone nao eh obrigatorio
      return true
    }
  }

  is_CPF(strCPF) {
    strCPF = strCPF.replace(/\D/g, '');
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  is_CNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;

  }

  replaceAll(str, find, replace) {
    if (str.indexOf(find) >= 0)
      return str.replace(new RegExp(find, 'g'), replace);
    return str;
  }

  toggleClass(idclass, classe) {
    setTimeout(() => {
      jQuery(idclass).toggleClass(classe);
    }, 100)
  }


  thankyouDonation(obj, paid, data) {
    debugger
    obj.gaService.trackEvent("concludedDonation", obj.selectedCause.url, obj.donationUser.donation);
    //var nameCause = obj.ngoSelected.name;
    // jQuery(".whats-desktop").attr("href", "https://web.whatsapp.com/send?text=Além do melhor preço, agora  a 99 vai doar 5% do valor das corridas para a causa que você escolher! Já me cadastrei e escolhi *" + nameCause + "*. Qual é a sua causa? Ajude agora você também! https%3A//james.com?utm_source=whats");
    // jQuery(".whats-mobile").attr("href", "whatsapp://send?text=Além do melhor preço, agora  a 99 vai doar 5% do valor das corridas para a causa que você escolher! Já me cadastrei e escolhi *" + nameCause + "*. Qual é a sua causa? Ajude agora você também! https%3A//james.com?utm_source=whats");
    this.twitterShareText = encodeURI("Estou participando da campanha " + this.campaign.name + " e acabei de apoiar instituições que estão na linha de frente do combate ao Covid-19, apoie você também: https://doe.polen.com.br/" + this.campaign.subDomainUrl + "?utm_source=twitter");
    //jQuery(".twitter").attr("href", "https://twitter.com/intent/tweet?text=" + twitterText + "&url=https%3A//james.com?utm_source=twitter&original_referer=https://james.com");

    jQuery("#confirmation-popup .filled-heart").css('opacity', '1')

    obj.bankSlipUrl = null;
    obj.bankSlipBarCode = null;
    obj.invoiceUrl = null;


    obj.setLoading(false);
    if (paid) {
      if (obj.paymentMethod == 'boleto' && data.metodo == 'boleto') {
        if (data.error != undefined) {
          console.log(data.error);
          console.log('boleto não foi criado!');
          obj.creditCard = new CreditCardInfo();
          this.ngxSmartModalService.getModal('notpaidModal').open()
          return;
        }

        obj.bankSlipUrl = data.bankSlipUrl;
        obj.bankSlipBarCode = data.bankSlipBarCode;

        setTimeout(() => {
          JsBarcode("#barcode", obj.bankSlipBarCode, {
            height: 75,
            displayValue: false,
            marginTop: 0
          });
        }, 1000)
      }
      else if (data.metodo == 'paypal') {
        obj.invoiceUrl = data.invoiceUrl;
        window.open(obj.invoiceUrl, '_blank');
      }
      else if (obj.paymentMethod == 'credit_card') {
        if (this.possibleGiftCoupon != null) {
          this.campaignService.getCouponCodeById(this.campaign.id, this.possibleGiftCoupon.id).subscribe(res => {
            if (res.success)
              obj.possibleGiftCoupon.coupon = res.data;
            else {
              obj.possibleGiftCoupon.coupon = '---';
              obj.possibleGiftCoupon.checkEmail = true
            }

          })
        }
      }

      console.log('cadastro realizado com sucesso!');
      //this.trackGa('Pagamento Confirmado', '');
      this.ngxSmartModalService.getModal('confirmationModal').open()
      setTimeout(() => {
        jQuery(".couponBanner").show()
      }, 1000)

    }
    else {
      this.gaService.trackEvent("failDonation");
      console.log('pagamento falhou!');
      //this.trackGa('Pagamento ñ realizado', '');
      obj.creditCard = new CreditCardInfo();
      this.ngxSmartModalService.getModal('notpaidModal').open()
    }
  }


  showUseTerms() {
    setTimeout(() => {
      new google.translate.TranslateElement(
        { pageLanguage: 'en' },
        'useterms-container'
      );

    }, 500);
    this.ngxSmartModalService.getModal('usetermsModal').open()
  }
}
