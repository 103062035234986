import { Configuration } from '../app.constants'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { CreditCardInfo } from '../models/payment.model';

declare var DirectCheckout: any
@Injectable({
  providedIn: 'root'
})

export class HelperService {
  constructor(private http: HttpClient) {
  }

  getPaymentType(paymentMethod: any) {
    if (paymentMethod == 'credit_card')
      return 1;
    if (paymentMethod == 'boleto')
      return 2;
    if (paymentMethod == 'paypal')
      return 5;
    if (paymentMethod == 'pix')
      return 6;
  }


  isNullOrEmpty(text) {
    return (text == null || text == '')
  }

  replaceAll(str, find, replace) {
    if (str == null)
      return str
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(str) {
    if (str == null)
      return str
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }

  //pega os ultimos lastCount do text
  getLastCharacters(text, lastCount) {
    if (!this.isNullOrEmpty(text))
      return text.trim().slice(text.length - lastCount)
    return text;
  }
  public findInvalidControls(f) {
    try {
      const invalid = [];
      const controls = f.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      console.log(invalid);
      return invalid;
    } catch (error) {
      return null;
    }

  }
}