import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { CampaignModel, UserCampaign, CampaignAnalytics } from 'src/app/models/campaign.model';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'backer-list',
  templateUrl: './backer-list.component.html',
  styleUrls: ['./backer-list.component.css']
})
export class BackerListComponent implements OnInit {

  @Input() ngoList: any
  @Input() color: any
  @Input() selectedCause: any
  @Input() campaign: CampaignModel;
  @Input() analytics: CampaignAnalytics;
  @Output() answer = new EventEmitter();
  public isAdmin: boolean
  slickerLoaded: boolean = false;
  colorLight: string
  userList: Array<UserCampaign>

  constructor() { }

  ngOnInit() {
    this.loadAnalytics(this.analytics)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.analytics.currentValue != null && !changes.analytics.firstChange) {
      this.loadAnalytics(changes.analytics.currentValue);
    }
  }

  loadAnalytics(analytics) {
    var obj = this;
    let newArray = [];
    analytics.listUser.forEach(user => {
      if(user.status === 1){
        newArray.push(user)
      }
    });
    analytics.listUser = newArray;
    analytics.listUser.forEach(function (user) {
      user.name = obj.capitalize(user.name.toLowerCase())
      user.ngoLogo = obj.getNgoLogo(user.ngoUrl);
      let randIndex = Math.floor((1 + Math.random() * 7));
      //console.log(randIndex)
      user.image = '../../../assets/custom/images/backers/backer-' + randIndex + '.png'
    });

    this.analytics = analytics
    this.loadSlick();
  }

  getNgoLogo(ngoUrl) {
    var logo = null;
    this.campaign.supportedNgos.forEach(item => {
      if (item.url == ngoUrl) {
        logo = item.logo
      }
    });

    return logo;
  }

  loadSlick() {
    var obj = this;
    setTimeout(() => {
      $('.slick-container').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ]
      });

      obj.slickerLoaded = true

    }, 500);
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}
