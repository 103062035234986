import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(public router: Router, public translate: TranslateService) {
    translate.addLangs(['en', 'pt', 'br'])
    translate.setDefaultLang('br');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //MULTIPLAN TAG        
        //gtag('config', 'UA-120731981-7', { 'page_path': event.urlAfterRedirects });

        //CUSTOMTAG
        //gtag('config', 'UA-120731981-7', {'page_path': event.urlAfterRedirects});
      }
    })
  }
}
