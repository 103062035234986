import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class HelperMethods {
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateDomain(domain) {
        var re = /^(((http|https|):\/\/)|www.|)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?($|([\/a-zA-Z0-9-]+))$/i;
        return re.test(String(domain).toLowerCase());
    }

    isContentTrue(obj) {
        return (obj != undefined && (obj.value == 'true' || obj.value == 'True'))
    }
}

