import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Configuration } from 'src/app/app.constants';
import { forkJoin, Observable } from 'rxjs';
import { HelperMethods } from 'src/app/customization/helperMethods';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CampaignService } from '../services/campaign.service';
import { CampaignModel } from '../models/campaign.model';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  url: string;
  content: any;
  isAdmin: boolean = true
  campaign: CampaignModel
  loading: boolean = false;
  loaded: boolean = false;
  position: number = 0;
  manualInstall: boolean = true
  platformSelected: string;
  selectedCause: any;

  constructor() {
    window['angularContext'] = this;
  }

  ngOnInit() {
    debugger
    if (window.location.href.indexOf("multiplan") >= 0) {
      window.location.href = 'https://multiplan.polen.com.br/multiplique-o-bem';
    }
    else {
      window.location.href = 'https://bemvindo.opolen.com.br/#/campanha';
    }
  }

}
