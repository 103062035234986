import { Configuration } from '../app.constants'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})

export class JunoBus {
  constructor(private http: HttpClient) {
  }

  tokenizeCreditCard(storeId, hash, holderName, brand): any {
    debugger
    var model = { storeId, hash, holderName, brand, type: 'store' };
    console.log(JSON.stringify(model))
    return new Promise(async (resolve, reject) => {
      resolve({ success: true, data: { 'metodo': 'boleto', 'bankSlipBarCode': '03399065964100000018604637101017482180000000680', 'bankSlipUrl': 'link' } })
    })
    // return this.http.post(Configuration.apiUrl + 'Api/juno/tokenize', JSON.stringify(model), {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json')
    // })
  }

  charge(seoUrl, statementId, paymentType, hash, paymentMethodId, last4number, holderName, brand, installment, captchaToken): any {
    debugger
    var model = { statementId, paymentType, hash, last4number, holderName, paymentMethodId, brand, installment, captchaToken };
    console.log("verificar o charge -----------------------------")
    console.log(JSON.stringify(model));
    // return new Promise(async (resolve, reject) => {
    //   resolve({ success: true, data: { 'metodo': 'boleto', 'bankSlipBarCode': '03399065964100000018604637101017482180000000680', 'bankSlipUrl': 'link' } })
    // })

    return this.http.post(Configuration.apiUrl + 'Api/juno/usercharge?seoUrl=' + seoUrl, JSON.stringify(model), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }
}
