import { Configuration } from '../app.constants'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(private http: HttpClient) {
  }

  async createUserStatement(userDonation, api_token, test): Promise<any> {
    var obj = this
    return new Promise((resolve, reject) => {
      debugger
      console.log(api_token)
      console.log(JSON.stringify(userDonation))
      //reject({ success: false, error: "Token Invalido" })
      //resolve({ success: true, statementId: "0fa1da00-8950-4b9a-ad3e-870b0bc89996" })
      this.http.post(Configuration.apiUrl + 'api/Statement/createUserStatement?api_token=' + api_token + '&test=' + test, JSON.stringify(userDonation), {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
      }).subscribe(data => {
        debugger
        resolve(data)
      }, function (error) {
        debugger
        reject({ success: false, error })
      });
    });
  }

  syncCreateUserStatement(payment, api_token, test): any {
    // console.log(api_token)
    console.log(JSON.stringify(payment))
    return this.http.post(Configuration.apiUrl + 'api/Billing/createUserStatement?api_token=' + api_token + '&test=' + test, JSON.stringify(payment), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  createDirectInvoice(payment, api_token, test): any {
    // console.log(api_token)
    // console.log(JSON.stringify(payment))
    return this.http.post(Configuration.apiUrl + 'api/Billing/userdonation?api_token=' + api_token + '&test=' + test, JSON.stringify(payment), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }

  createPaymentTokenIugu(payment, seoUrl, api_token, test): any {
    // console.log(api_token)
    // console.log(JSON.stringify(payment))
    return this.http.post(Configuration.apiUrl + 'api/Billing/IugucreatePaymentToken?api_token=' + api_token + '&seoUrl=' + seoUrl + '&test=' + test, JSON.stringify(payment), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    })
  }
}
