<!-- header -->
<div *ngIf="campaign == null && loaded">
  <nav class="navbar navbar-expand-lg navbar-light" style="border-bottom: solid 5px var(--color);">
    <div class="wrapper-navbar">
      <div class="wrap-header-mobile d-flex d-lg-none">
        <a class="navbar-brand" href="#">
          <!-- <img src="{{ campaign.logo }}" width="100" alt="{{ campaign.name }}"> -->
          <img src="../../assets/custom/images/polen-logo-horizontal.png" style="max-height: 80px;"
            alt="Um produto Polen">
        </a>
        <div>
          <a href="javascript:void(0)" (click)="scrollToElement('#choose-ong-section')" id="buttonHeader"
            class="select-donation btn btn-donate mr-2" style="background-color: var(--color);">QUERO APOIAR</a>
          <button id="showNavMobile" class="navbar-toggler" type="button" data-toggle="collapse"
            style="color: var(--color);" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false"
            aria-label="Toggle navigation">
            <svg class="icon-nav" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              height="30" width="30" version="1.1" id="Camada_1" x="0px" y="0px" viewBox="0 0 32 25.25"
              style="enable-background:new 0 0 32 25.25;" xml:space="preserve">
              <style type="text/css">
                .st9 {
                  fill: var(--color);
                }
              </style>
              <g>
                <g>
                  <path class="st9"
                    d="M29.5,5h-27C1.12,5,0,3.88,0,2.5S1.12,0,2.5,0h27C30.88,0,32,1.12,32,2.5S30.88,5,29.5,5z" />
                </g>
                <g>
                  <path class="st9"
                    d="M29.5,15.13h-27c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5h27c1.38,0,2.5,1.12,2.5,2.5    S30.88,15.13,29.5,15.13z" />
                </g>
                <g>
                  <path class="st9"
                    d="M29.5,25.25h-27c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5h27c1.38,0,2.5,1.12,2.5,2.5    S30.88,25.25,29.5,25.25z" />
                </g>
              </g>
            </svg>
            <!--img class="icon-nav" src="../../assets/custom/images/icon-nav.svg" alt=""-->
            <svg class="icon-close d-none" width="30" height="30" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Camada_1" x="0px" y="0px"
              viewBox="0 0 24.09 24.09" style="enable-background:new 0 0 24.09 24.09;" xml:space="preserve">
              <style type="text/css">
                .st9 {
                  fill: var(--color);
                }
              </style>
              <g>
                <g>
                  <path class="st9"
                    d="M23.36,19.83c0.98,0.97,0.98,2.56,0,3.53c-0.49,0.49-1.13,0.73-1.77,0.73c-0.64,0-1.28-0.24-1.77-0.73    l-7.77-7.77l-7.78,7.77c-0.49,0.49-1.13,0.73-1.77,0.73c-0.64,0-1.28-0.24-1.77-0.73c-0.97-0.97-0.97-2.56,0-3.53l7.78-7.78    L0.73,4.27c-0.97-0.98-0.97-2.56,0-3.54c0.98-0.97,2.56-0.97,3.54,0l7.78,7.78l7.77-7.78c0.98-0.97,2.56-0.97,3.54,0    c0.98,0.98,0.98,2.56,0,3.54l-7.78,7.78L23.36,19.83z" />
                </g>
              </g>
            </svg>
            <!--img class="icon-close d-none" src="../../assets/custom/images/icon-close.svg" alt=""-->
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse justify-content-between" id="navbarToggle">
        <a class="navbar-brand d-none d-lg-block" href="/">
          <img src="../../assets/custom/images/polen-logo-horizontal.png" style="max-height:80px"
            alt="um produto Polen">
        </a>
      </div>
    </div>
  </nav>
  <section class="jumbotron jumbotron-fluid transparencia">
    <div class="container">
      <div class="row wrap-banner-transparencia d-none d-md-flex">
        <div class="col-7 col-lg-5 text-left wrap-content-transparencia">
          <div class="content pl-3 pr-5">
            <!-- <h1 class="h1 mt-5">Todos juntos contra o <br><em style="color: var(--color);">COVID-19</em></h1> -->
            <h1 class="h1 mt-5">Esta campanha ainda não está ativa</h1>
            <div class="powered-polen mt-4">
              <span style="margin-right: 10px;" class="">Verificado pelo:</span>
              <img style="max-width: 80px;" src="https://polen.com.br/content/v6/images/logo-polen-horizontal.svg"
                alt="Polen">
            </div>
          </div>
        </div>
        <div class="col-5 col-lg-7"></div>
      </div>
    </div>
  </section>
</div>
<div *ngIf="campaign != null ">
  <nav class="navbar navbar-expand-lg navbar-light" style="border-bottom: solid 5px var(--color);">
    <div class="wrapper-navbar">
      <div class="wrap-header-mobile d-flex d-lg-none">
        <a class="navbar-brand" href="#">
          <!-- <img src="{{ campaign.logo }}" width="100" alt="{{ campaign.name }}"> -->
          <img src="{{ campaign.logo }}" style="max-height: 80px;" alt="{{ campaign.name }}">
        </a>
        <div>
          <a href="javascript:void(0)" (click)="scrollToElement('#choose-ong-section')" id="buttonHeader"
            class="select-donation btn btn-donate mr-2" style="background-color: var(--color);">QUERO APOIAR</a>
          <button id="showNavMobile" class="navbar-toggler" type="button" data-toggle="collapse"
            style="color: var(--color);" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false"
            aria-label="Toggle navigation">
            <svg class="icon-nav" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              height="30" width="30" version="1.1" id="Camada_1" x="0px" y="0px" viewBox="0 0 32 25.25"
              style="enable-background:new 0 0 32 25.25;" xml:space="preserve">
              <style type="text/css">
                .st9 {
                  fill: var(--color);
                }
              </style>
              <g>
                <g>
                  <path class="st9"
                    d="M29.5,5h-27C1.12,5,0,3.88,0,2.5S1.12,0,2.5,0h27C30.88,0,32,1.12,32,2.5S30.88,5,29.5,5z" />
                </g>
                <g>
                  <path class="st9"
                    d="M29.5,15.13h-27c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5h27c1.38,0,2.5,1.12,2.5,2.5    S30.88,15.13,29.5,15.13z" />
                </g>
                <g>
                  <path class="st9"
                    d="M29.5,25.25h-27c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5h27c1.38,0,2.5,1.12,2.5,2.5    S30.88,25.25,29.5,25.25z" />
                </g>
              </g>
            </svg>
            <!--img class="icon-nav" src="../../assets/custom/images/icon-nav.svg" alt=""-->
            <svg class="icon-close d-none" width="30" height="30" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Camada_1" x="0px" y="0px"
              viewBox="0 0 24.09 24.09" style="enable-background:new 0 0 24.09 24.09;" xml:space="preserve">
              <style type="text/css">
                .st9 {
                  fill: var(--color);
                }
              </style>
              <g>
                <g>
                  <path class="st9"
                    d="M23.36,19.83c0.98,0.97,0.98,2.56,0,3.53c-0.49,0.49-1.13,0.73-1.77,0.73c-0.64,0-1.28-0.24-1.77-0.73    l-7.77-7.77l-7.78,7.77c-0.49,0.49-1.13,0.73-1.77,0.73c-0.64,0-1.28-0.24-1.77-0.73c-0.97-0.97-0.97-2.56,0-3.53l7.78-7.78    L0.73,4.27c-0.97-0.98-0.97-2.56,0-3.54c0.98-0.97,2.56-0.97,3.54,0l7.78,7.78l7.77-7.78c0.98-0.97,2.56-0.97,3.54,0    c0.98,0.98,0.98,2.56,0,3.54l-7.78,7.78L23.36,19.83z" />
                </g>
              </g>
            </svg>
            <!--img class="icon-close d-none" src="../../assets/custom/images/icon-close.svg" alt=""-->
          </button>
        </div>
      </div>
      <div class="collapse navbar-collapse justify-content-between" id="navbarToggle">
        <a class="navbar-brand d-none d-lg-block" href="/">
          <img src="{{ campaign.logo }}" style="max-height:80px" alt="{{ campaign.name }}">
        </a>
        <div class="wrap-nav">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ml-lg-5">
            <li class="nav-item">
              <a class="nav-link" href="#how-it-works">Como funciona</a>
            </li>
          </ul>
          <div class="wrap-actions-nav ml-3">
            <a href="javascript:void(0)" (click)="scrollToElement('#choose-ong-section')"
              class="select-donation btn btn-donate border-0" style="background-color: var(--color);">QUERO
              APOIAR</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- banner -->
  <!-- <section class="title-mobile d-md-none d-lg-none mt-4 mb-4">
    <div class="col-12">
      <h1 class="">{{ campaign.name }}</h1>
    </div>
  </section> -->
  <section class="jumbotron jumbotron-fluid transparencia"
    [ngStyle]="{ 'background-image': 'linear-gradient(var(--color20), var(--color60)), url(' + campaign.cover + ')'}">
    <div class="container">
      <div class="polen-mobile row  d-md-none d-lg-none d-md-flex">
        <div class="col-12 col-lg-5 text-right">
          <div class="content pl-3">
            <!-- <h1 class="h1 mt-5">Todos juntos contra o <br><em style="color: var(--color);">COVID-19</em></h1> -->
            <h1 class="h1" style="font-size: 2.5rem;
            font-weight: bold;
            color: white;">{{ campaign.name }}</h1>
          </div>
        </div>
      </div>
      <div class="row wrap-banner-transparencia d-none d-md-flex">
        <div class="col-7 col-lg-5 text-left wrap-content-transparencia">
          <div class="content pl-3 pr-5">
            <!-- <h1 class="h1 mt-5">Todos juntos contra o <br><em style="color: var(--color);">COVID-19</em></h1> -->
            <h1 class="h1 mt-5">{{ campaign.name }}</h1>
            <div class="mt-4 wrap-content-logos-transparencia">
              <div class="logo-circle box-120 p-2 mr-2" *ngFor="let ngo of campaign.supportedNgos; let i = index">
                <img src="{{ ngo.logo }}" alt="{{ ngo.name }}">
              </div>
            </div>
            <a href="javascript:void(0)" class="select-donation btn btn-primary"
              (click)="scrollToElement('#choose-ong-section')"
              style="background-color: var(--color); border-color: transparent;">QUERO
              APOIAR</a>
            <div class="powered-polen mt-4">
              <span style="margin-right: 10px;" class="">Verificado pelo:</span>
              <img style="max-width: 80px;" src="https://polen.com.br/content/v6/images/logo-polen-horizontal.svg"
                alt="Polen">
            </div>
          </div>
        </div>
        <div class="col-5 col-lg-7"></div>
      </div>
    </div>
  </section>
  <section class="d-md-none d-lg-none ">
    <div class="line-powered-polen col-12 py-2" style="background-color: #413279;">
      <div class="powered-polen d-flex align-items-center" style="justify-content: center;">
        <span style="margin-right: 10px; color: #ffffff;" class="">Verificado pelo:</span>
        <img style="max-width: 60px;" src="../../assets/custom/images/donation-app/logo-polen-horizontal-branca.svg"
          alt="Polen">
      </div>
    </div>
  </section>
  <section class="wrapper-section pt-0 d-sm-none text-center">
    <div class="container">
      <div class="row wrap-banner-transparencia-mobile">
        <div class="col-12">
          <div class="mt-2 wrap-content-logos-transparencia">
            <div class="logo-circle box-120 p-2 mr-2" *ngFor="let ngo of campaign.supportedNgos; let i = index">
              <img src="{{ ngo.logo }}" alt="{{ ngo.name }}">
            </div>
          </div>
          <a href="javascript:void(0)" class="select-donation btn btn-primary"
            (click)="scrollToElement('#choose-ong-section')"
            style="background-color: var(--color); border-color: transparent;">QUERO
            APOIAR</a>
        </div>
      </div>
    </div>
  </section>
  <!-- Sobre - Doações ATIVAS -->
  <section class="wrapper-section" *ngIf="content.showTotalDonated.value == 'true'">
    <div class="container">
      <div class="row">
        <div class="col-md-6 wrap-transparencia-sobre">
          <h2 class="h3 title-section text-center text-md-left">Sobre a campanha</h2>
          <span style="background-color: var(--color);" class="line-title ml-md-0"></span>
          <p class="px-4 pl-md-0 pr-md-5">{{ content.about.value }}</p>
        </div>
        <div class="col-md-6">
          <div class="row mt-5 mt-md-0">
            <div class="col-2"></div>
            <div class="col-md-8 wrap-transparencia-doamos px-4">
              <h2 class="h3 title-section">Juntos já doamos</h2>
              <span style="background-color: var(--color);" class="line-title"></span>
              <!-- <div style="display: flex;">
                <svg style="margin: 24px 16px 24px;" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                  xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Camada_1" x="0px" y="0px"
                  viewBox="0 0 48.82 48.82" style="enable-background:new 0 0 48.82 48.82;" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      opacity: 0.9;
                      fill: #FFFFFF;
                    }

                    .st3 {
                      fill: #FFFFFF;
                    }

                    .st5 {
                      fill: #cc6633;
                    }
                  </style>
                  <g>
                    <circle class="st5" cx="24.41" cy="24.41" r="24.41" />
                    <g>
                      <g>
                        <path class="st3"
                          d="M38.94,25.34c-1.68-0.98-3.09,0.48-3.62,1.02c-1.12,1.16-2.46,2.3-3.15,2.87c-0.29-0.13-0.61-0.2-0.94-0.21     c-2.08-0.11-5.35-0.49-6.17-0.97c-0.88-0.52-1.59-1.03-2.21-1.48c-1.79-1.29-3.2-2.32-6.71-2.32c-1.02,0-1.91,0.28-2.77,0.56     c-0.89,0.28-1.72,0.55-2.73,0.55c-1.11,0-2.02,0.91-2.02,2.02v5.14c0,1.11,0.91,2.02,2.02,2.02c1.92,0,4.92,0.1,5.59,0.32     c1.19,0.4,3.99,2.06,4.97,2.66c0.01,0.01,0.03,0.02,0.04,0.02c0.35,0.2,0.75,0.3,1.16,0.3h9.34c0.99,0,2.09-0.91,2.47-1.55     c1.22-2.03,5.61-7.56,5.66-7.61c0.04-0.05,0.07-0.1,0.1-0.16C40.52,27.38,40.08,26.01,38.94,25.34z M31.72,36h-9.34     c-0.09,0-0.18-0.02-0.25-0.06c-0.35-0.21-3.74-2.3-5.33-2.83c-1.18-0.39-5.34-0.41-6.17-0.41c-0.1,0-0.18-0.08-0.18-0.18v-5.14     c0-0.1,0.08-0.18,0.18-0.18c1.29,0,2.35-0.34,3.29-0.63c0.8-0.25,1.49-0.47,2.22-0.47c2.92,0,3.94,0.74,5.63,1.97     c0.65,0.47,1.39,1.01,2.34,1.58c0.48,0.28,1.27,0.51,2.16,0.68c1.91,0.37,4.25,0.52,4.88,0.55c0.02,0,0.03,0,0.05,0     c0.3,0,0.55,0.25,0.55,0.55c0,0.3-0.25,0.55-0.55,0.55c-0.02,0-0.03,0-0.05,0l-6.95,0.37c-0.55-0.02-1.86-0.94-2.73-1.56     c-0.22-0.16-0.44-0.31-0.66-0.46c-0.47-0.32-0.92-0.62-1.33-0.82c-0.45-0.23-1-0.04-1.23,0.41c-0.23,0.45-0.04,1.01,0.41,1.23     c0.47,0.24,1.09,0.67,1.75,1.14c1.38,0.98,2.68,1.9,3.81,1.9c0.02,0,0.03,0,0.05,0l6.96-0.37c1.3-0.01,2.36-1.08,2.36-2.39     c0-0.3-0.05-0.58-0.15-0.84c0.74-0.61,2.06-1.75,3.2-2.93c0.78-0.81,1.09-0.88,1.37-0.72c0.24,0.14,0.42,0.42,0.33,0.7     c-0.61,0.77-4.51,5.71-5.72,7.72C32.46,35.61,31.92,35.98,31.72,36z" />
                      </g>
                      <g>
                        <path class="st3"
                          d="M28.95,24.96c-3.85,0-6.99-3.13-6.99-6.99s3.13-6.99,6.99-6.99s6.99,3.13,6.99,6.99S32.8,24.96,28.95,24.96z      M28.95,12.51c-3.01,0-5.46,2.45-5.46,5.46s2.45,5.46,5.46,5.46s5.46-2.45,5.46-5.46S31.96,12.51,28.95,12.51z" />
                      </g>
                      <g>
                        <g>
                          <path class="st3"
                            d="M30.87,19.02c0,0.4-0.15,0.72-0.44,0.97c-0.29,0.24-0.7,0.39-1.22,0.44v0.78H28.7v-0.76      c-0.62-0.01-1.16-0.12-1.62-0.33v-1c0.22,0.11,0.48,0.21,0.79,0.29c0.31,0.08,0.59,0.13,0.83,0.15v-1.17l-0.25-0.1      c-0.5-0.2-0.85-0.41-1.06-0.64c-0.21-0.23-0.31-0.52-0.31-0.86c0-0.37,0.14-0.67,0.43-0.9c0.29-0.24,0.69-0.38,1.2-0.43v-0.58      h0.52v0.56c0.58,0.02,1.1,0.14,1.57,0.35l-0.36,0.89c-0.39-0.16-0.8-0.26-1.21-0.3v1.12c0.49,0.19,0.84,0.35,1.05,0.49      c0.21,0.14,0.36,0.29,0.46,0.46C30.83,18.6,30.87,18.79,30.87,19.02z M28.21,16.77c0,0.11,0.04,0.2,0.12,0.27      c0.08,0.07,0.2,0.15,0.37,0.22v-0.89C28.37,16.43,28.21,16.56,28.21,16.77z M29.73,19.07c0-0.11-0.04-0.2-0.13-0.27      c-0.09-0.07-0.21-0.15-0.39-0.23v0.94C29.56,19.46,29.73,19.31,29.73,19.07z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <img src="../../assets/custom/images/transparencia/ja-doamos-azul.svg" class="my-4 ml-2 ml-md-4 mr-3" alt="Já doamos"
                <p class="doamos-valor"><strong class="h2 m-0">R$ 4.457,00</strong> impactando positivamente as
                  organizações que apoiamos</p>
              </div> -->
              <img src="../../assets/custom/images/transparencia/ja-doamos-preto.svg" class="my-2 ml-2 ml-md-4 mr-3"
                alt="Já doamos">
              <p class="doamos-aguarde"><strong>Estamos só começando, aguarde a atualização dos nossos
                  resultados</strong>
              </p>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Sobre - Doações INATIVAS -->
  <section class="wrapper-section" *ngIf="content.showTotalDonated.value != 'true'">
    <div class="container">
      <div class="row">
        <div class="col-12 wrap-transparencia-sobre">
          <h2 class="h3 title-section text-center">Sobre a campanha</h2>
          <span style="background-color: var(--color);" class="line-title md-0"></span>
          <p class="px-4 pl-md-0 pr-md-5" style="text-align: center;">{{ content.about.value }}</p>
        </div>
      </div>
    </div>
  </section>
  <ong-selector (answer)="getAnswer('ongselect', $event)" [selectedCause]="selectedCause" [color]="'var(--color)'"
    [ngoList]="campaign.supportedNgos">
  </ong-selector>
  <direct-donation (answer)="getAnswer('donation', $event)" [selectedCause]="selectedCause" [campaign]="campaign"
    [seoUrl]="campaign.seoUrl">
  </direct-donation>
  <!-- Instituições que apoiamos -->
  <section class="container-fluid wrapper-section wrap-transparencia-instituicoes">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="title-section" *ngIf="campaign.owner == 0">Conheça as Organizações</h2>
        <h2 class="title-section" *ngIf="campaign.owner == 1">Conheça nossa Organização</h2>
        <span style="background-color: var(--color);" class="line-title"></span>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex knowmore-ngo">
        <div class="col-12 col-md-6" *ngFor="let ngo of campaign.supportedNgos; let i = index">
          <div class="wrap-company-partner px-md-5 pb-2 pb-md-4">
            <div class="logo-circle box-150">
              <img src="{{ ngo.logo }}" alt="{{ ngo.name }}">
            </div>
            <p>{{ ngo.description}}</p>
            <p class="text-right mb-0" style="height: auto;"><a target="_blank" href="https://polen.com.br/{{ ngo.url}}"
                class="text-right">Saiba
                mais</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Depoimento -->
  <section class="container-fluid wrapper-section wrap-transparencia-depoimento" style="display: none;">
    <div class="container">
      <div class="row">
        <div class="col-md-6 p-0">
          <figure class="m-0">
            <!--img class="img-fluid" src="../../assets/custom/images/transparencia/leandro-afonso-gestor.jpg" alt="Leandro Afonso"-->
            <img class="img-fluid"
              src="https://fee.org/media/13288/steve-jobs.jpg?anchor=center&mode=crop&width=1920&rnd=131303471740000000"
              alt="">
          </figure>
        </div>
        <div class="col-md-6 d-flex" style="align-items: center;">
          <div class="wrap-content px-md-5">
            <p class="mt-4 m-md-0">"Ganha pontos com o cliente e com a empresa. Deu sentido ao pessoal do trabalho
              operacional (propósito da empresa). Engajamento da equipe."</p>
            <p><span class="mt-2 mt-md-5 d-block">Carol Moreno</span> <small>Fundadora</small></p>
            <a href="" class="btn btn-primary mt-2 mt-md-4 mb-4 mb-md-0"
              style="background-color: var(--color); border-color: transparent;">Fazer parte do movimento</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Como funciona e FAQ -->
  <how-it-works (answer)="getAnswer('cause', $event)" [color]="'var(--color)'" [campaign]="campaign"
    [ngoList]="campaign.supportedNgos">
  </how-it-works>
  <!-- Footer -->
  <footer class="container-fluid bg-light py-lg-5">
    <div class="row">
      <div class="container">
        <div class="row mb-lg-5">
          <div class="copyright">
            Feito com
            <ul class="list-change-text">
              <li>café</li>
              <li>xablau</li>
              <li>mel</li>
              <li>amor</li>
              <li>bolacha</li>
              <li>memes</li>
              <li>propósito</li>
            </ul>
            por Polen
          </div>
        </div>
        <!-- Only desktop -->
        <div class="row d-none d-md-flex">
          <div class="col-sm-4 wrap-footer">
            <img src="../../assets/custom/images/logo-polen-vertical.svg" width="110" alt="Logo Polen">
            <h6 class="title-social">Siga-nos nas redes</h6>
            <ul class="list-social">
              <li>
                <a href="https://www.facebook.com/sigaopolen/" target="_blank">
                  <img src="../../assets/custom/images/icon-facebook.svg" height="25" alt="Logo Facebook">
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/opolen/" target="_blank">
                  <img src="../../assets/custom/images/icon-instagram.svg" height="25" alt="Logo Instagram">
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UChyN5Aps8nFLyqTtVf-TMtA" target="_blank">
                  <img src="../../assets/custom/images/icon-youtube.svg" height="25" alt="Logo YouTube">
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/o-polen/" target="_blank">
                  <img src="../../assets/custom/images/icon-linkedin.svg" height="25" alt="Logo Linkedin">
                </a>
              </li>
            </ul>
            <address style="margin-bottom: 15px;">
              <p><b>Polen Tecnologia LTDA - CNPJ 28.133.440/0001-36</b></p>
              <a class="address" href="https://goo.gl/maps/3sPcCNmz4U62" rel="external" title="Veja no Google Maps">Rua
                General Carneiro 1031, Centro, Curitiba - PR, Brasil</a>
            </address>
            <address style="margin-top: 0;">
              <p><b>{{ campaign.storeBillingName }} - CNPJ {{ campaign.storeDocument }}</b></p>
              <a class="address" href="https://goo.gl/maps/3sPcCNmz4U62" rel="external" title="Veja no Google Maps">Rua
                {{ campaign.storeBillingAddress}}, Brasil</a>
            </address>
          </div>
          <div class="col-sm-4">
            <h5 class="title-nav-footer">POLEN</h5>
            <ul class="list-nav-footer">
              <li>
                <a href="https://polen.com.br/?utm_source=landing_cliente&utm_medium=covid_19">Página Inicial</a>
              </li>
              <li>
                <a href="https://polen.com.br/sobre?utm_source=landing_cliente&utm_medium=covid_19">Sobre nós</a>
              </li>
              <li>
                <a href="http://blog.opolen.com.br/?utm_source=landing_cliente&utm_medium=covid_19">Blog</a>
              </li>
            </ul>
            <h5 class="title-nav-footer">SUPORTE</h5>
            <ul class="list-nav-footer">
              <li>
                <a href="https://polen.com.br/contato?utm_source=landing_cliente&utm_medium=covid_19">Fale Conosco</a>
              </li>
              <a class="email" href="mailto:eupolinizo@polen.com.br">eupolinizo@polen.com.br</a>
            </ul>
          </div>
          <div class="col-sm-4">
            <h5 class="title-nav-footer">PARA EMPRESAS</h5>
            <ul class="list-nav-footer">
              <li>
                <a href="https://bemvindo.opolen.com.br/#/campanha?utm_source=landing_cliente&utm_medium=covid_19">Campanhas
                  Emergenciais</a>
              </li>
              <li>
                <a href="https://polen.com.br/ecommerces?utm_source=landing_cliente&utm_medium=covid_19">1 Compra = 1
                  Doação</a>
              </li>
              <li>
                <a href="https://polen.com.br/bomtroco?utm_source=landing_cliente&utm_medium=covid_19">BomTroco</a>
              </li>
              <li>
                <a href="https://polen.com.br/ajudar-ongs?utm_source=landing_cliente&utm_medium=covid_19">Causas
                  parceiras</a>
              </li>
            </ul>
            <!-- <a href="https://bemvindo.opolen.com.br/#/campanha?utm_source=landing_cliente&utm_medium=covid_19&utm_campaign=crie_sua_campanha"
              class="select-donation btn btn-donate"
              style="background-color: var(--color); border-color: transparent;">CRIE SUA CAMPANHA</a> -->
          </div>
        </div>
        <!-- Only mobile -->
        <div class="row d-flex d-md-none">
          <div class="col-sm-12 wrap-footer">
            <img src="../../assets/custom/images/logo-polen-vertical.svg" width="110" alt="Logo Polen">
            <div class="row mb-2 mt-5">
              <div class="col-6">
                <h5 class="title-nav-footer">POLEN</h5>
                <ul class="list-nav-footer">
                  <li>
                    <a href="https://polen.com.br/?utm_source=landing_cliente&utm_medium=covid_19">Página Inicial</a>
                  </li>
                  <li>
                    <a href="https://polen.com.br/sobre?utm_source=landing_cliente&utm_medium=covid_19">Sobre nós</a>
                  </li>
                  <li>
                    <a href="http://blog.opolen.com.br/?utm_source=landing_cliente&utm_medium=covid_19">Blog</a>
                  </li>
                </ul>
                <h5 class="title-nav-footer">SUPORTE</h5>
                <ul class="list-nav-footer">
                  <li>
                    <a href="https://polen.com.br/contato?utm_source=landing_cliente&utm_medium=covid_19">Fale
                      Conosco</a>
                  </li>
                  <a class="email" href="mailto:eupolinizo@polen.com.br">eupolinizo@polen.com.br</a>
                </ul>
              </div>
              <div class="col-6">
                <h5 class="title-nav-footer">O POLEN</h5>
                <ul class="list-nav-footer" style="line-height: 3rem;">
                  <li style="line-height: 1rem;">
                    <a href="https://bemvindo.opolen.com.br/#/campanha?utm_source=landing_cliente&utm_medium=covid_19">Campanhas
                      Emergenciais</a>
                  </li>
                  <li>
                    <a href="https://polen.com.br/ecommerces?utm_source=landing_cliente&utm_medium=covid_19">1 Compra =
                      1 Doação</a>
                  </li>
                  <li>
                    <a href="https://polen.com.br/bomtroco?utm_source=landing_cliente&utm_medium=covid_19">BomTroco</a>
                  </li>
                  <li>
                    <a href="https://polen.com.br/ajudar-ongs?utm_source=landing_cliente&utm_medium=covid_19">Causas
                      parceiras</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-12 wrap-footer">
            <!-- <a href="https://bemvindo.opolen.com.br/#/campanha?utm_source=landing_cliente&utm_medium=covid_19&utm_campaign=crie_sua_campanha"
              class="select-donation btn btn-donate"
              style="background-color: var(--color); border-color: transparent;">CRIE SUA CAMPANHA</a> -->
            <div class="col-sm-12 wrap-footer">
              <h6 class="title-social">Siga-nos nas redes</h6>
              <ul class="list-social mb-5">
                <li class="mx-2">
                  <a href="https://www.facebook.com/sigaopolen/" target="_blank">
                    <img src="../../assets/custom/images/icon-facebook.svg" height="40" alt="Logo Facebook">
                  </a>
                </li>
                <li class="mx-2">
                  <a href="https://www.instagram.com/opolen/" target="_blank">
                    <img src="../../assets/custom/images/icon-instagram.svg" height="40" alt="Logo Instagram">
                  </a>
                </li>
                <li class="mx-2">
                  <a href="https://www.youtube.com/channel/UChyN5Aps8nFLyqTtVf-TMtA" target="_blank">
                    <img src="../../assets/custom/images/icon-youtube.svg" height="40" alt="Logo YouTube">
                  </a>
                </li>
                <li class="mx-2">
                  <a href="https://www.linkedin.com/company/o-polen/" target="_blank">
                    <img src="../../assets/custom/images/icon-linkedin.svg" height="40" alt="Logo Linkedin">
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
