import { Injectable } from '@angular/core'

@Injectable()
export class Configuration {
    constructor() {
    }

    // public static readonly apiUrl = 'http://localhost:49784/'
    public static readonly apiUrl = 'https://storeapi.polen.com.br/'
    //public static readonly apiUrl = 'https://storeapibeta.opolen.com.br/'


    // public static readonly storePanelUrl = 'http://localhost:4300/#/'
    public static readonly storePanelUrl = 'https://painel.polen.com.br/#/'

    public static readonly sealApiUrl = 'https://api.polen.com.br/'

    public static readonly junoProduction = true
    public static readonly junoProductionPublicKey = 'F003F71D4412E496320955E806D57328A25A7990F6CE93C828F1E58F3EA01A1603DD370E05F186B0' //PRODUCTION
    public static readonly junoSandboxPublicKey = '3C2DB3FA9E29E0B7CB468AB6CA0278676D4F16D940AEC338FAF9E823183BB2BA' //SANDBOX
}