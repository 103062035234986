<div id="termosdeuso-container">
	<p align="CENTER" style="margin-bottom: 0in; line-height: 100%;"><u><strong>TERMOS
				DE USO&nbsp;&ndash; Polen</strong></u></p>

	<p align="CENTER" style="margin-bottom: 0in; line-height: 100%;"><strong>&nbsp;{{ newcampaign.name }} &ndash;
			{{ newcampaign.storeName }}</strong></p>

	<p align="CENTER" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol>
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Os
									presentes Termos de Uso trazem as diretrizes gerais para a
									utiliza&ccedil;&atilde;o da tecnologia POLEN para participa&ccedil;&atilde;o na a&ccedil;&atilde;o
									&ldquo;</span></span></span></span></span> {{ newcampaign.name }}<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">&rdquo; (A&Ccedil;&Atilde;O) por meio do
									website&nbsp;</span></span></span></span></span> <a
					href="{{campaignUrl}}"><u>&nbsp;{{ campaignUrl }}</u></a><span style="font-variant: normal;"><span
						style="text-decoration: none;"><span style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;ou d</span></span></span></span></span>a<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;</span></span></span></span></span>empresa<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;&ldquo;</span></span></span></span></span>
				{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
							style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">,
									por todos seus PARTICIPANTES;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol type="a">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Por PARTICIPANTES, entendemos todos
									aqueles que efetuam uma doa&ccedil;&atilde;o por meio do formul&aacute;rio presente no
									site&nbsp;</span></span></span></span></span><a
					href="{{campaignUrl}}"><u>&nbsp;{{ campaignUrl }}</u></a><span style="font-variant: normal;"><span
						style="text-decoration: none;"><span style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 0.74in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="2" type="a">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">A
									A&Ccedil;&Atilde;O &ldquo;</span></span></span></span></span> {{ newcampaign.name }}<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">&rdquo; &eacute;
									promovida&nbsp;</span></span></span></span></span>pela empresa
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;</span></span></span></span></span>
				{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
							style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">,
									mantido
									e disponibilizado por&nbsp;</span></span></span></span></span> {{ newcampaign.storeBillingName }}<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">., pessoa jur&iacute;dica de direito
									privado, regularmente inscrita no CNPJ/MF sob o n&ordm;&nbsp;</span></span></span></span></span>
				{{ newcampaign.storeDocument }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
							style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">, com
									sede
									na&nbsp;</span></span></span></span></span> {{ newcampaign.storeBillingAddress }}<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="3" type="a">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span
									style="background: #ffffff;">Ao&nbsp;</span></span></span></span></span><span
					style="background: #ffffff;">&nbsp;{{ newcampaign.storeName }}</span><span style="font-variant: normal;"><span
						style="text-decoration: none;"><span style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;coube a sele&ccedil;&atilde;o das organiza&ccedil;&otilde;es
									sociais
									que atuam na linha de frente do combate ao Coronav&iacute;rus (COVID-19) para serem
									benefici&aacute;rias
									dos recursos arrecadados pela a&ccedil;&atilde;o, bem como a promo&ccedil;&atilde;o e
									divulga&ccedil;&atilde;o da campanha, integrando em s</span></span></span></span></span><span
					style="background: #ffffff;">ua</span><span style="font-variant: normal;"><span
						style="text-decoration: none;"><span style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;</span></span></span></span></span><span
					style="background: #ffffff;">empresa</span><span style="font-variant: normal;"><span
						style="text-decoration: none;"><span style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;a possibilidade de realiza&ccedil;&atilde;o de
									doa&ccedil;&otilde;es;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="4" type="a">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">A
									A&Ccedil;&Atilde;O &eacute; totalmente independente e n&atilde;o possui vincula&ccedil;&atilde;o com
									as
									compras e pedidos realizados pelos usu&aacute;rios d</span></span></span></span></span>a<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;</span></span></span></span></span>empresa<span
					style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;</span></span></span></span></span>
				{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
							style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="5" type="a">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Toda
									a operacionaliza&ccedil;&atilde;o da A&Ccedil;&Atilde;O &eacute; feita pela empresa POLEN
									TECNOLOGIA LTDA., pessoa jur&iacute;dica de direito privado, regularmente
									inscrita no CNPJ/MF sob o n&ordm; 28.133.440/0001-36, com sede na Rua
									Elvira Harkot Ramina, n&ordm; 82, sala 1602, Mossungu&ecirc;, Curitiba &ndash;
									PR, que atua como &oacute;rg&atilde;o verificador e intermediador financeiro das
									doa&ccedil;&otilde;es realizadas &agrave;s organiza&ccedil;&otilde;es
									sociais;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="6" type="a">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									POLEN &eacute; respons&aacute;vel pela tecnologia de conex&atilde;o e transpar&ecirc;ncia
									de causas sociais, promovendo a curadoria e verifica&ccedil;&atilde;o da
									idoneidade das organiza&ccedil;&otilde;es sociais participantes, o rastreamento
									de todas as doa&ccedil;&otilde;es efetuadas, seu repasse &agrave;s organiza&ccedil;&otilde;es
									sociais beneficiadas e a divulga&ccedil;&atilde;o das m&eacute;tricas e resultado do
									impacto social gerado, por meio de website espec&iacute;fico para esta
									finalidade, divulgado em todos os canais de veicula&ccedil;&atilde;o da
									A&Ccedil;&Atilde;O;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol start="2">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">A
									participa&ccedil;&atilde;o da A&Ccedil;&Atilde;O &eacute; feita mediante a aceita&ccedil;&atilde;o,
									sem
									modifica&ccedil;&atilde;o, de todos os termos e condi&ccedil;&otilde;es contidas neste
									documento (os &quot;Termos de Uso&quot;), e se d&aacute; por meio da
									seguinte mec&acirc;nica:</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY" style="text-indent: 0.49in; margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Escolha
										da organiza&ccedil;&atilde;o social a ser apoiada;</span></span></span></span></span></p>
			</li>
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Preenchimento
										dos dados pessoais do PARTICIPANTE;</span></span></span></span></span></p>
			</li>
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Escolha
										do valor a ser doado;</span></span></span></span></span></p>
			</li>
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Escolha
										meio de pagamento e fornecimento dos dados necess&aacute;rios para
										cobran&ccedil;a;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol start="3">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Ao
									aceitar os Termos de Uso, o PARTICIPANTE declara que:</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 0.2in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">&Eacute;
										maior de 18 anos;</span></span></span></span></span></p>
			</li>
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Est&aacute;
										ciente que esta doa&ccedil;&atilde;o n&atilde;o &eacute; dedut&iacute;vel de seu Imposto de
										Renda;</span></span></span></span></span></p>
			</li>
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">Fez
										a doa&ccedil;&atilde;o por sua livre e espont&acirc;nea vontade, em car&aacute;ter
										irretrat&aacute;vel;</span></span></span></span></span></p>
			</li>
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">Os
										recursos utilizados na respectiva doa&ccedil;&atilde;o s&atilde;o de fonte
										l&iacute;tica;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="4">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">A responsabilidade pela veracidade das
									informa&ccedil;&otilde;es constantes do item 3, acima, ao aceitar o Termo de acordo, &eacute;
									exclusiva
									do PARTICIPANTE, n&atilde;o podendo o POLEN ou o&nbsp;</span></span></span></span></span>
				{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
							style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">&nbsp;serem responsabilizados em decorr&ecirc;ncia de eventual
									viola&ccedil;&atilde;o dos presentes Termos por parte do
									PARTICIPANTE;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="5">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">A
									participa&ccedil;&atilde;o na A&Ccedil;&Atilde;O em nome de uma Pessoa Jur&iacute;dica implica na
									assun&ccedil;&atilde;o da responsabilidade, pelo PARTICIPANTE, que declara ter
									os devidos poderes ou autoriza&ccedil;&atilde;o para a pr&aacute;tica de atos desta
									natureza em nome da empresa;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="6">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									PARTICIPANTE tem o direito e total liberdade de determinar o valor
									que deseja doar, podendo selecionar um dos valores preestabelecidos,
									ou informar outro valor que pretenda doar, utilizando-se do bot&atilde;o
									&quot;Quero Apoiar&quot; (limite m&iacute;nimo de R$ 5,00 - cinco reais -
									por doa&ccedil;&atilde;o);</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">&Eacute;
										de exclusiva responsabilidade do PARTICIPANTE verificar as op&ccedil;&otilde;es
										de organiza&ccedil;&otilde;es sociais habilitadas ao recebimento das doa&ccedil;&otilde;es,
										bem como certificar-se da escolha da organiza&ccedil;&atilde;o de sua
										prefer&ecirc;ncia e do valor que pretende doar;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="2" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Adicionalmente,
										o PARTICIPANTE pode optar livremente entre incluir ou n&atilde;o no valor
										doado o montante relativo aos custos banc&aacute;rios devidos &agrave;
										plataforma Iugu (IUGU SERVI&Ccedil;OS NA INTERNET S/A - 15.111.975/0001-64) ou Safe2Pay (SAFETOPAY
										INTERMEDIACAO DE NEGOCIOS LTDA -
										CNPJ 31.037.942/0001-78), intermediadoras das transa&ccedil;&otilde;es
										financeiras realizadas, bastando selecionar (ou n&atilde;o) a respectiva
										op&ccedil;&atilde;o antes de clicar no bot&atilde;o &ldquo;Realizar
										Doa&ccedil;&atilde;o&rdquo;;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="7">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Ap&oacute;s
									a realiza&ccedil;&atilde;o da doa&ccedil;&atilde;o, ser&aacute; emitido um recibo ao PARTICIPANTE,
									enviado no e-mail cadastrado, informando o valor de sua doa&ccedil;&atilde;o, a
									forma de pagamento escolhida, bem como a institui&ccedil;&atilde;o
									selecionada;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">A
										confirma&ccedil;&atilde;o da doa&ccedil;&atilde;o efetuada se d&aacute; apenas quando do
										processamento da transa&ccedil;&atilde;o, pela operadora de cart&atilde;o de cr&eacute;dito,
										ou da compensa&ccedil;&atilde;o do pagamento do boleto banc&aacute;rio, pela
										institui&ccedil;&atilde;o financeira;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol>

		<ol start="2" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">As
										transa&ccedil;&otilde;es financeiras
										realizadas na A&Ccedil;&Atilde;O s&atilde;o intermediadas pela plataforma Iugu (IUGU SERVI&Ccedil;OS
										NA INTERNET S/A - 15.111.975/0001-64) respons&aacute;vel pelo <span
											style="color: rgb(65, 65, 65); font-family: sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">processamento
											dos pagamentos via cart&atilde;o de cr&eacute;dito&nbsp;</span>e Safe2Pay (SAFETOPAY INTERMEDIACAO
										DE NEGOCIOS LTDA - CNPJ 31.037.942/0001-78) respons&aacute;vel pela gera&ccedil;&atilde;o dos
										boletos
										banc&aacute;rios;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="3" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">Qualquer problema no processamento do
										pagamento via cart&atilde;o de cr&eacute;dito, ou na compensa&ccedil;&atilde;o do pagamento via
										boleto
										banc&aacute;rio, por qualquer motivo, inclusive o n&atilde;o pagamento do boleto pelo PARTICIPANTE
										ap&oacute;s sua emiss&atilde;o, implicar&aacute; na n&atilde;o confirma&ccedil;&atilde;o da
										doa&ccedil;&atilde;o, sem que recaia sobre o POLEN ou o&nbsp;</span></span></span></span></span>
					{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">&nbsp;qualquer responsabilidade quanto a
										isso;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="4" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">&Eacute;
										de inteira responsabilidade do PARTICIPANTE fornecer informa&ccedil;&otilde;es
										de cobran&ccedil;a corretas e atualizadas para que seja poss&iacute;vel a
										realiza&ccedil;&atilde;o das doa&ccedil;&otilde;es;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 0.2in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="8">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O PARTICIPANTE concorda e aceita que
									seja
									descontado do valor doado, a t&iacute;tulo de Taxa de Administra&ccedil;&atilde;o, em favor do POLEN,
									o
									valor correspondente a {{ newcampaign.mainPercentage }}% da doa&ccedil;&atilde;o realizada, percentual
									este
									estabelecido em comum acordo com o&nbsp;</span></span></span></span></span>
				{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
							style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 0.2in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">Em
										caso de eventual necessidade de devolu&ccedil;&atilde;o ao PARTICIPANTE da
										quantia doada, esta ser&aacute; efetivada com o desconto da Taxa de
										Administra&ccedil;&atilde;o acima especificada;</span></span></span></span></span>
					<br>
					<br>
				</p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="2" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span
										style="background: #ffffff;">&Eacute; de inteira responsabilidade do
										PARTICIPANTE fornecer informa&ccedil;&otilde;es de contato atualizadas, por meio das quais possa ser
										facilmente contatado, caso surjam d&uacute;vidas ou sejam necess&aacute;rias
										averigua&ccedil;&otilde;es de seguran&ccedil;a relativas &agrave;
										doa&ccedil;&atilde;o.&nbsp;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="3" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">A
										impossibilidade de entrar em contato com o PARTICIPANTE pode levar
										ao cancelamento da doa&ccedil;&atilde;o em raz&atilde;o de procedimentos de
										seguran&ccedil;a;</span></span></span></span></span>
					<br>
					<br>
				</p>
			</li>
			<li
				*ngIf="content != undefined && content.showPercentageTable != undefined && content.showPercentageTable.value == 'true'">
				A Taxa de Administra&ccedil;&atilde;o ocorre de forma regressiva a partir do montante de doa&ccedil;&atilde;o
				arrecadado, conforme tabela a baixo:
				<br>


				<table border="1" cellpadding="0" cellspacing="0" dir="ltr"
					style="table-layout: fixed; font-size: 10pt; font-family: arial, sans, sans-serif; width: 1;"
					xmlns="http://www.w3.org/1999/xhtml">
					<colgroup>
						<col width="113">
						<col width="173">
						<col width="247">
					</colgroup>
					<tbody>
						<tr style="height: 21px;">
							<td colspan="2" data-sheets-value='{"1":2,"2":"DOAÇÃO MENSAL CONSOLIDADA"}' rowspan="1"
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; font-weight: bold; overflow-wrap: break-word; text-align: center;">
								DOA&Ccedil;&Atilde;O MENSAL CONSOLIDADA</td>
							<td colspan="1"
								data-sheets-value='{"1":2,"2":"Percentual da doação devido ao POLEN a título de prestação de serviços"}'
								rowspan="2"
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; font-weight: bold; overflow-wrap: break-word; text-align: center;">
								<div style="max-height: 42px;">Percentual da doa&ccedil;&atilde;o devido ao POLEN a t&iacute;tulo de
									presta&ccedil;&atilde;o de servi&ccedil;os</div>
							</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"De"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; font-weight: bold; overflow-wrap: break-word; text-align: center;">
								De</td>
							<td data-sheets-numberformat='[null,4,"#,##0.00\\ [$€-1]"]' data-sheets-value='{"1":2,"2":"Até"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; font-weight: bold; overflow-wrap: break-word; text-align: center;">
								At&eacute;</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 1,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 1,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":50000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$50&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,3,"0%",1]' data-sheets-value='{"1":3,"3":0.09}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								9%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":50000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$50&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":100000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$100&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.08}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								8,00%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 100.001,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 100.001,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":250000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$250&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.07}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								7,00%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 250.001,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 250.001,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":500000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$500&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.06}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								6,00%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 500.001,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 500.001,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":750000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$750&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.045}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								4,50%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 750.001,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 750.001,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":3,"3":1000000}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$1&nbsp;000&nbsp;000,00</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.03}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								3,00%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 1.000.000,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 1.000.000,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":2,"2":"∞"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								&infin;</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.025}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								2,50%</td>
						</tr>
						<tr style="height: 21px;">
							<td data-sheets-value='{"1":2,"2":"R$ 2.000.001,00"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								R$ 2.000.001,00</td>
							<td data-sheets-numberformat='[null,4,"[$R$]#,##0.00"]' data-sheets-value='{"1":2,"2":"-"}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								-</td>
							<td data-sheets-numberformat='[null,3,"0.00%",1]' data-sheets-value='{"1":3,"3":0.02}'
								style="border-color: rgb(204, 204, 204); overflow: hidden; padding: 2px 3px; vertical-align: bottom; font-family: Calibri; font-size: 11pt; overflow-wrap: break-word; text-align: center;">
								2,00%</td>
						</tr>
					</tbody>
				</table>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol start="9">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									POLEN n&atilde;o se responsabiliza por eventuais danos causados a
									dispositivos eletr&ocirc;nicos de propriedade do PARTICIPANTE,
									decorrentes da m&aacute; utiliza&ccedil;&atilde;o das ferramentas disponibilizadas
									pelo POLEN;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="10">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									POLEN n&atilde;o possui qualquer influ&ecirc;ncia sobre as informa&ccedil;&otilde;es
									veiculadas por seus clientes, apoiadores e parceiros, tampouco
									responsabiliza-se por elas;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY" style="margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">A
										veicula&ccedil;&atilde;o de links de acesso a websites de terceiros n&atilde;o
										implica em endosso das informa&ccedil;&otilde;es constantes nas respectivas
										p&aacute;ginas;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="11">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									POLEN investigar&aacute; qualquer viola&ccedil;&atilde;o aos presentes Termos de Uso
									cometida por PARTICIPANTES, reservando-se ao direito de, a seu
									exclusivo crit&eacute;rio, restringir, no todo ou em parte, o acesso &agrave;
									tecnologia a qualquer momento, sem aviso pr&eacute;vio;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">Os
										PARTICIPANTES que tomarem conhecimento da utiliza&ccedil;&atilde;o indevida da
										A&Ccedil;&Atilde;O por terceiros, ou de qualquer viola&ccedil;&atilde;o aos presentes
										Termos de Uso, obrigam-se a reportar de imediato referida infra&ccedil;&atilde;o,
										sob pena de serem considerados correspons&aacute;veis, estando sujeitos
										&agrave;s mesmas san&ccedil;&otilde;es aplic&aacute;veis aos
										violadores;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="12">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O POLEN reserva-se ao direito de
									alterar,
									a qualquer tempo, os presentes Termos de Uso, independentemente da necessidade de qualquer
									notifica&ccedil;&atilde;o aos seus Participantes, que se obrigam, desde j&aacute;, a lerem e
									consultarem
									os Termos de Uso atualizados, disponibilizados no website&nbsp;</span></span></span></span></span> <a
					href="{{campaignUrl}}">&nbsp;{{ campaignUrl }}</a><span style="font-variant: normal;"><span
						style="text-decoration: none;"><span style="font-style: normal;"><span style="font-weight: normal;"><span
									style="background: #ffffff;">;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="13">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Outros
									servi&ccedil;os oferecidos pelo POLEN podem estar sujeitos a Termos de Uso
									adicionais e espec&iacute;ficos, que ser&atilde;o oportunamente divulgados e
									dever&atilde;o ser respeitados igualmente;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="14">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									POLEN respeitar&aacute; a privacidade das informa&ccedil;&otilde;es fornecidas pelos
									PARTICIPANTES da A&Ccedil;&Acirc;O, abstendo-se da divulga&ccedil;&atilde;o p&uacute;blica de
									quaisquer dados de propriedade dos PARTICIPANTES, bem como da
									comercializa&ccedil;&atilde;o destes dados;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">Os
										PARTICIPANTE autorizam o POLEN a
										utilizar internamente as informa&ccedil;&otilde;es por eles fornecidas, bem como
										compartilh&aacute;-las com o&nbsp;</span></span></span></span></span>
					{{ newcampaign.storeName }}<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">,
										idealizador da A&Ccedil;&Atilde;O, para o regular desenvolvimento de suas
										atividades;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY"
		style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="2" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">As
										informa&ccedil;&otilde;es coletadas pelo POLEN ser&atilde;o aplicadas na realiza&ccedil;&atilde;o
										de melhorias &agrave; tecnologia, e para o desenvolvimento de novas
										funcionalidades, podendo ser utilizadas pelo POLEN em outros
										produtos que venham a ser desenvolvidos pela empresa;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol>

		<ol start="3" type="a">
			<li>

				<p align="JUSTIFY"
					style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
					<span style="font-variant: normal;"><span style="text-decoration: none;"><span
								style="font-style: normal;"><span style="font-weight: normal;"><span style="background: #ffffff;">Os
										PARTICIPANTES autorizam o POLEN e
										o&nbsp;</span></span></span></span></span> {{ newcampaign.storeName }}<span
						style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
									style="font-weight: normal;"><span style="background: #ffffff;">&nbsp;a enviarem mensagens comerciais
										ou
										entrarem em contato por meio do endere&ccedil;o de e-mail ou n&uacute;mero de telefone indicados
										quando da participa&ccedil;&atilde;o da A&Ccedil;&Atilde;O;</span></span></span></span></span></p>
			</li>
		</ol>
	</ol>

	<p align="JUSTIFY" style="margin-left: 0.49in; text-indent: 0.49in; margin-bottom: 0in; line-height: 100%;">
		<br>
	</p>

	<ol start="15">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">O
									servi&ccedil;o e as informa&ccedil;&otilde;es fornecidas pelo POLEN cont&ecirc;m conte&uacute;do
									protegido por direitos autorais, sigilo comercial, patentes e outros
									direitos previstos na legisla&ccedil;&atilde;o em vigor, estando vedada sua
									explora&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, distribui&ccedil;&atilde;o ou armazenamento, em
									nenhuma hip&oacute;tese sen&atilde;o mediante pr&eacute;via e expressa autoriza&ccedil;&atilde;o
									pelos respons&aacute;veis legais do POLEN;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="16">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Caso
									haja alguma d&uacute;vida, coment&aacute;rio ou sugest&atilde;o relativa aos presentes
									Termos de Uso e &agrave; A&Ccedil;&Atilde;O, estas devem ser direcionadas ao e-mail
									eupolinizo@polen.com.br;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="JUSTIFY"
		style="text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="17">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Esse Termo &eacute; regido pelas leis da
									Rep&uacute;blica Federativa do Brasil.&nbsp;</span></span></span></span></span></p>
		</li>
	</ol>

	<p align="LEFT"
		style="margin-left: 0.5in; text-indent: -0.5in; margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; font-variant: normal; font-style: normal; font-weight: normal; line-height: 107%; page-break-inside: auto; widows: 2; orphans: 2; text-decoration: none; page-break-after: auto;">
		<br>
	</p>

	<ol start="18">
		<li>

			<p align="JUSTIFY"
				style="margin-bottom: 0in; background: #ffffff; border: none; padding: 0in; line-height: 100%; page-break-inside: auto; widows: 2; orphans: 2; page-break-after: auto;">
				<span style="font-variant: normal;"><span style="text-decoration: none;"><span style="font-style: normal;"><span
								style="font-weight: normal;"><span style="background: #ffffff;">Quaisquer
									d&uacute;vidas e situa&ccedil;&otilde;es n&atilde;o previstas neste Termo ser&atilde;o
									primeiramente resolvida pelo POLEN e, caso persistam, dever&atilde;o ser
									solucionadas pelo foro da Comarca da Regi&atilde;o Metropolitana de
									Curitiba, Estado do Paran&aacute;, com exclus&atilde;o de qualquer outro, por
									mais privilegiado que seja ou venha a ser.</span></span></span></span></span></p>
		</li>
	</ol>
</div>